import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PercentageDeallocation } from "../indicators/percentage-deallocation.model";
import { ServiciosKobraService } from "@servicios/servicios-kobra.service";
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service'; 

@Injectable({
  providedIn: 'root'
})
export class IndicatorService {
  private servicesURL = '/indicadores';
  constructor(
    private serviciosVisitas: ServicesVisitasService
  ) { }

  public getPercentageDeAllocation(query: string = ''): Observable < PercentageDeallocation > {
  return this.serviciosVisitas.get(this.servicesURL + '/desasignacion' + query)
    .pipe(
      map(
        res => {
          let percentageDeallocation: PercentageDeallocation = PercentageDeallocation.map(res.data);
          return percentageDeallocation;
        }
      )
    )
  }
}
