import { ApplicationService } from './../../application/shared/application.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LenderService } from '../lender.service';
import { LayoutFieldsComponent } from './../layout-fields/layout-fields.component';
import { ActivatedRoute } from '@angular/router';
//import { ProductChecklistComponent } from '../product-checklist/product-checklist.component';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css'],
  providers: [LenderService]
})
export class ConfigurationComponent implements OnInit {
  formGroupGeneral: FormGroup;
  formGroupSaldos: FormGroup;
  title: string = '';
  dynamicFields: any[] = [];
  camposDinamicos: any[] = [];
  products: any[] = [];
  hijosActivos: boolean = false;
  data = null;
  // cargandoProductos: boolean = true;
  cargandoSaldos: boolean = true;
  cargandoGeneral: boolean = true;

  constructor(
    private lenderService: LenderService,
    private appService: ApplicationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,

    private dialog: MatDialog,
   // public dialogRef: MatDialogRef<ConfigurationComponent>,
    //@Inject(MAT_DIALOG_DATA) public data: any = null

  ) { 
   
  }

  ngOnInit() {
    this.init();
   
  }

  async init() {
    // Obtener parámetros de la consulta (query params) de la ruta
    this.route.queryParams.subscribe(params => {
      this.data = params;
    });
  
    // Mostrar un mensaje de carga mientras se obtiene la configuración
    //const loading = this.appService.showLoading('Cargando configuración');
  
    try {
      

      // Obtener la configuración de la financiera de forma asíncrona
      const fields = await this.lenderService
        .getConfiguration(this.data.idFinanciera)
        .toPromise();
        this.cargandoGeneral = false;

      // Inicializar listas y objetos para campos dinámicos y grupo de formulario dinámico
      let dynamicFields: any[] = [];
      let dynamicFormGroup = {};
  
      // Procesar cada campo de la configuración recibida
      fields.forEach(field => {
        const fieldName = `field_${field.idConfiguracion}`;
  
        // Configurar propiedades del campo dinámico
        dynamicFields.push({
          required: true,
          type: field.idConfiguracion === 8 ? 'string' : 'number',
          name: fieldName,
          placeholder: field.nombre,
          id: field.idConfiguracion
        });
       
        // Crear un control de formulario dinámico y agregarlo al grupo de formulario dinámico
        dynamicFormGroup[fieldName] = new FormControl(field.valor, Validators.required);
      });

  
      // Almacenar la configuración dinámica y crear el grupo de formulario dinámico
      this.dynamicFields = dynamicFields.slice();
      this.formGroupGeneral = this.formBuilder.group(dynamicFormGroup);

    } catch (error) {
      // En caso de error, ocultar el mensaje de carga y mostrar un mensaje de error
      //this.appService.hideLoading(loading);
      // this.cargandoProductos = false;
      this.cargandoSaldos = false;
      this.cargandoGeneral = false;
      this.appService.showError(error);
    }
  }
  
  saveConfiguraciones() {
    // Verificar si algún valor está vacío
    if (this.dynamicFields.some((f: any) => !this.formGroupGeneral.get(f.name).value)) {
      this.appService.showSnackbar('Aviso', 'Todos los campos son obligatorios.', 3000, 'warning');
      return; // No se guarda la configuración si algún valor está vacío
    }
  
    const loading = this.appService.showLoading(`Guardando la configuración para ${this.data.nombreFinanciera}`);
    
    const saveConfiguration = (values: any[]) => {
      return this.lenderService.saveConfiguration(this.data.idFinanciera, values);
    };
    
    const handleSaveSuccess = () => {
      this.appService.hideLoading(loading);
      this.appService.showSnackbar('Aviso', 'Se ha guardado la configuración correctamente', 3000, 'success');
    };
    
    const handleSaveError = (error: any) => {
      this.appService.hideLoading(loading);
      this.appService.showError(error);
    };
    
    const values = this.dynamicFields.map((f: any) => ({
      idConfiguracion: f.id,
      valor: this.formGroupGeneral.get(f.name).value
    }));

    
    saveConfiguration(values).subscribe(handleSaveSuccess, handleSaveError);
  }
  
  saveConfiguracionesSaldos() {
    // Verificar si algún valor está vacío
    if (this.camposDinamicos.some((f: any) => !this.formGroupSaldos.get(f.name).value)) {
      this.appService.showSnackbar('Aviso', 'Todos los campos son obligatorios. de saldos', 3000, 'warning');
      return; // No se guarda la configuración si algún valor está vacío
    }
  
    const loading = this.appService.showLoading(`Guardando la configuración de saldos para ${this.data.nombreFinanciera}`);
    
    const saveConfiguracionSaldos = (valores: any[]) => {
      return this.lenderService.saveConfiguracionSaldos(this.data.idFinanciera, valores);
    };
    
    const handleSaveSuccess = () => {
      this.appService.hideLoading(loading);
      this.appService.showSnackbar('Aviso', 'Se ha guardado la configuración de saldos correctamente', 3000, 'success');
    };
    
    const handleSaveError = (error: any) => {
      this.appService.hideLoading(loading);
      this.appService.showError(error);
    };
    
    const valores = this.camposDinamicos.map((f: any) => ({
      idConfiguracion: f.id,
      valor: this.formGroupSaldos.get(f.name).value
    }));
    
    saveConfiguracionSaldos(valores).subscribe(handleSaveSuccess, handleSaveError);
  }
  
  

  // editLayout(product) {
  //  this.openDialog(LayoutFieldsComponent, product);
  // }

  // openDialog(component, product, percentage = 80) {
  //   const dialogRef = this.dialog.open(component, {
  //     width: `${percentage}%`,
  //     height: `80%`,
  //     disableClose: false,
  //     data: { lender: this.data, product }
  //   });

  //   dialogRef.afterClosed().subscribe(() => {});
  // }

}
