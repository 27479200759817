import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { WorkExperience } from "./work-experience.model";
import { map } from 'rxjs/operators';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';

@Injectable()
export class WorkExperienceService {
z
  constructor(
    private visitasServ: ServicesVisitasService
  ) { }

  public all(idAgente: number): Observable<WorkExperience[]> {
    return this.visitasServ.get(`/agentes/${idAgente}/experiencia-laboral?activo=true`).pipe(
      map((res) => {
        let responseData: any = res.data;
        let workExperiences: WorkExperience[] = [];
        for (let i = 0; i < responseData.length; i++) {
          let expereince = new WorkExperience();
          expereince.id = responseData[i].idExperienciaLaboral;
          expereince.boss = responseData[i].jefeInmediato;
          expereince.company = responseData[i].empresa;
          expereince.title = responseData[i].puesto;
          expereince.startDate = responseData[i].fechaInicioLaboral;
          expereince.endDate = responseData[i].fechaFinalLaboral;
          expereince.comment = responseData[i].comentario;
          expereince.agentID = responseData[i].idAgente;
          workExperiences.push(expereince);
        }
        return workExperiences;
      }
      )
    );
  }

  public add(workExperience: WorkExperience): Observable<WorkExperience> {
    return this.visitasServ.post(`/agentes/${workExperience.agentID}/experiencia-laboral`, workExperience).pipe(
      map((res) => <WorkExperience>res.data)
    );
  }

  public delete(id: number, workExperience: WorkExperience): Observable<any> {
    workExperience.activo = false;

    return this.visitasServ.patch(`/agentes/experiencia-laboral/${id}`, workExperience).pipe(
      map(res => res.data)
    );
  }

  public update(id: number, workExperience: WorkExperience): Observable<WorkExperience> {
    workExperience.activo = true;

    return this.visitasServ.patch(`/agentes/experiencia-laboral/${id}`, workExperience).pipe(
      map(res => <WorkExperience>res.data)
    );
  }
}