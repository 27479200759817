<div class="app content kobra">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-card class="cardAyuda" style="width: 348px; margin-bottom: 20px; margin-left: 10px !important;">
        <mat-card-content>
          <strong>Recuerda cargar o abonar los montos sin IVA</strong>
        </mat-card-content>
      </mat-card>
      <ng-template let-lender #financieraTemplate>
        {{ lender.nombre }}
      </ng-template>
      <form [formGroup]="form">
        <div class="row">
          <div class="column">
            
              <mat-select-search
                class="id-card-field"
                [label]="'Cliente'"
                [placeHolder]="'Selecciona un cliente'"
                [value]="'idFinanciera'"
                [labelField]="'name'"
                [icon]="'account_balance'"
                [options]="lenders"
                formControlName="idFinanciera"
              > 
            </mat-select-search>
              <mat-error *ngIf="checkControl('idFinanciera')" style="margin-top: -20px; font-size: 12px; margin-left:15px">
                El cliente es <strong>requerido</strong>
              </mat-error>
            
          </div>
          <div class="column">
            
              <mat-select-search
                class="id-card-field"
                [label]="'Concepto'"
                [placeHolder]="'Busca por id o nombre'"
                [value]="'option'"
                [labelField]="'nombre'"
                [icon]="'account_circle'"
                [realizarBusqueda]="buscarConceptos"
                formControlName="concepto"
                (ngModelChange)="onConceptoSelected(form.get('concepto').value)"
              >
              </mat-select-search>
              <mat-error *ngIf="checkControl('idConcepto')" style="margin-top: -20px; font-size: 12px; margin-left:15px">
                El concepto es <strong>requerido</strong>
              </mat-error>

          </div>
          <div class="column">
            
            <a mat-stroked-button [routerLink]="['/conceptos/nuevo']" 
              matTooltip="Agrega un nuevo concepto"
              mat-tooltip-panel-above
              class="boton-accion-front"
              >
              <mat-icon >add_thick</mat-icon>
              Agrega un nuevo concepto
            </a>
           
          </div>
          
        </div>

        <div class="row">
          <div class="column">
            <mat-date-picker-formater
            [label]="'Fecha Movimiento'"
            [placeHolder]="'Capture la fecha inicio'"
            [max]="hoy"
            formControlName="fecMovimiento"
            [limpiar]="false"
          />
          </div>
          <div class="column">
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>Nombre Movimiento</mat-label>
              <input matInput textSanitizer  placeholder="Escribir nombre del movimiento" formControlName="nombreMovimiento">
              <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
              <mat-error *ngIf="checkControl('nombreMovimiento')">
                El nombre es <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field floatLabel="always" style="width: 100%;">
              <mat-label>Monto</mat-label>
              <input matInput textSanitizer  type="number" placeholder="0" formControlName="monto">
              <span matTextPrefix>$&nbsp;</span>
              <span matTextPrefix *ngIf="isCargo">- &nbsp;</span>
              <mat-error *ngIf="checkControl('monto')">
                El monto es <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="column">
           
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions align="end">
      <button class="boton-cancelar" mat-flat-button routerLink="../"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
      <button mat-stroked-button class="boton-guardar" [disabled]="saveButton" (click)="onSubmit()"><mat-icon style="color:white !important">save</mat-icon>Guardar</button>
    </mat-card-actions>
  </mat-card>
</div>
