<div class="app content kobra">
  <form [formGroup]="periodoForm" (ngSubmit)="onSubmit()">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{title}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-loader *ngIf="cargando" [message]="'Cargando información...'"></app-loader>
          <div class="row" *ngIf="!cargando">
            <div class="column">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Nombre del Periodo de Facturación</mat-label>
                <input matInput textSanitizer  placeholder="Escribir nombre del periodo" formControlName="nombre">
                <mat-error *ngIf="checkControl('nombre')">
                  {{getErrorMessage('nombre')}} <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="column">
              <mat-date-picker-formater
              [label]="'Fecha inicio'"
              [placeHolder]="'Capture la fecha inicio'"
              formControlName="fecInicio"
              [limpiar]="false"/>
              <mat-error *ngIf="checkControl('fecInicio')">
                {{getErrorMessage('fecInicio')}}<strong>requerido</strong>
              </mat-error>
            </div>
            <div class="column">
              <mat-date-picker-formater
              [label]="'Fecha fin'"
              [placeHolder]="'Capture la fecha fin'"
              formControlName="fecFin"
              [limpiar]="false"/>
              <mat-error *ngIf="checkControl('fecFin')">
                {{getErrorMessage('fecFin')}} <strong>requerido</strong>
              </mat-error>
            </div>

          </div>
  
      </mat-card-content>
      <mat-card-actions align="end" *ngIf="!cargando">
        <button class="boton-cancelar" mat-flat-button routerLink="../" queryParamsHandling="preserve"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
        <button mat-stroked-button [ngClass]="{'buton-disabled': saveButton, 'boton-guardar': !saveButton}" [disabled]="saveButton"><mat-icon style="color:white !important">save</mat-icon>Guardar</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
