import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { User } from "./user.model";
import { ApiKobraService } from "../core/http/api-kobra/api-kobra.service";
import { ServicesKobraService } from "../core/http/services-kobra/services-kobra.service";
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { map } from "rxjs/operators";
import {  Tipo2fa, VinculacionSecretResponse } from './user.interface';
import { IServicesTypeKobraResponse } from '../core/http/servicio-general/servicio-general-response';

@Injectable()
export class UserService {
  private baseUrl: string = '/v2/users';
  private serviceURL = '/auth';
  private adminUrl: string = '/users'

  constructor(
    private apiKobra: ApiKobraService,
    private servicesKobra: ServicesKobraService,
    private leanServicesKobra: ServiciosKobraService,
    private servicesVisitas: ServicesVisitasService
  ) { }

  public getOne( id: number, query?:string ): Observable<User> {
    return this.leanServicesKobra.get(`${this.adminUrl}/${id}${query}`).pipe(
      map((response) => <User> response.data)
    );
  }

  reloadBaseUrl(archivo:string = ""){
    this.servicesKobra.reloadBaseUrl(archivo);
    this.leanServicesKobra.reloadBaseUrl(archivo);
  }

  public logIn(email, contrasena): Observable<User> {
    let data = {
      email: email,
      contrasena: contrasena
    };

    return this.servicesKobra.post('/login', data).pipe(
      map(res => <User> res.data)
    )
  }

  public asignarRoles(roles): Observable<any> {
    return this.leanServicesKobra.post('/asignar-roles', {roles}).pipe(
      map(response => response.data)
    );
  }

  public actualizarPerfil(idUser, datos): Observable<any> {
    return this.leanServicesKobra.patch(`${this.adminUrl}/${idUser}`, datos).pipe(
      map(res => {
          return res;
      })
    );
  }

  public cambiarContrasena(idUser, datos): Observable<any> {
    return this.leanServicesKobra.patch(`${this.adminUrl}/${idUser}/password`, datos).pipe(
      map(res => {
          return res;
      })
    );
  }

  public get2FA(idUsuario: number): Observable<Tipo2fa[]> {
    return this.servicesVisitas.get<Tipo2fa[]>(`/usuarios/${idUsuario}/2fa`).pipe(
      map(res => res.data) 
    );
  }

  public vinculacion2fa(idUsuario: number, idTipo2fa: number): Observable<VinculacionSecretResponse> {
    return this.servicesVisitas.post<VinculacionSecretResponse>(`/usuarios/${idUsuario}/2fa`, {idTipo2fa}).pipe(
      map((res) => res.data) 
    );
  }

  public confirmarOTP(pSecret: string, pOtpCode: string, idUsuario: number): Observable<IServicesTypeKobraResponse<boolean>> {
    return this.servicesVisitas.post<boolean>(`/usuarios/${idUsuario}/secret/otp`, {  secret: pSecret, otpCode: pOtpCode });
  }

}
