import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IUsuario } from '../usuarios.interface';
import { UsuarioService } from '../usuario.service';
import { LenderService } from '@lender/lender.service';
import { Lender } from '@lender/lender.model';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { Observable, Subject, merge } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

@Component({
  selector: 'app-usuarios-form',
  templateUrl: './usuarios-form.component.html',
  styleUrls: ['./usuarios-form.component.css'],
  providers: [
    LenderService                                   
  ]
})
export class UsuariosFormComponent implements OnInit, OnDestroy {
  isReadonly: boolean = false;
  public title: string;
  public form: FormGroup;
  hideContra: boolean = true;
  hideConfirm: boolean = true;
  get usuario(): IUsuario {
    return this.usuarioServ.usuario;
  }
  @ViewChild('financieraInput') private financieraInput: any;
  public lenders: Lender[];
  public saveButton = false;
  private loadRegsFlag = false;
  private onDestroy: Subject<void> = new Subject<void>();
  public loaderText: string;
  loadingLenders: boolean = true;
  sessionData: SessionData;
  margenContrasena: number = 0;
  pixelesContrasena: string = '80px';

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public usuarioServ: UsuarioService,
    private lenderService: LenderService,
    private localStorageService: LocalStorageService,
    private app: ApplicationService
  ) {
    //this.usuario = new IUsuario;
    this.sessionData = this.localStorageService.getSessionData();
    this.initializeForm();

  }

  ngOnInit() {
    this.loadLenders();
    this.initializeForm();
    this.subscribeToPasswordStatusChanges();
    const userId = this.route.snapshot.paramMap.get('id');
  
    if (userId) {
      this.handleEditMode(+userId);
    } else {
      this.handleCreateMode();
    }
  }

  private subscribeToPasswordStatusChanges() {
    this.form.controls['contrasena'].statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.pixelesContrasena = '80px';
        this.margenContrasena = 0;
      } else if (status === 'INVALID') {
        this.pixelesContrasena = '100px';
        this.margenContrasena = -15;
      }
    });
  }
  
  // Método para inicializar el formulario y sus validadores base
  private initializeForm() {
    this.form = this.fb.group({
      idFinanciera: ['', Validators.required],
      nombre: ['', [Validators.required, this.noWhitespaceValidator]],
      apellidoPaterno: ['', [Validators.required, this.noWhitespaceValidator]],
      apellidoMaterno: '',
      notificacionVisita: [false],
      notificacionCitas: [false],
      notificacionReporteRedeco: [false],
      notificacionSaldos: [false],
      email: ['', [Validators.required, Validators.email, this.noWhitespaceValidator]],
      contrasena: ['', this.createPasswordValidators()],
      confirmPass: [''],
      deleted: [true]
    });
  
    this.confirmPassObserver();
  }
  
  // Método para crear los validadores de contraseña
  private createPasswordValidators() {
    return [
      Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d@$!%*?&]{8,}$')
    ];
  }
  
  // Método para manejar el modo de edición
  private handleEditMode(userId: number) {
    this.isReadonly = true;
    this.getUsuario(() => {
      this.title = `Editar "${this.usuario.nombre}"`;
      this.form.patchValue(this.usuario);
      this.form.patchValue({ deleted: !this.usuario.deleted });
  
      // Aplicar validadores de contraseña solo si el usuario introduce una nueva contraseña
      this.form.get('contrasena').valueChanges.subscribe(value => {
        this.togglePasswordValidators(!!value);
      });
    });
  }
  
  // Método para manejar el modo de creación
  private handleCreateMode() {
    this.title = 'Agregar';
    this.form.get('contrasena').setValidators([
      Validators.required,
      ...this.createPasswordValidators()
    ]);
    this.form.get('confirmPass').setValidators([Validators.required]);
    this.form.get('contrasena').updateValueAndValidity();
    this.form.get('confirmPass').updateValueAndValidity();
  }
  
  // Método para alternar validadores de contraseña en el modo de edición
  private togglePasswordValidators(isPasswordEntered: boolean) {
    const contrasenaControl = this.form.get('contrasena');
    const confirmPassControl = this.form.get('confirmPass');
  
    if (isPasswordEntered) {
      contrasenaControl.setValidators([
        Validators.required,
        ...this.createPasswordValidators()
      ]);
      confirmPassControl.setValidators([Validators.required]);
    } else {
      contrasenaControl.clearValidators();
      confirmPassControl.clearValidators();
    }
  
    // Actualiza los validadores sin emitir eventos adicionales para evitar bucles infinitos
    contrasenaControl.updateValueAndValidity({ emitEvent: false });
    confirmPassControl.updateValueAndValidity({ emitEvent: false });
  }
  
  
  // Método para observar los cambios en los campos de contraseña y confirmación
  private confirmPassObserver() {
    merge(
      this.form.get('contrasena').valueChanges,
      this.form.get('confirmPass').valueChanges
    ).pipe(
      takeUntil(this.onDestroy)
    ).subscribe(() => {
      if (this.form.get('contrasena').value !== this.form.get('confirmPass').value) {
        this.form.get('confirmPass').setErrors({ pass: true });
      } else {
        this.form.get('confirmPass').setErrors(null);
      }
    });
  }
  
  ngOnDestroy() {
    if (this.loadRegsFlag) {
      const page = +this.route.snapshot.queryParamMap.get('_page') || 1;
      this.usuarioServ.getGrid(page);
    }

    this.usuarioServ.setUsuarioData(undefined);

    this.onDestroy.next();
    this.onDestroy.complete();
  }

  cancel() {
    this.router.navigate([`catalogos/administradores`], {
      queryParamsHandling: 'preserve'
    });
  }

  getUsuario(next?: () => void) {
    this.usuarioServ.obetenr(+this.route.snapshot.paramMap.get('id'))
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        let adminData: IUsuario = res.data.map((usuario: IUsuario) => {
          usuario.deleted = +usuario.deleted;
          usuario.notificacionVisita = +usuario.notificacionVisita;
          usuario.notificacionCitas = +usuario.notificacionCitas;
          usuario.notificacionSaldos = +usuario.notificacionSaldos;
          usuario.notificacionReporteRedeco = +usuario.notificacionReporteRedeco;
          usuario.emailOriginal = usuario.email;
          return usuario;
        });

        this.usuarioServ.setUsuarioData(adminData[0]);
        this.form.patchValue(adminData[0]);

        if (next) {
          next();
        }
      }, (error) => {
        this.app.showError(error);
      });
  }

  checkControl(control: string) {
    return this.form.controls[control].invalid && this.form.controls[control].touched;
  }

  getErrorMessage(control: string) {
  const formControl = this.form.get(control);
  
  if (formControl.hasError('required')) {
    return 'Este campo es requerido.';
  } else if (formControl.hasError('email')) {
    return 'Introduzca un email válido.';
  } else if (formControl.hasError('pass')) {
    return 'Las contraseñas no coinciden.';
  } else if (formControl.hasError('pattern')) {
    return 'La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula y un número.';
  }
  
  return '';
}


  loadLenders(): void {
    this.loadingLenders = true;
    this.lenderService.getAll().subscribe(
      (data: any) => {
        this.loadingLenders = false;
        data.sort(this.compare);
        this.lenders = data;
      },
      (err: any) => {
        this.loadingLenders = false;
        this.app.showError(err);
      }
    );
  }

  onSubmit() {
    if (this.form.valid) {

      try {
        this.form.value.apellidoMaterno = this.form.value.apellidoMaterno.trim();
      } catch (error) {
        this.form.value.apellidoMaterno = null;
      }

      this.form.get('confirmPass').disable();
      this.saveButton = true;

      let httpObserver: Observable<any>;
      let snackMsg: string;

      if (this.usuario) {
        this.loaderText = 'Editando administrador...'

        if (this.form.get('contrasena').value || this.form.get('confirmPass').value) {
          if (this.form.get('contrasena').value !== this.form.get('confirmPass').value) {
            this.saveButton = false;
            this.loaderText = undefined;
            this.app.showSnackbar('¡Aviso!', 'La contraseña no coincide.', 3000, 'error');
            this.form.get('confirmPass').enable();

            return;
          }
        }
        
        if (this.form.get('email').value == this.usuario.emailOriginal) {
          this.form.patchValue({
            email: null
          });
        }

        this.form.value.deleted = !this.form.value.deleted;


        httpObserver = this.usuarioServ.editar(this.usuario.idAdministradorFinanciera, this.form.value);
        snackMsg = 'Administrador editado correctamente.';
      } else {
        this.loaderText = 'Guardando administrador...'
        httpObserver = this.usuarioServ.agregar(this.form.value);
        snackMsg = 'Administrador agregado correctamente.';
      }
      this.app.showLoading(this.loaderText);

      httpObserver.pipe(
        take(1)
      ).subscribe((res: any) => {
        this.loadRegsFlag = true;
        this.saveButton = false;
        this.loaderText = undefined;
        this.app.hideLoading();
        this.app.showSnackbar('¡Aviso!', snackMsg, 3000, 'success');

        if (this.usuario) {
          this.router.navigate(['../'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
        } else {
          this.form.reset();
          this.form.get('notificacionVisita').patchValue(false);
          this.form.get('notificacionCitas').patchValue(false);
          this.form.get('notificacionSaldos').patchValue(false);
          this.form.get('confirmPass').enable();

          this.financieraInput._element.nativeElement.focus();
        }
      }, (error: any) => {
        this.app.hideLoading();
        this.saveButton = false;
        this.loaderText = undefined;
        this.app.showError(error);
        this.form.get('confirmPass').enable();
      });

    } else {
      (<any>Object).values(this.form.controls).forEach((control: FormControl) => {
        control.markAsTouched();
      });
    }
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { 'required': true };
  }


  formClearValidators() {
    try {
      this.usuario.email = this.usuario.email.trim();
    } catch (error) {
      this.usuario.email = undefined;
    }
    // Limpia validadores específicos
    this.form.get('contrasena').clearValidators();
    this.form.get('confirmPass').clearValidators();
    this.form.get('contrasena').updateValueAndValidity();
    this.form.get('confirmPass').updateValueAndValidity();
  
    // Asigna valores del usuario si están disponibles
    if (this.usuario) {
      this.form.patchValue(this.usuario);
      this.form.patchValue({ deleted: !this.usuario.deleted });
    }
  }
  

  private compare(a, b) {
    const nombreA = a.nombre.toUpperCase();
    const nombreB = b.nombre.toUpperCase();

    let comparison = 0;
    if (nombreA > nombreB) {
      comparison = 1;
    } else if (nombreA < nombreB) {
      comparison = -1;
    }
    return comparison;
  }
}
