export class WorkExperience {
  id: number;
  idExperienciaLaboral?: number;
  agentID: number;
  boss: string;
  comment: string;
  company: string;
  editMode: boolean;
  endDate: Date;
  startDate: Date;
  title: string;
  totalExperienciaLaboral: number;
  activo: boolean;
}