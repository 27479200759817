import { IUtcTime } from '../interfaces/utc-time';
export class UtcConversor {

  /**
   * Convierte una fecha en la zona horaria de México (America/Mexico_City) a UTC.
   * @param mexicoDateString Fecha en formato ISO o legible por Date.
   * @returns Fecha en UTC en formato ISO.
   */
  static mexicoToUTC(mexicoDateString: string): string {
    const mexicoTimeZone = 'America/Mexico_City';

    // Interpretar la fecha como si estuviera en la zona horaria de México
    const mexicoDate = new Date(
      new Intl.DateTimeFormat('en-US', {
        timeZone: mexicoTimeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      }).format(new Date(mexicoDateString))
    );

    // Convertir la fecha interpretada a UTC
    const utcDate = new Date(mexicoDate.toISOString());
    return utcDate.toISOString();
  }

  /**
   * Convierte una fecha en UTC a la zona horaria del navegador.
   * @param utcDateString Fecha en formato ISO o legible por Date en UTC.
   * @returns Objeto con la fecha local y la diferencia con UTC.
   */
  static utcToLocalWithOffset(utcDateString: string): IUtcTime {
    const utcDate = new Date(utcDateString);

    const localDate = new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).format(utcDate);

    const timezoneOffset = new Date().getTimezoneOffset();
    const offsetHours = -timezoneOffset / 60;

    const formattedHours = Math.abs(Math.floor(offsetHours)).toString().padStart(2, '0');
    const formattedMinutes = ((Math.abs(offsetHours) % 1) * 60).toFixed(0).padStart(2, '0');

    const utcOffset = `UTC${offsetHours > 0 ? '+' : ' '}${formattedHours}:${formattedMinutes}`;

    return { localDate, utcOffset };
  }

  /**
   * Convierte una fecha en De mexico completamente a la zona horaria del navegador.
   * @param utcDateString Fecha en formato ISO o legible por Date en UTC.
   * @returns Objeto con la fecha local y la diferencia con UTC.
   */
  static convertDateToLocal(dateString: string): IUtcTime {
    const isUtc = this.isUtcDate(dateString);
    let localInfo: IUtcTime;

    if (isUtc) {
      localInfo = this.utcToLocalWithOffset(dateString);
    }else{
      const utcDate: string = this.mexicoToUTC(dateString);
      localInfo = this.utcToLocalWithOffset(utcDate);
    }
    return localInfo;
  }



   /**
   * Convierte una fecha desde la zona horaria de México o UTC a la zona horaria local del navegador,
   * detectando automáticamente si la fecha está en UTC o no.
   * Devuelve un string amigable con la diferencia de horas UTC incluida.
   * @param dateString Fecha en formato ISO o legible por Date.
   * @returns Regresa un string con la información de la fecha local y diferencia con UTC.
   */
  static convertDateToLocalView(dateString: string): string {
    // Detecta automáticamente si la fecha está en UTC
    const isUtc = this.isUtcDate(dateString);

    // Declara la variable `localInfo`
    let localInfo: IUtcTime;

    if (isUtc) {
      // Si la fecha está en UTC, llama a `convertUTCToLocal`
      localInfo = this.utcToLocalWithOffset(dateString);
    } else {
      // Si no está en UTC, asume que está en la zona horaria de México
      localInfo = this.convertDateToLocal(dateString);
    }

    // Formatea el resultado como un string amigable
    return `${localInfo.localDate} (${localInfo.utcOffset})`;
  }

    /**
   * Verifica si una fecha está en formato UTC (ISO 8601 con Z o un offset UTC explícito).
   * @param dateString Fecha en formato string.
   * @returns `true` si es UTC, `false` si no.
   */
  static isUtcDate(dateString: string): boolean {
    // Expresión regular para detectar "Z" o un offset UTC como "+00:00" o "-05:00"
    const utcRegex = /(\+|-)\d{2}:\d{2}|Z$/;
    return utcRegex.test(dateString);
  }

}
