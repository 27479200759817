<div class="app content kobra">
  <section class="centrado">
    <mat-card class="card-general">
      <mat-card-header>
        <mat-card-title>
          General
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="formGroupGeneral" *ngIf="formGroupGeneral" class="field-container">
          <app-field 
            *ngFor="let field of dynamicFields" 
            [type]="field.type" 
            [formControlName]="field.name"
            [placeholder]="field.placeholder" 
            [required]="true"
            [maxValue]="999999"
            class="field-half-width"
          >
          </app-field>
        
        </form>
      </mat-card-content>
      <mat-card-actions align="end" *ngIf="formGroupGeneral">
        <button class="boton-cancelar" mat-flat-button type="submit" routerLink="../" >
          <mat-icon class="kobra-icon">
            close
          </mat-icon> Cancelar
        </button>
        <button  mat-stroked-button class="boton-guardar"
          (click)="saveConfiguraciones()" m-actions >
          <mat-icon>
            save
          </mat-icon>
          Guardar
        </button>
      </mat-card-actions>
      <mat-card-footer class="kobra-card-footer">
      <mat-progress-bar  *ngIf="cargandoGeneral" class="kobra-card-footer" mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </section>
</div>