import { Domicilio } from 'src/app/shared/components/domicilio.interface';

import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ClientAddress } from '../../../client-address/client-addres.model';
import { CuentaService } from '../../../cuentas/shared/cuenta.service';
import { MessageService } from "../../../shared/message/message.service";
import { AccountService } from "../../account.service";
import { Observable } from 'rxjs';
import { environmentSelector } from '../../../../environments/environment.selector';
import { ApplicationService } from '../../../application/shared/application.service';
import { ServicesGestoresService } from '../../../core/http/services-gestores/services-gestores.service'
import { VisitService } from "../../../visit/visit.service";
import { VerificarDomicilio } from './domicilio.interface';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'form-adress.component',
  templateUrl: 'form-adress.component.html',
  styleUrls: ["form-adress.component.css"],
  providers: [
    CuentaService,
    ServicesGestoresService,
    AccountService,
    VisitService,
  ]
})
export class ModalDomicilioComponent {
  public loaders: { [key: string]: boolean } = {
    Estados: false,
    Municipios: false,
    CodigoPostal: false,
    Colonias: false,
  };
  private environment = environmentSelector();
  public loaderSpinner: boolean;
  public estados: any;
  public municipios: any;
  public codigosPostales: any;
  public colonias: any;
  public selectedIdEstado: number;
  public selectedIdMunicipio: number;
  public selectedIdColonia: number;
  public selectedIdCodigoPostal: number;
  public selectedEstado: string;
  public selectedMunicipio: string;
  public selectedColonia: string;
  public selectedCodigoPostal: string;
  public nuevaColonia: string = null;
  public pais: string;
  public labelEstado: string;
  public labelMunicipio: string;
  public labelColonia: string;
  public labelEligeEstado: string;
  public labelEligeMunicipio: string;
  public labelEligeColonia: string;
  public params_Municipio: number;
  public params_codigoPostal: string;
  public calle: string;
  public numeroExt: string;
  public numeroInt: string;
  public entreCalles: string;
  public clientAddress: ClientAddress;
  public domicilio = [];
  public idEstado: number;
  public idMunicipio: any;
  public codigoPostal: any;
  public idColonia: any;
  public mostrarColonia: boolean ;

  public guardando: boolean;
  public cargando: any;
  domicilioOriginal: VerificarDomicilio;
  activarActualizar:boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ModalDomicilioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cuentaService: CuentaService,
    private messageService: MessageService,
    private servicesGestoresService: ServicesGestoresService,
    private visitService: VisitService,
    private accountService: AccountService,
    private app: ApplicationService,
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnChanges() {
    //this.asginacionVaribles();
    //this.resetForm();
  }

  ngOnInit() {
    this.clientAddress = this.data;
    this.obtenerDomicilioOriginal();
    if (this.data.suburb && this.data.idColonia == null) { 
      this.nuevaColonia = this.data.suburb;
      this.hideMatSelect();
      this.toggleMostrarColonia();
     
    }
    
    this.loadEstados();
    this.inicializarVariables();
    
  }

  obtenerDomicilioOriginal() {
    this.domicilioOriginal = {
      calle: this.clientAddress.calle,
      numeroExt: this.clientAddress.numeroExt,
      numeroInt: this.clientAddress.numeroInt,
      idColonia: this.clientAddress.idColonia,
      idMunicipio: this.clientAddress.idMunicipio,
      idEstado: this.clientAddress.idEstado,
      idCodigoPostal: this.clientAddress.idCodigoPostal,
      entreCalles: this.clientAddress.entreCalles
    };
  }

  validarDomicilios(): boolean {
    let clientAddress: ClientAddress = this.prepareFormData();
    const domicilioModificado: VerificarDomicilio = {
      calle: clientAddress.calle,
      numeroExt: clientAddress.numeroExt,
      numeroInt: clientAddress.numeroInt, 
      idColonia: clientAddress.idColonia,
      idMunicipio: clientAddress.idMunicipio,
      idEstado: clientAddress.idEstado,
      idCodigoPostal: clientAddress.idCodigoPostal,
      entreCalles: clientAddress.entreCalles
    };

    // Convertir ambos objetos a Base64
    const base64Original = this.convertirObjetoABase64(this.domicilioOriginal);
    const base64Modificado = this.convertirObjetoABase64(domicilioModificado);

    // Comparar las cadenas Base64
    return base64Original !== base64Modificado;
  }

  private convertirObjetoABase64(obj: Domicilio): string {
    const jsonString = JSON.stringify(obj); // Convertir objeto a JSON
    return btoa(jsonString); // Convertir JSON a Base64
  }

  public toggleMostrarColonia(): void {
    this.mostrarColonia = !this.mostrarColonia;
  }

  public hideMatSelect(): void {
    this.mostrarColonia = false;
    this.idColonia = null;
  }
  
  
  public inicializarVariables() {
    this.calle= this.clientAddress.calle;
    this.numeroExt= this.clientAddress.numeroExt;
    this.numeroInt= this.clientAddress.numeroInt;
    this.entreCalles= this.clientAddress.entreCalles;
    this.idEstado = this.clientAddress.idEstado;
    this.idMunicipio = this.clientAddress.idMunicipio;
    this.codigoPostal = this.clientAddress.zipCode;
    this.idColonia = this.clientAddress.idColonia;

    this.selectedEstado = this.clientAddress.state;
    this.selectedMunicipio = this.clientAddress.municipality;
    this.selectedIdCodigoPostal = this.clientAddress.idCodigoPostal;
    this.selectedColonia = this.clientAddress.suburb;

    this.pais = this.environment.pais;
    this.labelEstado = this.environment.labelEstado;
    this.labelMunicipio = this.environment.labelMunicipio;
    this.labelColonia = this.environment.labelColonia;
    this.labelEligeEstado = this.environment.labelEligeEstado;
    this.labelEligeMunicipio = this.environment.labelEligeMunicipio;
    this.labelEligeColonia = this.environment.labelEligeColonia;

    this.loadMunicipios();
    this.loadCodigoPostal(0);
    this.loadColonias(0);
     
  }

  private handleError(error: any, comboNombre: string): void {
   
    this.loaders[comboNombre] = false;
    this.messageService.httpError(error);
    this.app.showSnackbar('Aviso', 'Ocurrió un error al cargar ' + comboNombre + '\n' + error, 3000, 'error');
   
  }
  

  private loadEstados(): void {
    this.loaders['Estados'] = true;
    this.visitService.obtenerEstados().subscribe(
      (estados) => {
        this.estados = estados.data;
        this.loaders['Estados'] = false;
      },
      (error) => this.handleError(error, 'Estados')
    );
  }
  
  public loadMunicipios(): void {
    
    if (this.idEstado) {
      this.loaders['Municipios'] = true;
      this.visitService.obtenerMunicipios(this.idEstado).subscribe(
        (municipios) => {
          this.municipios = municipios.data;
          this.loaders['Municipios'] = false;
        },
        (error) => this.handleError(error, 'Municipios')
      );
    } else {
      this.municipios = null;
    }
  }

  
  public loadCodigoPostal(opcion: any): void {
    this.loaders['CodigoPostal'] = true;

    this.params_Municipio = opcion === 1 ? 0 : this.idMunicipio;

    if (this.params_Municipio) {
      this.visitService.obtenerCodigosPostales(this.params_Municipio).subscribe(
        (codigosPostales) => {
          this.codigosPostales = codigosPostales.data;
          this.loaders['CodigoPostal'] = false;
        },
        (error) => this.handleError(error, 'CodigoPostal')
      );
    } else {
      this.loaders['CodigoPostal'] = false;
      this.codigosPostales = null;
    }
  }

  public loadColonias(opcion: any): void {
    this.loaders['Colonias'] = true;

    this.params_Municipio = opcion === 1 ? 0 : this.idMunicipio;
    this.params_codigoPostal = opcion === 1 ? '' : this.codigoPostal;

    if (this.params_Municipio && this.selectedIdCodigoPostal) {
      this.visitService
        .obtenerColonias(this.params_Municipio, Number(this.selectedIdCodigoPostal))
        .subscribe(
          (colonias) => {
            this.colonias = colonias.data;
            this.loaders['Colonias'] = false;
          },
          (error) => this.handleError(error, 'Colonias')
        );
    } else {
      this.loaders['Colonias'] = false;
      this.colonias = null;
    }
  }

  public changeEstado() : void {
    let index : number  = this.estados.findIndex((x:any) => x.idEstado == this.idEstado);
    this.selectedEstado = this.estados[index].nombre;
    this.idMunicipio = null;
    this.selectedIdCodigoPostal = null;
    this.codigoPostal = null;
    this.idColonia = null;
    this.loadMunicipios();
    this.loadCodigoPostal(1);
    //this.loadColonias(1);
    
  }

  public changeMunicipio() : void {
    let index : number  = this.municipios.findIndex((x:any) => x.idMunicipio == this.idMunicipio);
    this.selectedMunicipio = this.municipios[index].nombre;
    this.selectedIdCodigoPostal = null;
    this.codigoPostal = null;
    this.idColonia = null;
    this.colonias = null
    this.loadCodigoPostal(0);
    //this.loadColonias(1);
  }
  
  public changeCodigoPostal() : void {
    let index : number  = this.codigosPostales.findIndex((x:any) => x.codigoPostal == this.codigoPostal);
    this.selectedIdCodigoPostal = this.codigosPostales[index].idCodigoPostal;
    this.idColonia = null;
    this.loadColonias(0);
  }

  public changeColonias() : void {
    this.nuevaColonia = null;
    let index : number  = this.colonias.findIndex((x:any) => x.idColonia == this.idColonia);
    this.selectedColonia = this.colonias[index]?.nombre;
  }

  private prepareFormData(): ClientAddress {
    let clientAddress: ClientAddress = new ClientAddress();
    clientAddress.calle = (this.calle && this.calle.trim()) ? this.calle.trim() : null;
    clientAddress.numeroExt = (this.numeroExt && this.numeroExt.trim()) ? this.numeroExt.trim() : null;
    clientAddress.numeroInt = (this.numeroInt && this.numeroInt.trim()) ? this.numeroInt.trim() : null;
    clientAddress.idCodigoPostal = this.selectedIdCodigoPostal;
    clientAddress.idEstado = this.idEstado;
    clientAddress.idMunicipio = this.idMunicipio;
    clientAddress.idColonia = this.idColonia;
    clientAddress.zipCode = this.codigoPostal;
    clientAddress.entreCalles = (this.entreCalles && this.entreCalles.trim()) ? this.entreCalles.trim() : null;
    return clientAddress;
  }

  public save(): void {
    if(!this.validaModal()){
      this.cargando = this.app.showLoading('Guardando Domicilio...');
      let clientAddress: ClientAddress = this.prepareFormData();
      
      if (this.nuevaColonia) {
        this.idColonia = -1
        clientAddress.idColonia = null;
        this.selectedColonia = this.nuevaColonia;
       }else{
        this.selectedColonia = null;
       }

      let response: Observable<ClientAddress>;
      if (this.clientAddress.idAcreditado) {
          let domicilio = <ClientAddress><unknown>{
            idAcreditado: this.clientAddress.idAcreditado,
            idFinanciera: this.clientAddress.idFinanciera,
            idExterno: this.clientAddress.idExterno,
            calle: clientAddress.calle,
            numeroExt: clientAddress.numeroExt,
            numeroInt: clientAddress.numeroInt,
            idColonia: clientAddress.idColonia,
            idMunicipio: clientAddress.idMunicipio,
            idEstado: clientAddress.idEstado,
            idCodigoPostal: clientAddress.idCodigoPostal,
            codigoPostal: this.codigoPostal,
            estado: this.selectedEstado,
            municipio: this.selectedMunicipio,
            colonia: this.selectedColonia,
            entreCalles: this.entreCalles,
            lat: this.clientAddress.lat,
            lng: this.clientAddress.lng,
            idCuenta: this.clientAddress.idCuenta,
            idDomicilioAcreditado:this.clientAddress.idDomicilioAcreditado
                      
          };

        
          //modificar el domicilio del acreditado
          this.cuentaService.saveDomicilio(this.clientAddress.idAcreditado, domicilio)
          .subscribe(
            response => {
              
              if(response.success){
                //this.loader = false;
                this.app.showSnackbar(
                  "Aviso",
                  "Información actualizada correctamente.",
                  3000,
                  "success"
                );
                this.dialogRef.close(true);
              }else{
                //this.loader = false;
                this.app.showSnackbar(
                  "Aviso",
                  "Ocurrio un error al actualizar el domicilio.",
                  3000,
                  "error"
                );
              }
              
              this.app.hideLoading(this.cargando);
            },
            error => {
              this.app.hideLoading(this.cargando);
              this.messageService.httpError(error);
              this.app.showSnackbar(
                "Aviso",
                  error,
                3000,
                "error"
              );

            }
           
          )
      }
    }
  }

  public validaModal ():boolean {
    let respuesta = false;
    if(!this.calle) {
      this.app.showSnackbar(
        "Aviso",
        "Es necesario el campo calle",
        3000,
        "warning"
      );
      //this.loader = false;
      respuesta = true;

    }else if(!this.numeroExt) {
      this.app.showSnackbar(
        "Aviso",
        "Es necesario el campo numero exterior",
        3000,
        "warning"
      );
     // this.loader = false;
      respuesta = true;


    }else if(!this.idEstado) {
      this.app.showSnackbar(
        "Aviso",
        "Es necesario elegir un estado",
        3000,
        "warning"
      );
      //this.loader = false;
      respuesta = true;


    }else if(!this.idMunicipio) {
      this.app.showSnackbar(
        "Aviso",
        "Es necesario elegir un municipio",
        3000,
        "warning"
      );
      //this.loader = false;
      respuesta = true;

    
    }else if(!this.selectedIdCodigoPostal && this.pais == 'MX') {
      this.app.showSnackbar(
        "Aviso",
        "Es necesario elegir un codigo postal",
        3000,
        "warning"
      );
      //this.loader = false;
      respuesta = true;

    }
    return respuesta;
  
  }
}