import { Injectable } from '@angular/core';

import { PersonalReference } from "./personal-reference.model";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';

@Injectable()
export class PersonalReferenceService {

  constructor(
    private visitasServ: ServicesVisitasService
  ) { }

  public all( agentID: number): Observable<PersonalReference[]> {
    return this.visitasServ.get(`/agentes/${agentID}/referencia-personal?activo=true`).pipe(
      map((res) => {
        let referencesData = res.data;
        let references: PersonalReference[] = [];
        for (let i = 0; i < referencesData.length; i++) {
          let reference: PersonalReference = new PersonalReference();
          reference.id = +referencesData[i].idReferenciaPersonal;
          reference.name = referencesData[i].nombre;
          reference.surname = referencesData[i].apellido;
          reference.fullName = referencesData[i].nombre + ' ' + referencesData[i].apellido;
          reference.phoneNumber = referencesData[i].telefono;
          reference.relationshipID = referencesData[i].idParentescoReferenciaPersonal;
          reference.relationship.id = referencesData[i].idParentescoReferenciaPersonal;
          reference.relationship.name = referencesData[i].nombreParentesco;
          reference.agentID = referencesData[i].idAgente;
          references.push(reference);
        }
        return references;
      })
    )
  }

  public add( personalReference: PersonalReference ): Observable<PersonalReference> {
    return this.visitasServ.post(`/agentes/${personalReference.agentID}/referencia-personal`, personalReference).pipe(
      map(res => <PersonalReference> res.data)
    );
  }

  public delete( id: number, personalReference: PersonalReference ): Observable<any> {
    personalReference.activo = false;

    return this.visitasServ.patch(`/agentes/referencia-personal/${personalReference.id}`, personalReference).pipe(
      map(res => res.data)
    )
  }

  public update( personalReference: PersonalReference ): Observable<PersonalReference> {
    personalReference.activo = true;

    return this.visitasServ.patch(`/agentes/referencia-personal/${personalReference.id}`, personalReference).pipe(
      map(res => <PersonalReference> res.data)
    )
  }
}