import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LenderService } from '@lender/lender.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { VisitasCriteriosService } from '../visitas-criterios.service';
import { ICriterio, IClienteCriterio, IProductoCriterio, ITablaCriteriosConfig, ILoadingsCriterio } from '../criterios.interface';
import { Lender } from '@lender/lender.model';

@Component({
  selector: 'app-criterios-calidad-nuevo',
  templateUrl: './criterios-calidad-nuevo.component.html',
  styleUrls: ['./criterios-calidad-nuevo.component.css']
})
export class CriteriosCalidadNuevoComponent {
  idCliente: number = null;
  idProducto: number = null;
  criterios: ICriterio[] = [];
  criteriosIniciales: ICriterio[] = []; // Almacena los criterios originales
  nuevoCriterio: ICriterio = { nombre: '', ponderacion: null };
  totalPonderaciones: number = 0;
  clientes: IClienteCriterio[] = [];
  productos: IProductoCriterio[] = [];
  loadings: ILoadingsCriterio = { clientes: false, productos: false, criterios: false };  editarPresente: boolean = false;

  tablaCriterios: ITablaCriteriosConfig  = {
    headers: false,
    tableHeaders: false,
    multiSelect: false,
    columnas: [
      {
        name: 'Criterio de la visita',
        key: 'nombre', // Coincide con `nombre` de la interfaz Criterio
        hide: false
      },
      {
        name: 'Ponderación',
        key: 'ponderacion', // Coincide con `ponderacion` de la interfaz Criterio
        hide: false
      },
      {
        name: ' ',
        key: 'delete',
        hide: false,
        icono: 'delete_forever',
        tooltip: 'Eliminar',
        type: 'icono'
      }
    ]
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private lenderService: LenderService,
    private appService: ApplicationService,
    private visitasCriteriosService: VisitasCriteriosService,
  ) { }

  ngOnInit() {

    this.route.url.subscribe(urlSegments => {
      const urlSegmentStrings = urlSegments.map(segment => segment.path);
      this.editarPresente = urlSegmentStrings.includes('editar');
    });

    if (!this.editarPresente) {
      this.idCliente = null;
      this.idProducto = null;
      this.loadLenders();

    } else {
      this.idCliente = +this.route.snapshot.queryParamMap.get('idCliente');
      this.idProducto = +this.route.snapshot.queryParamMap.get('idProducto');
      if (!this.idCliente || !this.idProducto) {
        this.appService.showSnackbar('¡Aviso!', 'Faltan parámetros en la URL.', 2000, 'error');
        this.router.navigate([`catalogos/criterios-calidad`]);
      }

      this.loadLenders();
      this.loadProductos();
      this.loadCriterios();

    }

  }

  agregarCriterio(): void {
    // Validar que el nombre no esté vacío
    if (!this.nuevoCriterio.nombre || this.nuevoCriterio.nombre.trim() === '') {
      this.appService.showSnackbar('¡Aviso!', 'El nombre del criterio es obligatorio.', 2000, 'warning');

      return;
    }

    // Validar que el nombre tenga una longitud aceptable
    if (this.nuevoCriterio.nombre.length < 1 || this.nuevoCriterio.nombre.length > 100) {
      this.appService.showSnackbar('¡Aviso!', 'El nombre del criterio debe tener entre 1 y 100 caracteres.', 2000, 'warning');
      return;
    }

    // Validar que el criterio no exista ya
    const criterioExistente = this.criterios.some(
      (criterio) => criterio.nombre.toLowerCase() === this.nuevoCriterio.nombre.trim().toLowerCase()
    );

    if (criterioExistente) {
      this.appService.showSnackbar('¡Aviso!', 'El criterio ya existe en la lista.', 2000, 'warning');
      return;
    }

    // Convertir ponderación a número
    const ponderacion = Number(this.nuevoCriterio.ponderacion);

    // Validar que la ponderación sea un número válido
    if (isNaN(ponderacion)) {
      this.appService.showSnackbar('¡Aviso!', 'La ponderación debe ser un número válido.', 2000, 'warning');
      return;
    }

    // Validar que la ponderación sea mayor a 0
    if (ponderacion <= 0) {
      this.appService.showSnackbar('¡Aviso!', 'La ponderación debe ser mayor a 0.', 2000, 'warning');
      return;
    }

    // Calcular el nuevo total de ponderaciones
    const nuevoTotal = this.getTotalPonderaciones() + ponderacion;

    // Validar que el nuevo total no exceda el 100%
    if (nuevoTotal > 100) {
      this.appService.showSnackbar('¡Aviso!', `La suma total de las ponderaciones no puede exceder el 100%. Actualmente tienes el ${this.getTotalPonderaciones()}%.`, 2000, 'warning');

      return;
    }

    // Formatear la ponderación a dos decimales
    const ponderacionFormateada = parseFloat(ponderacion.toFixed(2));
    // Agregar el nuevo criterio con la ponderación formateada
    this.criterios = [...this.criterios, { ...this.nuevoCriterio, ponderacion: ponderacionFormateada }];
    // Reiniciar el formulario
    this.nuevoCriterio = { nombre: '', ponderacion: null };
    // Actualizar el total de ponderaciones
    this.actualizarTotalPonderaciones();

  }

  eliminarCriterio(event: any): void {
    this.criterios = this.criterios.filter((c) => c.nombre !== event.row.nombre);
    this.actualizarTotalPonderaciones();
  }

  // Calcular el total de ponderaciones
  getTotalPonderaciones(): number {
    return this.criterios.reduce((sum, c) => sum + c.ponderacion, 0);
  }

  // Actualizar total de ponderaciones
  actualizarTotalPonderaciones(): void {
    this.totalPonderaciones = this.getTotalPonderaciones();
  }

  hasChanges(): boolean {
    return JSON.stringify(this.criterios) !== JSON.stringify(this.criteriosIniciales);
  }

  guardar(): void {
    // Validar que se haya seleccionado un cliente
    if (!this.idCliente) {
      this.appService.showSnackbar('¡Aviso!', 'Debe seleccionar un cliente.', 2000, 'warning');
      return;
    }

    // Validar que se haya seleccionado un producto
    if (!this.idProducto) {
      this.appService.showSnackbar('¡Aviso!', 'Debe seleccionar un producto.', 2000, 'warning');
      return;
    }

    // Validar que al menos exista un criterio
    if (!this.criterios.length) {
      this.appService.showSnackbar('¡Aviso!', 'Debe agregar al menos un criterio.', 2000, 'warning');
      return;
    }

    // Validar que la suma total de ponderaciones sea 100%
    if (this.getTotalPonderaciones() !== 100) {
      this.appService.showSnackbar('¡Aviso!', 'La suma de las ponderaciones debe ser igual a 100%.', 2000, 'warning');
      return;
    }

    // Validar si hay cambios antes de guardar
    if (!this.hasChanges()) {
      this.appService.showSnackbar('¡Aviso!', 'No se han detectado cambios para guardar.', 2000, 'warning');
      return;
    }

    const loading = this.appService.showLoading(`Estamos guardando los criterios de visita`);

    const request = this.editarPresente
      ? this.visitasCriteriosService.actualizarCriterios(this.idCliente, this.idProducto, this.criterios)
      : this.visitasCriteriosService.guardarCriterios(this.idCliente, this.idProducto, this.criterios);

    request.subscribe({
      next: () => {
        this.appService.hideLoading(loading);
        this.appService.showSnackbar('Aviso', 'Se han guardado los criterios de visita correctamente', 3000, 'success');
        this.cancelar();
      },
      error: (error) => {
        this.appService.hideLoading(loading);
        this.appService.showError(error);
      },
    });
  }

  onChangeLender(event: any): void {
    this.idCliente = event;
    this.loadProductosSinCriterioVisita();
  }

  onChangeProducto(event: any): void {
    this.idProducto = event;
  }

  // Cargar financieras
  loadLenders(): void {
    this.loadings['clientes'] = true;
    this.lenderService.getAll('active').subscribe((lenders: Lender[]) => {
      // Mapear datos de Lender a Cliente
      this.clientes = lenders.map((lender) => ({
        idCliente: lender.idCliente,
        nombreCliente: lender.nombre,
      }));
      this.loadings['clientes'] = false;
    });
  }
  loadProductos(): void {
    this.loadings['productos'] = true;
  
    this.visitasCriteriosService.obtenerProductosPorCliente(this.idCliente).subscribe({
      next: (response) => {
        // Validar que la respuesta tenga el formato esperado
        if (response?.data && Array.isArray(response.data)) {
          this.productos = response.data.map((producto) => ({
            idProducto: producto.idProducto,
            nombreProducto: producto.nomProducto || producto.nombreProducto, // Compatibilidad con ambos formatos
          }));
        }
      },
      error: (err) => {
       this.appService.showSnackbar('Aviso','Error al cargar productos', 2000, 'error');
       console.error('Error al cargar productos:', err);
        this.productos = [];
      },
      complete: () => {
        this.loadings['productos'] = false;
      },
    });
  }
  

  // Cargar productos según la financiera seleccionada
  loadProductosSinCriterioVisita(): void {
    if (!this.idCliente) return;
  
    this.loadings['productos'] = true;
  
    this.visitasCriteriosService.obtenerProductosSinCriteriosDeVisita(this.idCliente).subscribe({
      next: (res) => {
        // Validar que res.data sea un array antes de asignar
        if (res.data && Array.isArray(res.data)) {
          this.productos = res.data.map((producto) => ({
            idProducto: producto.idProducto,
            nombreProducto: producto.nombreProducto || producto.nomProducto, // Manejo de compatibilidad
          }));
        }
      },
      error: (err) => {
        this.appService.showSnackbar('Aviso','Error al cargar productos sin criterios de visita', 2000, 'error');
        console.error('Error al cargar productos sin criterios de visita:', err);
        this.productos = [];
      },
      complete: () => {
        this.loadings['productos'] = false;
      },
    });
  }
  

  // Cargar criterios existentes en modo edición
  loadCriterios(): void {
    this.loadings.criterios = true;
  
    this.visitasCriteriosService.obtenerCriterios(this.idCliente, this.idProducto).subscribe({
      next: (res) => {
        if (res.data && Array.isArray(res.data)) {
          // Convertir cada ponderación a número y asignar los datos iniciales
          this.criterios = res.data.map((criterio) => ({
            ...criterio,
            ponderacion: Number(criterio.ponderacion),
          }));
          // Guardar una copia de los datos iniciales para comparación
          this.criteriosIniciales = JSON.parse(JSON.stringify(this.criterios));
          this.actualizarTotalPonderaciones();
        
        }
      },
      error: (err) => {
        this.appService.showSnackbar('Aviso','Error al cargar criterios', 2000, 'error');
        console.error('Error al cargar criterios:', err);
        this.criterios = [];
      },
      complete: () => {
        this.loadings.criterios = false;
      },
    });
  }
  

  cancelar(): void {
    const currentParams = this.route.snapshot.queryParams;
    const fParam = currentParams['f'];
    // Eliminamos todos los parámetros existentes y luego agregamos el parámetro 'f'
    this.router.navigate(['/catalogos/criterios-calidad'], {
      queryParams: { f: fParam }
    });
  }

}
