<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false; inactivarHijo()"></router-outlet>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start"
    [opened]="false">
    <mat-toolbar>
        <button class="filter-button-side" mat-flat-button matTooltip="Ocultar filtros" mat-tooltip-panel-above
            (click)="filtrosSideNav.toggle()">
            <mat-icon>cancel</mat-icon>
        </button>
        Filtros
    </mat-toolbar>
    <ol class="lista-filtros-side">
        <li class="filtro-side">
            <mat-select-search class="id-card-field" [label]="'Cliente'" [placeHolder]="'Selecciona un cliente'"
                [value]="'idCliente'" [labelField]="'nombre'" [icon]="'account_balance'" [options]="lenders"
                [(ngModel)]="idLender" (ngModelChange)="onChangeLender($event);" [ngModelOptions]="{standalone: true}">
            </mat-select-search>

        </li>
        <!-- <li>
            <mat-checkbox class="kobra-checkbox" [(ngModel)]="filters.activa" [color]="'primary'">
                Activa
            </mat-checkbox>
        </li> -->
    </ol>
    <ol class="lista-filtros-buttons">
        <button class="boton-accion-front" style="width: 100%;" mat-stroked-button color="primary"
            (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; setFilter()">
            <mat-icon>filter_alt</mat-icon>
            Filtrar
        </button>
        <button style="width: 100%; margin-top: 10px;" mat-stroked-button (click)="clearFilter()">
            <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
            Limpiar Filtros
        </button>
    </ol>
</mat-sidenav>

<div class="app content tabla kobra" *ngIf="criterioList && !hijosActivos">
    <div class="button-container">
        <div class="button-toggle-group-container">
            <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
                <mat-button-toggle value="filtros"
                    (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
                    matTooltip="Filtrar administradores" mat-tooltip-panel-above>
                    <mat-icon class="kobra-icon">filter_alt</mat-icon>
                    <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{
                        totalFiltros }}</span>

                </mat-button-toggle>
                <mat-button-toggle value="actualizar" (click)="criteriosGrid(filters.page ? filters.page : 1);"
                    matTooltip="Refrescar" mat-tooltip-panel-above>
                    <mat-icon class="kobra-icon">sync</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="subMenu" matTooltip="Acciones" mat-tooltip-panel-above
                    [matMenuTriggerFor]="menu">
                    <mat-icon class="kobra-icon">more_vert</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="ayuda" matTooltip="Ayuda" mat-tooltip-panel-above (click)="ayuda = !ayuda;">
                    <mat-icon color="primary">help</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="agregarCriterio()">
            <mat-icon class="kobra-icon">add</mat-icon>
            <span>Nuevo</span>
        </button>
        <button mat-menu-item [disabled]="itemsSelected.length !== 1" (click)="editarCriterio()">
            <mat-icon class="kobra-icon">edit</mat-icon>
            <span>Editar</span>
        </button>
        
    </mat-menu>
    <app-table [classContainer]="'tabla-completa'" [loading]="loadings.criterios" [columns]="tabla.columns"
        [items]="criterioList" [headers]="tabla.headers" [multiSelect]="tabla.multiSelect"
        (onSelectedRows)="onSelected($event)" (onLinkRow)="onLinkRow($event)" (sortChange)="sortChange($event)"
        
        (onDoubleClick)="onDoubleClick($event)">


        <div t-header>
            <mat-card *ngIf="ayuda" class="cardAyuda">
                <mat-card-content>
                    <p>
                        <mat-icon color="primary">help</mat-icon>
                        El módulo "Criterios de Calidad" permite gestionar y configurar criterios específicos para la evaluación de productos vinculados a los clientes. Esta herramienta facilita la definición, edición y administración de criterios y sus respectivas ponderaciones, asegurando que las configuraciones cumplan con los objetivos establecidos.
                    </p>
                    Las acciones clave de este módulo son:
                    <p>
                        <mat-icon class="kobra-icon">filter_alt</mat-icon>
                        <b>Filtrar:</b> Permite refinar la búsqueda de criterios aplicando filtros personalizados por cliente, producto u otros parámetros.
                    </p>
                    <p>
                        <mat-icon class="kobra-icon">sync</mat-icon>
                        <b>Refrescar:</b> Recarga la lista de criterios en función de los filtros aplicados, asegurando que se muestren los datos más actualizados.
                    </p>
                    <p>
                        <mat-icon class="kobra-icon">add</mat-icon>
                        <b>Nuevo:</b> Da la posibilidad de agregar un nuevo criterio con sus respectivos detalles, como el nombre y la ponderación.
                    </p>
                    <p>
                        <mat-icon class="kobra-icon">edit</mat-icon>
                        <b>Editar:</b> Permite modificar los criterios existentes para reflejar cambios en las configuraciones o necesidades.
                    </p>
                    <p>
                        <b>Restricciones y Validaciones:</b> El sistema asegura que la suma total de las ponderaciones de los criterios siempre sea igual al 100%. Esto garantiza que las configuraciones sean coherentes y cumplan con los estándares establecidos.
                    </p>
                    
                </mat-card-content>
            </mat-card>
        </div>
        

        <ng-container t-paginator>
            <mat-paginator showFirstLastButtons [length]="pagination?.totalRows" [pageIndex]="pagination?.page-1"
                [pageSize]="datosConsulta.rows" (page)="onPage($event)" aria-label="Select page of periodic elements">
            </mat-paginator>
        </ng-container>
    </app-table>
</div>