import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApplicationService } from '../../application/shared/application.service';
import { LenderService } from '../lender.service';
import { PaqueteFinancieraFormComponent } from 'src/app/paquete-financiera/form/form.component';

@Component({
  selector: 'app-lender-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css', '../../shared/components/wizard/wizard.component.css'],
  providers: [LenderService]
})
export class LenderNewComponent implements OnInit {
	formGroup: FormGroup;
	contacts: any[] = [];
  idFinancial;
  formGroupContacto: FormGroup;
  showNewContact: boolean = false;

  @ViewChild(PaqueteFinancieraFormComponent)
  package: PaqueteFinancieraFormComponent;

  constructor(
    private router: Router,
  	private formBuilder: FormBuilder,
    private appService: ApplicationService,
    private lenderService: LenderService,
  ) { }

  ngOnInit() {
  	this.formGroup = this.formBuilder.group({
			nombre: ['', Validators.required],
			razonSocial: ['', Validators.required],
			telefono: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
			sitioWeb: [''],
			logo: [''],
			email: ['', [Validators.required, Validators.email]],
			nombreEncargado: ['', Validators.required]
    });

    this.formGroupContacto = this.formBuilder.group({
      nombre: ['', Validators.required],
      numeroTelefono: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]]
    });
  }

  addContact() {
    this.formGroupContacto = this.formBuilder.group({
      nombre: ['', Validators.required],
      numeroTelefono: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]]
    });
    this.showNewContact = true;
  }

  saveContacto(){
    this.contacts.unshift(this.formGroupContacto.value);
    this.cancelarContacto();
  }

  cancelarContacto(){
    this.showNewContact = false;
  }
  cancelar() {
    this.router.navigate(['/clientes']);
  }

  register() {
    if (this.formGroup.invalid) {
      return;
    }

    const loading = this.appService.showLoading('Estamos guardando al nuevo cliente.');

    const { logo , ...values } = this.formGroup.value;

    const data = Object.assign({}, values, {
      nombre: values.nombre.trim(),
      razonSocial: values.razonSocial.trim(),
      contactos: this.contacts.slice()
    });

    const success = () => {
      this.appService.hideLoading(loading);
      this.appService.showSnackbar('¡Aviso!', 'Se ha guardado correctamente el cliente', 3000, 'success');
      this.router.navigate(['/clientes']);
    };

    const uploadLogo = idFinanciera => {
      this.lenderService.uploadLogo(logo, idFinanciera).subscribe(() => {
        success();
      }, (error) => {
        this.appService.showError(error);
        this.appService.hideLoading(loading);
      });      
    };

    if (Boolean(this.idFinancial)) {
      return uploadLogo(this.idFinancial);
    }

    this.lenderService.addClient(data).subscribe((data: any) => {
      this.idFinancial = data.idFinanciera;

      if (Boolean(logo)) {
        uploadLogo(this.idFinancial);
      } else {
        success();
      }
    }, (err: any) => {
      this.appService.showError(err);
      this.appService.hideLoading(loading);
    });
  }

  onSavePackage(packageFinancial: any) {
    this.router.navigate(['/clientes']);
  }
}
