<div class="app content kobra" *ngIf="!transitionEffect">
  <div class="centrado">
    <mat-card class="card-center anchoCard">
      <div class="contendor">
        <div class="item pading-panel-1">
          <mat-grid-list cols="1" rowHeight="100px">
            <mat-grid-tile>
              <strong style="font-size: 40px; line-height: 1;">¡Hola, mundo!</strong>
            </mat-grid-tile>
          </mat-grid-list>

          <!-- Formulario de inicio de sesión -->
          <form *ngIf="!mostrarCodigo2FA && !mostrarQR" [formGroup]="form" (submit)="logIn()">
            <mat-form-field class="width100">
              <mat-label>Correo electrónico</mat-label>
              <input class="login-input" name="username" id="username" #search matInput
                placeholder="Capture su dirección de correo" formControlName="correo" [formValidationInput]="correoVld">
              <mat-error
                *ngIf="form.controls['correo'].hasError('email') && !form.controls['correo'].hasError('required')">
                Introduce un correo válido
              </mat-error>
              <mat-error *ngIf="form.controls['correo'].hasError('required')">
                Dirección de correo es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="width100">
              <mat-label>Contraseña</mat-label>
              <input class="login-input" matInput placeholder="Capture su contraseña" formControlName="contrasena"
                [formValidationInput]="contrasenaVld" [type]="hide ? 'password' : 'text'">
              <mat-error
                *ngIf="form.controls['contrasena'].hasError('contrasena') && !form.controls['contrasena'].hasError('required')">
                Introduce tu contraseña
              </mat-error>
              <mat-error *ngIf="form.controls['contrasena'].hasError('required')">
                Contraseña es <strong>requerida</strong>
              </mat-error>
              <mat-icon class="ver-password kobra-icon" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' :
                'visibility'}}</mat-icon>
            </mat-form-field>

            <mat-form-field class="width100">
              <mat-label for="">Selecciona tu país</mat-label>
              <mat-select [(ngModel)]="paisActual" [ngModelOptions]="{standalone: true}" (ngModelChange)="cambioPais()">
                <mat-option *ngFor="let pais of paises" [value]="pais">
                  <img [src]="pais.img" style="height: 15px;" /> {{pais.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="login-button-row">
              <button mat-stroked-button class="boton-guardar" [ngClass]="{ 'buton-disabled' : loading}"
                [disabled]="loading">Iniciar sesión</button>
            </div>
          </form>
          <!-- Formulario para ingresar el código 2FA -->
          <div *ngIf="mostrarCodigo2FA || mostrarQR" @fadeInOut>
            <form [formGroup]="formCodigo2FA" (submit)="verificarCodigo2FA()">
              <div class="informacion-codigo2fa" style="text-align: left;">
                <p>Estás intentando iniciar sesión con el usuario <strong>{{ form.value.correo }}</strong></p>
                <p *ngIf="mostrarCodigo2FA">
                  Por favor, ingresa el código de 6 dígitos que hemos enviado a tu correo.
                </p>
                <p *ngIf="mostrarQR">
                  Por favor, abre tu aplicación de autenticación e ingresa el código de 6 dígitos generado.
                </p>
              </div>

              <mat-form-field class="width100">
                <mat-label>Código de autenticación</mat-label>
                <input 
                  #codigoInput
                  class="login-input" 
                  matInput 
                  placeholder="Ingrese el código de 6 dígitos"
                  formControlName="codigo2FA" 
                  maxlength="6" 
                  type="text" 
                  inputmode="numeric" 
                  validateNumericInput>
                <mat-error *ngIf="formCodigo2FA.get('codigo2FA').hasError('required')">
                  El código de autenticación es <strong>requerido</strong>
                </mat-error>
                <mat-error *ngIf="formCodigo2FA.get('codigo2FA').hasError('minlength') || formCodigo2FA.get('codigo2FA').hasError('maxlength')">
                  El código debe tener <strong>6 dígitos</strong>
                </mat-error>
                <mat-error *ngIf="formCodigo2FA.get('codigo2FA').hasError('pattern')">
                  Solo se permiten <strong>números</strong>
                </mat-error>
              </mat-form-field>
              
              <div class="login-button-row">
                <button mat-stroked-button class="boton-guardar" [ngClass]="{ 'buton-disabled' : loading }"
                  [disabled]="loading">{{ loading ? 'Verificando...' : 'Verificar código' }}</button>
              </div>
            </form>
          </div>
          <!-- FIN Formulario para ingresar el código 2FA -->
        </div>
        <div class="item relleno centrado">
          <img style="width:50%" src="assets/img/Logo_kobra.png" alt="Logo Kobra">
        </div>

      </div>
    </mat-card>
    <div class="footer">
      <mat-grid-list cols="1" rowHeight="35px">
        <mat-grid-tile>
          &copy; {{year}} Kobra - Todos los derechos reservados.
        </mat-grid-tile>
        <mat-grid-tile>
          <a href="https://kobra.red/politica-de-privacidad" target="_blank">Política de privacidad <mat-icon
              class="login_open">open_in_new</mat-icon></a>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</div>