import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[decimalValidator]'
})
export class DecimalValidatorDirective {
  @Input() decimalPlaces: number = 2; // Por defecto, 2 decimales
  @Input() maxValue: number = Infinity; // Por defecto, sin límite máximo

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  @HostListener('keyup', ['$event'])
  onInput(event: any) {
    const inputElement = event.target;
    let value = inputElement.value;

    // Permitir solo números con un punto decimal opcional y limitar los decimales
    const regex = this.decimalPlaces === 0
      ? /^\d*$/ // Solo números enteros
      : new RegExp(`^\\d*(\\.\\d{0,${this.decimalPlaces}})?$`); // Números con decimales limitados

    // Validar el valor actual
    if (!regex.test(value)) {
      const parts = value.split('.');
      const integerPart = parts[0].replace(/\D/g, ''); // Eliminar caracteres no numéricos
      const decimalPart = parts[1]?.slice(0, this.decimalPlaces) || ''; // Limitar decimales
      value = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    }

    // Si está vacío o solo tiene un punto, limpiar
    if (value === '.' || value === '') {
      value = '';
    }

    // Convertir el valor a número para comparar con el valor máximo
    const numericValue = parseFloat(value);

    if (numericValue > this.maxValue) {
      value = this.maxValue.toFixed(this.decimalPlaces); // Limitar al valor máximo
    }

    // Actualizar el valor en el input
    inputElement.value = value;
  }
}
