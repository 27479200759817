export class ProductosFinanciera{
    idFinanciera: number;
    idProducto: number;
    idAgente: number;
    nombreProducto: String;
    idAgenteProducto: String;
    fechaRegistroProducto: Date;

    public static map(data: any): ProductosFinanciera{
        let instancia = new ProductosFinanciera();

        instancia.idFinanciera = data.idFinanciera;
        instancia.idProducto = data.idProducto;
        instancia.idAgente = data.idAgente;
        instancia.nombreProducto = data.nombreProducto;
        instancia.idAgenteProducto = data.idAgenteProducto;
        instancia.fechaRegistroProducto =  data.fechaRegistroProducto

        return instancia;
    }
}