import { PhoneType } from "../../../phone-type/phone-type.model";

export class WorkReference {
  id: number
  agentID: number;
  cellphoneNumber: string;
  company: string;
  editMode: boolean;
  fullName: string;
  name: string;
  phone: string;
  phoneNumber: string;
  surname: string;
  years: number;
  activo?: boolean;
  idCobrador?: number;
  totalReferenciasLaborales?: number;

  constructor() {
    //this.phoneType = new PhoneType();
  }
}