import { ApplicationService } from '../../../application/shared/application.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LenderService } from '../../../lender/lender.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-configuration-paquetes-clientes',
  templateUrl: 'configuration.component.html',
  styleUrls: ['configuration.component.css'],
  providers: [LenderService]
})
export class PaquetesClientesConfigurationComponent implements OnInit {
  formGroupGeneral: FormGroup;
  formGroupSaldos: FormGroup;
  title: string = '';
  dynamicFields: any[] = [];
  camposDinamicos: any[] = [];
  products: any[] = [];
  hijosActivos: boolean = false;
  data = null;
  // cargandoProductos: boolean = true;
  cargandoSaldos: boolean = true;
  cargandoGeneral: boolean = true;

  constructor(
    private lenderService: LenderService,
    private appService: ApplicationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute

  ) {

  }

  ngOnInit() {
    this.init();
  }

  async init() {
    // Obtener parámetros de la consulta (query params) de la ruta
    this.route.queryParams.subscribe(params => {
      this.data = params;
    });
    // Mostrar un mensaje de carga mientras se obtiene la configuración
    //const loading = this.appService.showLoading('Cargando configuración');

    try {


      // Obtener la configuración de la financiera de forma asíncrona
      const fields = await this.lenderService
        .getConfiguration(this.data.idFinanciera)
        .toPromise();
        this.cargandoGeneral = false;

      // Inicializar listas y objetos para campos dinámicos y grupo de formulario dinámico
      let dynamicFields: any[] = [];
      let dynamicFormGroup = {};

      // Procesar cada campo de la configuración recibida
      fields.forEach(field => {
        const fieldName = `field_${field.idConfiguracion}`;

        // Configurar propiedades del campo dinámico
        dynamicFields.push({
          required: true,
          type: field.idConfiguracion === 8 ? 'string' : 'number',
          name: fieldName,
          placeholder: field.nombre,
          id: field.idConfiguracion
        });

        // Crear un control de formulario dinámico y agregarlo al grupo de formulario dinámico
        dynamicFormGroup[fieldName] = new FormControl(field.valor, Validators.required);
      });


      // Almacenar la configuración dinámica y crear el grupo de formulario dinámico
      this.dynamicFields = dynamicFields.slice();
      this.formGroupGeneral = this.formBuilder.group(dynamicFormGroup);

        // Obtener la configuración de la financiera de forma asíncrona
        const campos = await this.lenderService
        .getConfiguracionSaldos(this.data.idFinanciera)
        .toPromise();
        this.cargandoSaldos = false;

        // Inicializar listas y objetos para campos dinámicos y grupo de formulario dinámico
        let camposDinamicos: any[] = [];
        let dinamicoFormGroup = {};

        // Procesar cada campo de la configuración recibida
        campos.forEach(campo => {
          const nombreCampo = `field_${campo.idConfiguracion}`;


          // Configurar propiedades del campo dinámico
          camposDinamicos.push({
            required: true,
            type:  'number',
            name: nombreCampo,
            placeholder: campo.descripcion,
            id: campo.idConfiguracion
          });

          // Crear un control de formulario dinámico y agregarlo al grupo de formulario dinámico
          dinamicoFormGroup[nombreCampo] = new FormControl(campo.valor, Validators.required);
        });

        // Almacenar la configuración dinámica y crear el grupo de formulario dinámico
        this.camposDinamicos = camposDinamicos.slice();
        this.formGroupSaldos = this.formBuilder.group(dinamicoFormGroup);
    } catch (error) {
      // En caso de error, ocultar el mensaje de carga y mostrar un mensaje de error
      //this.appService.hideLoading(loading);
      // this.cargandoProductos = false;
      this.cargandoSaldos = false;
      this.cargandoGeneral = false;
      this.appService.showError(error);
    }
  }

  saveConfiguracionesSaldos() {
    // Verificar si algún valor es null o undefined, pero permitir 0
    if (this.camposDinamicos.some((f: any) => {
      const controlValue = this.formGroupSaldos.get(f.name).value;
      return controlValue === null || controlValue === undefined || controlValue === '';
    })) {
      this.appService.showSnackbar('Aviso', 'Todos los campos son obligatorios. de saldos', 3000, 'warning');
      return; // No se guarda la configuración si algún valor está vacío
    }

    // Mostrar mensaje de carga
    const loading = this.appService.showLoading(`Guardando la configuración de saldos para ${this.data.nombreFinanciera}`);

    const saveConfiguracionSaldos = (valores: any[]) => {
      return this.lenderService.saveConfiguracionSaldos(this.data.idFinanciera, valores);
    };

    // Éxito al guardar
    const handleSaveSuccess = () => {
      this.appService.hideLoading(loading);
      this.appService.showSnackbar('Aviso', 'Se ha guardado la configuración de saldos correctamente', 3000, 'success');
    };

    // Error al guardar
    const handleSaveError = (error: any) => {
      this.appService.hideLoading(loading);
      this.appService.showError(error);
    };

    // Mapeo de valores de los campos dinámicos
    const valores = this.camposDinamicos.map((f: any) => ({
      idConfiguracion: f.id,
      valor: this.formGroupSaldos.get(f.name).value // Capturar el valor del control
    }));

    // Guardar la configuración
    saveConfiguracionSaldos(valores).subscribe(handleSaveSuccess, handleSaveError);
  }

}
