import { Cuenta } from './../../cuentas/shared/cuenta';
import { PermisosService } from './../../application/shared/permisos.service';
import { FiltrosAgentesRecorridos } from './../modelos/filtros-agentes-recorridos.model';
import { Component, OnInit, ElementRef, Pipe, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';

import { environmentSelector } from "../../../environments/environment.selector";
import { Agent } from "../agent.model";
import { AgentStatus } from "../agent-status.model";
import { AgentAddress } from "../../agent-address/agent-address.model";
import { AgentBank } from "../agent-bank/agent-bank.model";
import { AgentService } from "../agent.service";
import { Bank } from "../../bank/bank.model";
import { BankService } from "../../bank/bank.service";
import { GananciaCobrador } from "../ganancia-cobrador/ganancia-cobrador.model";
import { FormValidationService } from "../../shared/form-validation/form-validation.service";
import { LoaderService } from "../../loader/loader.service";
import { MessageService } from "../../shared/message/message.service";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { Subscription } from 'rxjs';
import { PercentageDeallocation } from '../../indicators/percentage-deallocation.model';
import { ServicesGestoresService } from '@servicesGestores/services-gestores.service';
import { AgenteProductosFinanciera } from '@agent/agente-productos-financiera.model';
import { ProductosFinanciera } from '@agent/productos-financiera.model';
import { CreditosFinanciera } from '@agent/creditos-financiera.model';
//import { SuiModalService, ModalSize } from 'ng2-semantic-ui';
//import { ConfirmModal } from 'src/app/application/confirm-modal/confirm-modal.component';
import { take } from 'rxjs/operators';
import { CoberturaService } from 'src/app/cobertura/cobertura/cobertura.service';
import { Ranking } from "../modelos/agent-ranking.model";
import { ServiciosKobraService } from "@servicios/servicios-kobra.service";
import { AgentStateComponent } from '../agent-state/agent-state.component';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import {CuentasDesasignadasDialog} from '../modales/cuentas-desasignadas/cuentas-desasignadas-dialog.component';
import { VisitService } from "../../visit/visit.service";
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component';
import moment from 'moment';
import { ModalLimiteCuentasComponent } from '../modales/limite-cuentas/limite-cuentas.component';
import { FotoModalComponent } from 'src/app/componentes/fotos/modal/foto-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTabGroup } from '@angular/material/tabs';
import { WorkReferenceModalFormComponent } from "./work-reference/work-reference-modal-form/work-reference-modal-form.component";
import { PersonalReferenceModalFormComponent } from "./personal-reference/personal-reference-modal-form/personal-reference-modal-form.component";
import { PersonalReferenceEditableListComponent } from "./personal-reference/personal-reference-editable-list/personal-reference-editable-list.component";
import { WorkReferenceEditableListComponent } from "./work-reference/work-reference-editable-list/work-reference-editable-list.component";
import { WorkExperienceModalFormComponent } from './work-experience/work-experience-modal-form/work-experience-modal-form.component';
import { WorkExperience } from './work-experience/work-experience.model';
import { HistorialAltaBajaComponent } from './historial-alta-baja/historial-alta-baja.component';
import { ImageService } from 'src/app/shared/services/image.service';
import { lastValueFrom } from 'rxjs';import { IAgenteCamposExtras, ICampoExtraAgente } from '@agent/interfaces/campo-extra-agentes.interface';
import { IServicesTypeKobraResponse } from 'src/app/core/http/servicio-general/servicio-general-response';
import { CamposExtrasAgentesFormComponent } from '@agent/forms/campos-extras-agentes/campos-extras-agentes-form.component';
import { TipoDato } from 'src/app/shared/components/enums/enumeradores.enum';
import { Utils } from 'src/app/shared/components/utils';
import { HttpHeaders } from '@angular/common/http';


declare let $: any;

@Component({
    selector: 'agent-detail',
    templateUrl: './agent-detail.component.html',
    styleUrls: ['./agent-detail.component.css'],
    providers: [
        AgentService,
        BankService,
        FormValidationService,
        ServicesGestoresService,
        ServiciosKobraService,
        VisitService,
        LoaderService,
        ImageService
    ]
})
export class AgentDetailComponent implements OnInit {

    @ViewChild('tabGroup') tabGroup: MatTabGroup;
    @ViewChild('tabGroupReferencia') tabGroupReferencia: MatTabGroup;
    @ViewChild(PersonalReferenceEditableListComponent) personalReferenceEditableListComponent!: PersonalReferenceEditableListComponent;
    @ViewChild(WorkReferenceEditableListComponent) workReferenceEditableListComponent!: WorkReferenceEditableListComponent;
    @ViewChild('tabGroupReferencias') tabGroupReferencias: MatTabGroup;
    @ViewChild(HistorialAltaBajaComponent) historialComponent: HistorialAltaBajaComponent;



    private environment = environmentSelector();
    private $modalConfirmarBaja: any;
    private $modalConfirmarValidacion: any;
    filterPeriodStartDate: Date;
    filterPeriodEndDate: Date;
    public today: string = new Date().toISOString();
    public filtrosRecorrido: FiltrosAgentesRecorridos;
    public agent: Agent;
    public agentAddress: AgentAddress;
    public agentBank: AgentBank;
    public percentageDeallocation: PercentageDeallocation;
    public agentID: number;
    public banks: Bank[];
    public cargandoGanancias: boolean;
    public certificateImg: string = '/img/icons/certificado.png';
    public editingPersonalInfo: boolean = false;
    public editAddress: boolean;
    public editAddressEnable: boolean;
    public editBankInfo: boolean;
    public gananciaCobrador: GananciaCobrador;
    public loadingAgent: boolean;
    public loadingAddress: boolean;
    public loadingBank: boolean;
    public loadingCuentasApartadas: boolean = true;
    public guardandoCuentasApartadas: boolean = false;
    public savingBank: boolean;
    public cargandoBancos: boolean;
    public loadingAverageRate: boolean;
    public loadingPF: boolean;
    public validado: boolean;
    public loadingPercentageDeAllocation: boolean;
    public loadingDesasignacion: boolean;
    public registrarPago: boolean;
    public tab: string;
    public productosPorFinanciera: AgenteProductosFinanciera[] = [];
    public showStatus: boolean;
    public muestraRecorrdido: boolean = false;
    public agentsStatus: AgentStatus[];
    public formStatus: FormGroup;
    public statusLoader: boolean = false;
    public setStatusMsg: string = '';
    public pais: string;
    public labelEstado: string;
    public labelMunicipio: string;
    public labelColonia: string;
    public labelCurp: string;
    public labelRFC: string;
    public selectedRow: ProductosFinanciera;
    public ranking: Ranking;
    public creditos: CreditosFinanciera[];
    public bajaPorDesasignacion: boolean;
    public loadHistoryMovements: boolean = true;
    public historyMovements: any[] = [];
    public redInterna: boolean;
    public sessionData: SessionData;
    public historialNoDisponible = [];
    public cargandoHistorialNoDisponible = false;
    public cuentasDesasignadas: any = [];
    public isActivarBoton:boolean = false;
    public isLoadRanking :boolean = true;
    public cargandoEstatus :boolean = true;
    public loadingCreditos :boolean = true;
    public selectedIndex :number = 0;
    public validarMenu: any;
    public limiteDeCuentasApartadas: number = 1;
    hijosActivos: boolean = false;
    selectedCreditos: CreditosFinanciera;
    acordeonAbierto: boolean[] = [];
    idAgente:number;
    idCobrador:number;
    isCargoFotoPerfil = false;
    public utils = Utils;

    // #region variables campos extras
    camposExtrasAgentes: ICampoExtraAgente[] = [];
    camposExtrasAgentesRespaldo: ICampoExtraAgente[] = [];
    editandoCamposExtras:boolean = false;
    isLoadCamposExtras:boolean = false;
    @ViewChild(CamposExtrasAgentesFormComponent) campoExtraComponent: CamposExtrasAgentesFormComponent;
    // #endregion

    tablaRankings: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: false,
        columnas: [
            {
                name: 'Indicador',
                key: 'nombre',
                hide: false
            }, {
                name: 'Resultado',
                key: 'ponderacion',
                hide: false
            }
        ]
    }
    showNewWorkReference: boolean;
    selectedWorkExperience: WorkExperience;
    reloadExperienceList = true; // Bandera para recargar el componente



    constructor(
        private app: ApplicationService,
        private agentService: AgentService,
        private bankService: BankService,
        private formBuilder: FormBuilder,
        private loaderService: LoaderService,
        private messageService: MessageService,
        private rootElement: ElementRef,
        private route: ActivatedRoute,
        private router: Router,
        private serviciosGestores: ServicesGestoresService,
        //private modalService: SuiModalService,
        public coberturaServ: CoberturaService,
        private serviciosKobra: ServiciosKobraService,
        private dialog: MatDialog,
        private localStorageService: LocalStorageService,
        private visitService: VisitService,
        private permisosService: PermisosService,
        private sanitizer: DomSanitizer,
        private imageService: ImageService
    ) {
        this.validarMenu = this.permisosService.getPermiso;
        this.createForms();
        this.sessionData = this.localStorageService.getSessionData();
    }

    public readParams(): void {
        this.route.params.subscribe(
            (params: any) => {
                let bandera = true;
                this.redInterna = params.redInterna == '1' ? true : false;
                if (params.id) {
                    this.idCobrador = +params.id;
                    this.loadAgent(this.idCobrador);  
                }
            }
        );

        /*this.route.queryParams.subscribe((params: any) => {
            this.redInterna = params.redInterna == '1' ? true : false;
        });*/
    }

    ngOnInit() {
        this.$modalConfirmarBaja = $(this.rootElement.nativeElement).find('.modal.confirmar.baja');
        this.$modalConfirmarValidacion = $(this.rootElement.nativeElement).find('.modal.confirmar.validacion');
        this.agent = new Agent();
        this.agentAddress = new AgentAddress();
        this.agentBank = new AgentBank();
        this.gananciaCobrador = new GananciaCobrador();
        this.percentageDeallocation = new PercentageDeallocation();
        this.ranking = new Ranking;
        this.tab = 'general';
        this.labelMunicipio = this.environment.labelMunicipio;
        this.labelEstado = this.environment.labelEstado;
        this.labelColonia = this.environment.labelColonia;
        this.labelCurp = this.environment.labelCURP;
        this.labelRFC = this.environment.labelRFC;
        this.pais = this.environment.pais;
        this.readParams();
    }

    toggleModoAgregar() {
        if (this.historialComponent) {
          this.historialComponent.modoAgregar = !this.historialComponent.modoAgregar;
          this.historialComponent.modoAgregarChange.emit(this.historialComponent.modoAgregar); // Emitir el cambio
        }
      }

      onModoAgregarChange(modoAgregar: boolean) {
        // Manejar el cambio de modoAgregar si es necesario
        //console.log('Modo Agregar cambiado:', modoAgregar);
      }

    public onErrorImageLoad(){
        this.agent.rutaFotoPerfil='assets/img/foto-no-disponible.png';
    }

    toggleAcordeon(index: number): void {
        this.acordeonAbierto[index] = !this.acordeonAbierto[index];
    }

    desvinculacionMasiva(data: any, index: number) {
        const msg = !data ? 'Debes seleccionar un registro para desvincular.' : undefined;
      
        if (!msg) {
          const dialogRef = this.dialog.open(ConfirmacionDialog, {
            data: {
              titulo: "Confirmar",
              mensaje: `¿Deseas desvincular el cliente "${data.nombreFinanciera}"?`,
              icono: "delete_forever",
              boton1: "No",
              boton2: "Sí",
              claseAccion: "boton-accion-eliminar"
            }
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.loadingPF = true;
              const loading = this.app.showLoading('Eliminando vinculación...');
      
              this.agentService.desvincularcionMasivaAgentes(data.idCliente, false, this.idAgente)
                .pipe(take(1))
                .subscribe({
                  next: (res: any) => {
                    this.loadingPF = false;
                    this.app.hideLoading(loading);
      
                    if (res.success) {
                        this.app.showSnackbar('¡Aviso!', 'Se ha borrado correctamente los productos', 3000, 'success');
                        this.productosPorFinanciera.splice(index,1);
                    } else {
                      this.app.showError(res.error.message);
                    }
                  },
                  error: (err: any) => {
                    this.loadingPF = false;
                    this.app.hideLoading(loading);
                    this.app.showSnackbar('¡Aviso!', 'Ha ocurrido un error al desvincular los productos', 3000, 'error');
                  }
                });
            }
          });
        } else {
          this.app.showSnackbar('¡Aviso!', msg, 3000, 'warning');
        }
    }

    public agentAddressSave(agentAddress: AgentAddress): void {
        this.editAddress = false;
        this.agentAddress = agentAddress;
        this.loadAddressInformation(this.idAgente);
    }

    cambiaIndex(index: number){
        this.selectedIndex = index;
        if(this.selectedIndex == 2 && !this.muestraRecorrdido){
            this.muestraRecorrdido= true;
        }
    }

    private createForms(): void {
        this.formStatus = this.formBuilder.group({
            idCobradorEstatus: [null, []]
        });
        this.formStatus.get('idCobradorEstatus').valueChanges.subscribe((value) => {
            this.update(value);
        });
    }

    public agentBankSave(agentBank: AgentBank): void {
        this.agentBank = agentBank;
        this.editBankInfo = !this.editBankInfo;
    }

    public changeSaving(guardando: boolean): void {
        this.savingBank = guardando;
    }

    public changeLoadingBancos(cargandoBancos: boolean){
        this.cargandoBancos = cargandoBancos;
    }

    public loadEditAddress(agentAddress: AgentAddress): void {
        this.agentAddress = agentAddress;
        this.editAddress = true;
        this.tabGroup.selectedIndex = 0; // Cambia a la pestaña de Domicilio

    }

    public loadEditExpLaboral(workExperience?: WorkExperience): void {
        this.selectedWorkExperience = workExperience || new WorkExperience();
        this.showNewWorkReference = workExperience ? false : true;
        const dialogRef = this.dialog.open(WorkExperienceModalFormComponent, {
            disableClose: false,
            width: '50%',
            data: {
                workExperience: this.selectedWorkExperience,
                agentID: this.idAgente,
                afterSave: this.reloadEditExpLaboral.bind(this), // Aquí pasas la referencia correcta a la función

            }

          });
          dialogRef.afterClosed().subscribe(data => {});
    }



    reloadEditExpLaboral() {
        // Aquí podrías abrir el formulario para editar la experiencia laboral
        // y luego recargar la lista de experiencias laborales
        this.reloadExperienceList = false;
        setTimeout(() => {
          this.reloadExperienceList = true;
        }, 10); // El tiempo puede ser ajustado según sea necesario
      }



    changeState(type) {
        let lastMovement;

        if (this.historyMovements.length > 0) {
            lastMovement = this.historyMovements[0];
        }

        const dialogRef = this.dialog.open(AgentStateComponent, {
            width: '400px',
            //height: '70%',
            data: { agent: this.agent, type, lastMovement }
        });

        dialogRef.afterClosed().subscribe(data => {
            if (!Boolean(data)) {
                return;
            }

            this.agent.activo = data.activo;
            this.bajaPorDesasignacion = data.castigo;
            this.agent.diasBaja = data.diasBaja;
            this.agent.fecBaja = data.fecBaja;
            this.agent.fecVencimientoBaja = data.fecVencimientoBaja;
            this.loadAgent(this.idCobrador);
        });
    }

    public validar(): void {
        this.$modalConfirmarValidacion.modal('hide');
        this.loaderService.showHttpLoader('Validando al gestor...');
        this.agentService.validar(this.agent.id).subscribe(
            res => {
                this.loaderService.hideHttpLoader();
                this.validado = true;
            },
            error => {
                this.loaderService.hideHttpLoader();
            },
            () => {
                this.loaderService.hideHttpLoader();
            }
        );
    }

    public cerrarConfirmarValidacion(): void {
        this.$modalConfirmarValidacion.modal('hide');
    }

    public editPersonalInfo(): void {
        this.editingPersonalInfo = true;
    }

    private loadAgent(idCobrador: number): void {
        //let loading: number = this.app.showLoading('Cargando información del cobrador...');
        this.loadingAgent = true;
        let subscription: Subscription = this.agentService.getOne(idCobrador).subscribe(
            (agent) => {
                if (agent) {
                     if (!this.sessionData.isSuperAdmin) {
                        if(!agent.interno){
                            this.router.navigate(['']);
                            return;
                        }
                     }
                    this.agent = agent;
                    this.idAgente = agent.idAgente;
                    this.redInterna = this.agent.interno;
                    localStorage.removeItem('filtrosRecorrido');
                    this.filtrosRecorrido = new FiltrosAgentesRecorridos();
                    this.filtrosRecorrido.idCobrador = agent.idCobrador;
                    this.filtrosRecorrido.fecRecorrido = new Date(Date.now());
                    this.savePersonalInformation(agent);
                    this.loadPercentageDeAllocation(agent.idAgente);
                    this.loadAddressInformation(agent.idAgente);
                    this.loadBankInformation(agent.idAgente);
                    this.loadAverageRate(agent.idAgente);
                    this.loadRanking(agent.idAgente);
                    this.loadAgentStatus();
                    this.loadFinancialLinked(agent.idAgente);
                    this.loadCredits(agent.idAgente);
                    this.loadLimiteCuentasApartadas(agent.idAgente);
                    this.loadHistory();
                    if(agent.interno && agent.idCliente){
                      this.loadInformacionAdicional();
                    }
                    this.validado = agent.validado;
                    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
                    let country = "&Country=";
                    country+= paisSeleccionado ? JSON.parse(paisSeleccionado).abreviacion : 'mx';
                    // this.agent.profilePictureFullPath = `${this.environment.kobraStorage}/cobradores/${agent.id}/foto-perfil?token=${localStorage.getItem('access-token')}${country}`;
                    // this.agent.ineFrontURL = `${this.environment.kobraStorage}/cobradores/${agent.id}/identificacion-frontal?token=${localStorage.getItem('access-token')}${country}`;
                    // this.agent.ineBackURL = `${this.environment.kobraStorage}/cobradores/${agent.id}/identificacion-trasera?token=${localStorage.getItem('access-token')}${country}`;
                    // this.agent.cedulaFiscalURL = `${this.environment.kobraStorage}/cobradores/${agent.id}/cedula-fiscal?token=${localStorage.getItem('access-token')}${country}`;
                    // this.bajaPorDesasignacion = this.agent.deleted && this.agent.fecBaja && this.agent.fecVencimientoBaja ? true : false;
                    this.downloadImage(agent.rutaFotoPerfil, agent.nombreArchivoPerfil).then(url => {
                        this.isCargoFotoPerfil = true;
                        this.agent.rutaFotoPerfil = url;
                    });
                     
                    this.bajaPorDesasignacion = !this.agent.activo && this.agent.fecBaja && this.agent.fecVencimientoBaja ? true : false;
                }else{
                    this.router.navigate(['']);
                }
                this.loadingAgent = false;
            },
            (error) => {
                this.app.showError(error);
                this.loadingAgent = false;
                subscription.unsubscribe();
            }, () => {
                this.loadingAgent = false;
                subscription.unsubscribe();
            }
        )
    }

    async downloadImage(ruta: string, nombreArchivo: string): Promise<string> {
        const url = this.crearUrlFotosAgente(ruta, nombreArchivo);
    
        try {
          const blob = await lastValueFrom(this.imageService.downloadImage(url));
          return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob)) as string;
        } catch (error) {
            this.onErrorImageLoad();
            this.isCargoFotoPerfil = true;
            
            //this.app.showSnackbar('¡Aviso!', 'El agente no cuenta con foto de perfil.', 3000, 'warning');
          throw error;
        }
    }

    private loadHistory() {
        this.agentService.getHistory(this.agent.idAgente).subscribe(items => {
            this.historyMovements = items;
            this.loadHistoryMovements = false;
        }, () => {

        });
    }

    private loadRanking(id: number): void {
        this.isLoadRanking = true;
        this.coberturaServ.obtenerRanking(id)
            .pipe(
                take(1)
            ).subscribe((data: any) => {
                if (data.data) {
                    this.ranking.puntos = data.data.ranking;
                    this.ranking.globalRank = data.data.globalRank;
                    this.ranking.localRank = data.data.localRank;
                    this.ranking.indicadores = data.data.indicadores;
                }
                this.isLoadRanking = false;
            },
            error => {
                this.app.showError(error);
                this.isLoadRanking = false;
            });
    }

    private loadAddressInformation(idAgente: number): void {
        this.loadingAddress = true;
        this.editAddressEnable = true;
        this.agentService.getDomicilio(idAgente).subscribe(
            direccion => {
                if (direccion) {
                    this.agentAddress = direccion;
                }
                if(this.agentAddress.idCobrador == null || this.agentAddress.idAgente == null ){
                  this.agentAddress.idCobrador = this.agent.idCobrador;
                  this.agentAddress.idAgente = this.agent.idAgente;
                  this.editAddressEnable = false
                }
                this.loadingAddress = false;
            },
            error => {
                this.app.showError(error);
                this.loadingAddress = false;
            }
        );
    }

    private loadBankInformation(idAgente: number): void {
        this.loadingBank = true;
        this.agentService.curretnBank(idAgente).subscribe(
            (bank) => {
                this.agentBank = bank;
                this.loadingBank = false;
            },
            (error) => {
                this.loadingBank = false;
                this.messageService.httpError(error);
            }
        );
    }

    private loadAverageRate(id: number): void {
        this.loadingAverageRate = true;
        this.agentService.getAverageRate(id).subscribe(
            (rate) => {
                this.agent.avgRate = rate;
                this.loadingAverageRate = false;
            },
            (error) => {
                this.loadingAverageRate = false;
                this.messageService.httpError(error);
            }
        );
    }

    private loadFinancialLinked(idAgente: number): void {
        this.loadingPF = true;
        this.agentService.getFinancialsLinked(idAgente).subscribe(
            productosPorFinanciera => {
                this.productosPorFinanciera = productosPorFinanciera ?? [];
                this.loadingPF = false;
            }
            
        );
    }

    public loadPercentageDeAllocation(id: number): void {
        this.isActivarBoton = false;
        this.loadingPercentageDeAllocation = true;
        let queryParams: string = '?';
        // this.filterPeriodStartDate
        if (this.filterPeriodStartDate) {
            let date = new Date(this.filterPeriodStartDate);
            date.setDate(date.getDate());
            let strDate: string = this.filterPeriodStartDate.toISOString();
            queryParams += 'fecInicio=' + strDate.split('T')[0];
        }

        if (this.filterPeriodEndDate) {
            let date = new Date(this.filterPeriodEndDate);
            date.setDate(date.getDate());
            let strDate: string = date.toISOString();
            if (this.filterPeriodEndDate) {
                queryParams += '&';
            }
            queryParams += 'fecFin=' + strDate.split('T')[0];
        }

        if (queryParams == '?') {
            this.filterPeriodStartDate = new Date();
            this.filterPeriodEndDate = new Date();
            let startDate = new Date();
            startDate.setDate(startDate.getDate() - 15);
            this.filterPeriodStartDate = startDate;
            let maxDate = new Date();
            maxDate.setDate(maxDate.getDate());
            let strDate: string = startDate.toISOString();
            let endDate: string = maxDate.toISOString();
            queryParams += 'fecInicio=' + strDate.split('T')[0] + '&fecFin=' + endDate.split('T')[0];
        }
        let subscription: Subscription = this.agentService.getPercentageDeAllocation(id, queryParams).subscribe(
            (percentageDeAllocation) => {
                this.loadingPercentageDeAllocation = false;
                this.percentageDeallocation.percentageDeallocation = percentageDeAllocation.percentageDeallocation;
                this.percentageDeallocation.totalAssignedAccounts = percentageDeAllocation.totalAssignedAccounts;
                this.percentageDeallocation.totalExpiredAccounts = percentageDeAllocation.totalExpiredAccounts;

                if(percentageDeAllocation.totalAssignedAccounts > 0){
                    this.obtenerCuentasDesasignadas(id, queryParams)
                }else{
                    this.isActivarBoton = false;
                }
            },
            (error) => {
                this.app.showError(error);
                this.loadingPercentageDeAllocation = false;
            }, () => {
                this.loadingPercentageDeAllocation = false;
                subscription.unsubscribe();
            }
        )
    }

    // #region Metodos Campos Extras

    loadInformacionAdicional(): void {
      this.isLoadCamposExtras = true;
      this.camposExtrasAgentes = [];
      this.camposExtrasAgentesRespaldo = [];
      this.agentService.obtenerInformacionAdicional(this.agent.idAgente, this.agent.idCliente)
          .pipe(
              take(1)
          ).subscribe((res: IServicesTypeKobraResponse<ICampoExtraAgente[]>) => {
              if (res.data) {
                for (let index = 0; index < res.data.length; index++) {
                  const campoExtra = res.data[index];
                  if(campoExtra.idTipoDato == TipoDato.LOGICO && campoExtra.valor != null){
                    campoExtra.valor = campoExtra.valor == '1' ? true : false;
                  }
                }
                this.camposExtrasAgentes = res.data;
                this.camposExtrasAgentesRespaldo = JSON.parse(JSON.stringify(this.camposExtrasAgentes));
              }
              this.isLoadCamposExtras = false;
          },
          error => {
              this.app.showError(error);
              this.isLoadCamposExtras = false;
          });
    }

    cancelCamposExtras(){
      this.camposExtrasAgentes = JSON.parse(JSON.stringify(this.camposExtrasAgentesRespaldo));
      this.editandoCamposExtras = false;
    }

    guardarCamposextras(){
      if(!this.campoExtraComponent.validaElementos()){
        return;
      }
      let camposExtras: IAgenteCamposExtras = {
        idAgente: this.agent.idAgente,
        idCliente: this.agent.idCliente,
        camposExtras: this.camposExtrasAgentes
      };
      console.log(camposExtras);
      this.agentService.camposExtras(camposExtras).subscribe(
        res => {
            this.camposExtrasAgentesRespaldo = JSON.parse(JSON.stringify(this.camposExtrasAgentes));
            console.log(camposExtras);
            this.editandoCamposExtras = false;
          },
          error => {
            this.editandoCamposExtras= false;
            this.app.showError(error);
          }
        );
    }

    // #endregion

    public onSavePago() {
        //this.cargarGanancias(this.idAgente);
        this.registrarPago = false;
    }

    public mostrarRegistroPago(): void {
        this.registrarPago = true;
    }

    public ocultarRegistroPago(): void {
        this.registrarPago = false;
    }
    public savePersonalInformation(agent: Agent): void {
        this.agent.firstName = agent.firstName ;
        this.agent.firstSurname = agent.firstSurname ;
        this.agent.secondSurname = agent.secondSurname ;
        this.agent.fullName = `${agent.firstName} ${agent.firstSurname} ${agent.secondSurname ? agent.secondSurname : ''}`;
        this.agent.curp = agent.curp ;
        this.agent.rfc = agent.rfc ;
        this.agent.birthdate = agent.birthdate ;
        this.agent.gender = agent.gender ;
        this.agent.phoneNumber = agent.phoneNumber ;
        this.agent.cellphoneNumber = agent.cellphoneNumber ;
        this.agent.email = agent.email ;
        this.agent.buroLaboral = agent.buroLaboral ;
        this.agent.disponible = agent.disponible ;
        this.agent.primerContacto = agent.primerContacto ;
        this.agent.nss = agent.nss ;
        this.agent.contrasena = agent.contrasena ;
        this.editingPersonalInfo = false;
    }

    // public showAddressForm(): void {
    //     this.editAddress = true;
    //     this.tabGroup.selectedIndex = 0; // Cambia a la pestaña de Domicilio

    // }

    public showBankForm(): void {
        this.editBankInfo = true;
        this.tabGroup.selectedIndex = 1; // Cambia a la pestaña de Datos Bancarios

    }

    public loadAgentStatus(): void {
        this.showStatus = true;
        this.cargandoEstatus = true;
        this.agentService.getAgentStatus().subscribe(
            res => {
                this.agentsStatus = res;
                this.formStatus.controls['idCobradorEstatus'].setValue(this.agent.idCobradorEstatus);
                this.showStatus = true;
                this.cargandoEstatus = false;
            },
            err => {
                this.app.showError(err);
                this.cargandoEstatus = false;
            },
            () => this.cargandoEstatus = false
        );
    }

    public loadLimiteCuentasApartadas(id: number): void{
        this.loadingCuentasApartadas = true;
        this.serviciosGestores.getLimiteCuentasApartadas(id).subscribe(
            res => {
                if (res) {
                    this.limiteDeCuentasApartadas = res.valor;
                }
                this.loadingCuentasApartadas = false;
            },
            error => {
                this.app.showError(error);
                this.loadingCuentasApartadas = false;
            }
        );
    }


    public guardarLimiteCuentas(){
        this.guardandoCuentasApartadas = true;
        this.serviciosGestores.saveLimiteCuentasApartadas({
            idAgente: this.agent.idAgente,
            valor: this.limiteDeCuentasApartadas
        }).subscribe(
            res => {
                this.app.showSnackbar('¡Aviso!', `Se ha actualizado el limite de cuentas a ${this.limiteDeCuentasApartadas}.`, 3000, 'success');
                this.guardandoCuentasApartadas = false;
            },
            error => {
                this.app.showError(error);
                this.guardandoCuentasApartadas = false;
            }
        );
    }

    public update(idCobradorEstatus: number) {
        if(idCobradorEstatus && !this.cargandoEstatus){
            this.statusLoader = true;
            let agent: any = {
                id: this.idAgente,
                idCobradorEstatus: idCobradorEstatus
            };
            this.agentService.update(agent)
                .subscribe(
                    () => {
                        this.setStatusMsg = 'Actualizando estatus...';
                        this.app.showSnackbar('¡Aviso!', 'Estatus actualizado correctamente.', 3000, 'success');
                        this.statusLoader = false;
                    },
                    (error) => {
                        this.statusLoader = false;
                        this.app.showError(error);
                    }
                );
        }
    }

    onDelete(indexFinanciera: number, indexProducto: number) {
        const msg = !this.selectedRow ? 'Debes seleccionar un registro para borrarlo.' : undefined;

        if (!msg) {
            const dialogRef = this.dialog.open(ConfirmacionDialog, {
                //height: '400px',
                //width: '300px',
                //disableClose: true,
                data:{
                    titulo: "Confirmar",
                    mensaje: `¿Deseas borrar la vinculación del gestor al producto "${this.selectedRow.nombreProducto}"?`,
                    icono:"delete_forever",
                    boton1: "No",
                    boton2: "Sí",
                    claseAccion: "boton-accion-eliminar"
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if(result){
                    const loading = this.app.showLoading('Eliminando vinculación...');
                    this.agentService.eliminarProducto(this.selectedRow).pipe(
                        take(1)
                    ).subscribe(
                        res => {
                            if (res.success) {
                                this.app.showSnackbar('¡Aviso!', 'Se ha borrado correctamente el producto', 3000, 'success');
                                if(this.productosPorFinanciera[indexFinanciera].productos.length > 1){
                                    this.productosPorFinanciera[indexFinanciera].productos.splice(indexProducto, 1);
                                }
                                else{
                                    this.productosPorFinanciera.splice(indexFinanciera,1);
                                }

                                this.app.hideLoading(loading);
                            }
                            else {
                                this.app.hideLoading(loading);
                                this.app.showError(res.error.message);
                            }
                        },
                        error => {
                            this.app.hideLoading(loading);
                            this.app.showSnackbar('¡Aviso!', 'Ha ocurrido un error al desvincular el producto', 3000, 'error');
                        },
                        () => {
                            this.app.hideLoading(loading);
                        }
                    );
                }
            });
        } else {
            this.app.showSnackbar('¡Aviso!', msg, 3000, 'warning');
        }
    }

    onDeleteCredito(idAgente, idAgenteTipoCredito, nombreTipoCredito) {
        const msg = !this.selectedCreditos ? 'Debes seleccionar un registro para borrarlo.' : undefined;
        if (!msg) {
            const dialogRef = this.dialog.open(ConfirmacionDialog, {
                //height: '400px',
                //width: '300px',
                //disableClose: true,
                data:{
                    titulo: "Confirmar",
                    mensaje: `¿Deseas borrar la vinculación del gestor al tipo de crédito "${nombreTipoCredito}"?`,
                    icono:"delete_forever",
                    boton1: "No",
                    boton2: "Sí",
                    claseAccion: "boton-accion-eliminar"
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if(result){
                    this.loadingPF = true;
                    this.serviciosGestores.eliminarCredito(idAgente, idAgenteTipoCredito).pipe(
                        take(1)
                    ).subscribe((res: any) => {

                        if (res.success) {
                            this.app.showSnackbar('¡Aviso!', 'Se ha borrado correctamente el tipo de crédito', 3000, 'success');
                            this.loadCredits(idAgente);
                            // this.productosPorFinanciera[indexFinanciera].productos.splice(indexProducto, 1);
                            this.loadingPF = false;
                        }
                        else {
                            this.app.showError(res.error);
                        }
                    });
                }
            });
        } else {
            this.app.showSnackbar('¡Aviso!', msg, 3000, 'warning');
        }

    }


    loadCredits(idAgente:number) {
        this.loadingCreditos = true;
        this.serviciosGestores.loadCredits(idAgente).subscribe(
            res => {
                this.creditos = res.data;
            },
            err => {
                this.app.showError(err);
                this.loadingCreditos = false;
            },
            () => this.loadingCreditos = false
        );

    }

    
    openDialogCuentas(idAgente:any): void {
        let queryParams: string = '?';
        // this.filterPeriodStartDate
        if (this.filterPeriodStartDate) {
            let date = new Date(this.filterPeriodStartDate);
            date.setDate(date.getDate());
            let strDate: string = this.filterPeriodStartDate.toISOString();
            queryParams += 'fecInicio=' + strDate.split('T')[0];
        }

        if (this.filterPeriodEndDate) {
            let date = new Date(this.filterPeriodEndDate);
            date.setDate(date.getDate());
            let strDate: string = date.toISOString();
            if (this.filterPeriodEndDate) {
                queryParams += '&';
            }
            queryParams += 'fecFin=' + strDate.split('T')[0];
        }

        if (queryParams == '?') {
            this.filterPeriodStartDate = new Date();
            this.filterPeriodEndDate = new Date();
            let startDate = new Date();
            startDate.setDate(startDate.getDate() - 15);
            this.filterPeriodStartDate = startDate;
            let maxDate = new Date();
            maxDate.setDate(maxDate.getDate());
            let strDate: string = startDate.toISOString();
            let endDate: string = maxDate.toISOString();
            queryParams += 'fecInicio=' + strDate.split('T')[0] + '&fecFin=' + endDate.split('T')[0];
        }

        const dialogRef = this.dialog.open(CuentasDesasignadasDialog, {
            data:{  
                    idAgente: idAgente,
                    queryParams:queryParams,
                    acreditados: this.cuentasDesasignadas
                }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

    obtenerCuentasDesasignadas(idAgente: any, queryParams: any) {
        this.loadingDesasignacion = true;

        this.visitService.obtenerCuentasDesasignadas(idAgente, queryParams).pipe(
            take(1)
        ).subscribe((respuesta: any) => {

            if (respuesta.data.length > 0) {
                for (let i = 0; i < respuesta.data.length; i++) {
                    let direccion: string;
                    /*direccion = (respuesta.data[i].calle ? respuesta.data[i].calle +', ' : '') +
                                                (respuesta.data[i].numeroExterior ? respuesta.data[i].numeroExterior +', ' : '') +
                                                (respuesta.data[i].colonia ? respuesta.data[i].colonia +', ' : '') +
                                                (respuesta.data[i].municipio ? respuesta.data[i].municipio+', ' : '') +
                                                (respuesta.data[i].estado ? respuesta.data[i].estado +'.' : '') ;*/
                    //respuesta.data[i].selectDomicilio = respuesta.data[i].folioDomicilio + ' - ' + direccion;

                    respuesta.data[i].nombreCompleto = (respuesta.data[i].nombre ? respuesta.data[i].nombre + ' ' : '') +
                        (respuesta.data[i].apellidoPaterno ? respuesta.data[i].apellidoPaterno + ' ' : '') +
                        (respuesta.data[i].apellidoMaterno ? respuesta.data[i].apellidoMaterno : '');
                }

                this.isActivarBoton = true;
            }
            //  else {
            //     this.app.showSnackbar(
            //         "Aviso",
            //         "No se encontro información.",
            //         3000,
            //         "warning"
            //     );
            // }

            this.cuentasDesasignadas = respuesta.data;
            this.loadingDesasignacion = false;

        }, (error: any) => {
            this.loadingDesasignacion = false;
            this.app.showError(error);

        });



    }

    abrirRecorridoDeHoy(){
        localStorage.setItem('filtrosRecorrido', JSON.stringify(this.filtrosRecorrido));
        this.router.navigate(['recorrido-hoy', {}],
        {
            relativeTo: this.route,
            queryParamsHandling: 'preserve'
        }).then(() => {
        });
    }

    openDialogLimiteCuentas(): void {

        const dialogRef = this.dialog.open(ModalLimiteCuentasComponent, {
            //height: '400px',
            //width: '500px',
            //disableClose: true,
            data:{
                idAgente: this.agent.idAgente,
                limiteDeCuentasApartadas: this.limiteDeCuentasApartadas,
                isSuperAdmin : this.sessionData.isSuperAdmin
            }
        });

        dialogRef.afterClosed().subscribe(result => {
    
            if(result.success) {
                this.limiteDeCuentasApartadas = result.limite;
            }
        });
      }

    public cargarFotoCompleta(urlFoto: string, nombreArchivo: string){
        let url = this.crearUrlFotosAgente(urlFoto, nombreArchivo)
        const dialogRef = this.dialog.open(FotoModalComponent, {
            data: {
                urlFoto: url
            }
        });

        dialogRef.afterClosed().subscribe(data => {});
    }

    getSafeUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    referenciaLaboral(){
        this.tabGroupReferencia.selectedIndex = 0;
        this.showFormReferencia();
    }

    referenciaPersonal(){
        this.tabGroupReferencia.selectedIndex = 1;
        this.showFormPersonales();
    }

    public showFormReferencia(): void {
        const dialogRef = this.dialog.open(WorkReferenceModalFormComponent, {
          disableClose: false,
          width: '50%',
          data: {
            nuevo: true,
            agentID: this.idAgente,
          }

        });

        dialogRef.afterClosed().subscribe(data => {
            if(!data.cancelar && data.nuevo){
                this.workReferenceEditableListComponent.loadReferences(this.idAgente);
            }   
        });

        //this.workReferenceModalFormComponent.show();
    }

    public showFormPersonales(): void {
        const dialogRef = this.dialog.open(PersonalReferenceModalFormComponent, {
          disableClose: false,
          width: '50%',
          data: {
            nuevo: true,
            agentID: this.idAgente
          }

        });

        dialogRef.afterClosed().subscribe(data => {
            //console.log(data);
            if(!data.cancelar && data.nuevo){
                this.personalReferenceEditableListComponent.loadReferences(this.idAgente);
            }   
            
        });
      }

      crearUrlFotosAgente(ruta: string, nombreArchivo: string): string{
        const paisSeleccionado = localStorage.getItem('paisSeleccionado');
        return `${this.environment.kobraStorage}/cobradores/${this.agent.idCobrador}/obtener-archivo-gestores?token=${localStorage.getItem('access-token')}&nombre=${ruta}/${nombreArchivo}&Country=${paisSeleccionado ? JSON.parse(paisSeleccionado).abreviacion : 'mx'}`;
      }
}
