import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Component } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';
import { VisitasCriteriosService } from '../visitas-criterios.service';
import { LenderService } from '@lender/lender.service';
import {  IFiltersCriterio, ICriteriosCalidad, IPaginatorCriteriosCalidad, IQueryParamsCriterio } from '../criterios.interface';
import { Lender } from '@lender/lender.model';

@Component({
  selector: 'app-criterios-calidad',
  templateUrl: './criterios-calidad.component.html',
  styleUrls: ['./criterios-calidad.component.css'],
  providers: [LenderService]

})
export class CriteriosCalidadComponent {


  filtrosAbiertos: boolean = false;
  columnasAbiertas: boolean = false;
  hijosActivos: boolean = false;
  ayuda: boolean = false;
  sessionData: SessionData;
  public criterioList: ICriteriosCalidad[] = [];
  public itemsSelected: ICriteriosCalidad[] = [];
  public pagination: IPaginatorCriteriosCalidad;

  public queryParams: URLSearchParams;
  public listFields: any = {};
  public lenders: Lender[] = [];
  public idLender: number = null;
  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;
  loadings: { criterios: boolean; financieras: boolean } = {
    criterios: true,
    financieras: true,
  };

  tabla: any = {
    headers: false,
    multiSelect: false,
    columns: [{
      name: 'Cliente',
      key: 'nombreCliente',
      hide: false
    }, {
      name: 'ID Producto',
      key: 'idProducto',
      hide: true
    }, {
      name: 'Producto',
      key: 'nombreProducto',
      hide: false
    }, {
      name: 'Estatus',
      key: 'estatus',
      type: 'estatus',
      hide: false
    }, {
      name: 'Fecha Registro',
      key: 'fecRegistro',
      type: 'dateTime',
      hide: false
    }, {
      name: 'Fecha Actualizacion',
      key: 'fecActualizado',
      type: 'dateTime',
      hide: false
    }
    ]
  }

  filters: IFiltersCriterio = {
    cliente: null,
    activa: true,
    filtered: false,
    page: 1,
  };


  datosConsulta: IQueryParamsCriterio = {
    slt: 'idCliente, nombreCliente, idProducto, nombreProducto, estatus,fecRegistro, fecActualizado',
    pageSize: 50,
    rows: 50,
    sortField: 'idProducto',
    sortAsc: true,
    query: '',
    fromRowPage: 0,
    toRowPage: 10,
  };

  constructor(
    private lenderService: LenderService,
    private app: ApplicationService,
    private filtrosService: FiltrosService,
    private visitasCriteriosService: VisitasCriteriosService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {
    this.sessionData = this.localStorageService.getSessionData();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
   
  }

  ngOnInit() {

    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
      const currentRoute = this.route.snapshot.firstChild?.routeConfig?.path;
      if (currentRoute === 'criterios-calidad') {
        this.criteriosGrid(1);
        this.itemsSelected = [];
      }
    });
  
    
    this.loadLenders();
    this.criteriosGrid(1);

    this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
  }

  public loadLenders(): void {
    this.loadings.financieras = true;
  
    this.lenderService.getAll('all').subscribe(
      (res: Lender[]) => {
        this.lenders = res;
  
        // Si no eres super admin, selecciona automáticamente el lender asociado a la sesión
        if (!this.sessionData.isSuperAdmin) {
          this.idLender = this.sessionData.idFinanciera;
          this.onChangeLender(this.idLender);
        }
      },
      err => {
        this.app.showError(err);
        this.loadings.financieras = false;
      },
      () => (this.loadings.financieras = false)
    );
  }
  

  private agregarContabilizadoresDeFiltros() {

    this.contabilizadorFiltros.add('Generales', 'generales', [
      'cliente'
    ]);

  }

  resultadoFiltros(filtrosNuevos: any) {
    if (filtrosNuevos) {
      this.filters = filtrosNuevos;
      this.filters.filtered = true;
    }
    this.idLender = this.filters.cliente ? this.filters.cliente.idCliente : null;
    this.loadings.criterios = false;
    // this.loadBranches(this.filters.page || 1);
  }

  public contabilizadorDeFiltros(filtroName: string) {
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filters);
  }

  public contabilizadorDeFiltrosTotales(): void {
    let totalFiltrosArray: number[] = [];
    totalFiltrosArray.push(this.contabilizadorDeFiltros('generales'));
    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  public inactivarHijo() {
    this.filtrosService.asignarOpcion();
    if (this.filters && this.filters.filtered) {
      this.filtrosService.guardarFiltros(this.filters, true);
    }
    this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));

  }

  public onChangeLender(event: number | null): void {
    if (event) {
      const selectedLender = this.lenders.find(lender => lender.idCliente === event);
      if (selectedLender) {
        this.filters.cliente = {
          idCliente: selectedLender.idCliente,
          nombreCliente: selectedLender.nombre, // Convertir 'nombre' a 'nombreCliente'
        };
      } else {
        this.filters.cliente = null;
      }
    } else {
      this.filters.cliente = null;
    }
  }
  

  public setFilter(): void {
    this.filters.filtered = true;
    this.filters.page = 1;
    this.filtrosService.guardarFiltros(this.filters);
    this.criteriosGrid(1);
    this.contabilizadorDeFiltrosTotales()
  }

  public clearFilter(): void {
    Object.keys(this.filters).forEach(key => {
      if(this.sessionData.isSuperAdmin || (!this.sessionData.isSuperAdmin && key != 'idCliente' && key != 'financiera')){
        this.filters[key] = null;
      }
    });
    this.filters.cliente = null;
    this.idLender = null;
    this.filters.activa = true;
    this.filters.filtered = false;
    this.filters.page = 1;
    this.filtrosService.removerFiltros();
    this.contabilizadorDeFiltrosTotales()
  }

  private crearURLQuery(page: number): string {
    let queryParams: string = '';

    queryParams += `?paginate=true&page=${page}&rows=${this.datosConsulta.rows}`;
    if (this.datosConsulta.slt) {
      queryParams += '&slt=' + this.datosConsulta.slt;
    }

    if (this.datosConsulta.sortField) {
      queryParams += '&orderBy=' + this.datosConsulta.sortField + (this.datosConsulta.sortAsc ? ' asc' : ' desc');
    }

    if (this.filters.cliente) {
      queryParams += '&idCliente=' + this.filters.cliente.idCliente;
    }

    if (this.filters.activa) {
      queryParams += `&activa=${this.filters.activa}`;
    }
    return queryParams;
  }


  public criteriosGrid(page: number): void {
    this.loadings.criterios = true;

    if (page !== this.filters.page) {
      this.filters.page = page;
      this.filtrosService.guardarFiltros(this.filters);
    }

    const query: string = this.crearURLQuery(page);

    this.visitasCriteriosService.criteriosGrid(query).pipe(take(1)).subscribe({
      next: (res: { data: IPaginatorCriteriosCalidad }) => {
        this.pagination = res.data;
        this.criterioList = res.data.pageData.map(item => ({
          ...item,
          estatus: item.estatus ? 'Activo' : 'Inactivo',
        }));
        this.calcFromToRowPage();
        this.loadings.criterios = false;
      },
      error: err => {
        this.app.showSnackbar('Aviso','Error al cargar criterios', 2000, 'error');
        console.error('Error al cargar criterios:', err);
        this.loadings.criterios = false;
      },
    });
  }
  

  agregarCriterio() {
    this.router.navigate(
      ['nuevo', {}],
      {
        relativeTo: this.route,
        queryParamsHandling: 'preserve'
      }).then(() => {
        this.itemsSelected = [];
      });
  }

  editarCriterio() {
    this.router.navigate(['editar'], {
      relativeTo: this.route,
      queryParams: {
        idCliente: this.itemsSelected[0].idCliente,
        idProducto: this.itemsSelected[0].idProducto,
        nombreProducto: this.itemsSelected[0].nombreProducto,
        nombreCliente: this.itemsSelected[0].nombreCliente
      },
      queryParamsHandling: 'merge'
    });
  }

  onSelected(rows) {
    this.itemsSelected = rows.slice();
  }

  onLinkRow({ row, columnPressed }) {
    if (columnPressed === 'nombreCliente') {
      this.editarCriterio();
    }
  }

  onDoubleClick({ row }) {
    this.editarCriterio();
  }

  sortChange(sort: any) {
    if (sort.active == this.datosConsulta.sortField) {
      this.datosConsulta.sortAsc = !this.datosConsulta.sortAsc;
    } else {
      this.datosConsulta.sortField = sort.active;
      this.datosConsulta.sortAsc = true;
    }
    this.criteriosGrid(1);
  }

  onPage(newPagina: any): void {
    if (newPagina.pageIndex + 1 !== this.pagination.page || newPagina.pageSize !== this.datosConsulta.rows) {
      this.datosConsulta.rows = newPagina.pageSize;
      this.criteriosGrid(newPagina.pageIndex + 1);
    }
  }

  calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
      this.datosConsulta.fromRowPage = 0;
      this.datosConsulta.toRowPage = 0;
    } else {
      this.datosConsulta.fromRowPage = (this.datosConsulta.rows * this.pagination.page) - (this.datosConsulta.rows - 1);

      if (this.pagination.page === this.pagination.pages) {
        this.datosConsulta.toRowPage = this.pagination.totalRows;
      } else {
        this.datosConsulta.toRowPage = this.pagination.page * this.datosConsulta.rows;
      }
    }
  }


}
