
<div class="app content kobra">
    
    <mat-card class="kobra-card">
        <mat-card *ngIf="ayuda " class="cardAyuda">
            <mat-card-content>
              <p><mat-icon color="primary">help</mat-icon>
                  El módulo "Recorrido agente" es una apartado muy importante en el sistema que te ayudará a visualizar y analizar el recorrido que tienen nuestros agentes mientras realizan las gestiones.
                  La aplicación recolecta las coordenadas importantes del agente y te las muestra en un mapa para su correcta visualización.
              </p>
              Acciones:
              <p>
                <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda de los recorridos por agente.
              </p>
              <p>
                <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
              </p>
             
            </mat-card-content>
          </mat-card>
        <mat-card-content>
            <mat-card-title>
                <div class="button-container">
                    <div class="button-toggle-group-container">
                    <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" style="margin-right: 5px;">
                        <mat-radio-group [(ngModel)]="tipoMapa" (change)="inicializarMapa()">
                            <mat-radio-button class="kobra-radio" [value]="1">Normal</mat-radio-button>
                            <mat-radio-button class="kobra-radio" [value]="2">Satélite</mat-radio-button>
                        </mat-radio-group>
                        <mat-button-toggle value="filtros" 
                            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;" matTooltip="Filtrar"
                            mat-tooltip-panel-above>
                            <mat-icon class="kobra-icon">filter_alt</mat-icon>
                            <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>

                        </mat-button-toggle>
                        <mat-button-toggle value="descargas" 
                            (click)="descargarRecorrido();" matTooltip="Descargar Listado"
                            mat-tooltip-panel-above>
                            <mat-icon class="kobra-icon">cloud_download</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="actualizar" (click)="buscarRecorridoAgente();"
                            [disabled]="!filtros.idAgente && !filtros.fecRecorrido" matTooltip="Refrescar" mat-tooltip-panel-above>
                            <mat-icon class="kobra-icon">sync</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="ayuda" 
                        matTooltip="Ayuda"
                        mat-tooltip-panel-above
                        (click)="ayudaComentario(!ayuda)">
                        <mat-icon color="primary">help</mat-icon>
                      </mat-button-toggle>
                      
                    </mat-button-toggle-group>
                </div>
               
                </div>
            </mat-card-title>
            <div style="height: calc(100vh - 235px); width:100%;" id="mapaRecorrido"></div>
        </mat-card-content>
        <mat-card-footer>
            <mat-progress-bar mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
        </mat-card-footer>
    </mat-card>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
    <mat-toolbar>
      <button class="filter-button-side" mat-flat-button
        matTooltip="Ocultar filtros"
        mat-tooltip-panel-above
        (click)="filtrosSideNav.toggle()"
      >
        <mat-icon>cancel</mat-icon>
      </button> 
      Filtros
    </mat-toolbar>
    <ol class="lista-filtros-side">
        <div class="div-filtros-side">
        
            <li *ngIf="!ocultarVistas">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Agente'"
                  [placeHolder]="'Busca por id o nombre'"
                  [value]="'idAgente'"
                  [labelField]="'nombre'"
                  [icon]="'account_circle'"
                  [realizarBusqueda]="agenteSearch"
                  [(ngModel)]="filtros.idAgente"
                  (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                  [minBusqueda]="1"
                >
                </mat-select-search>
                
            </li>
            <li style="margin-bottom: 15px;" *ngIf="!ocultarVistas">
                <mat-date-picker-formater
                    class="grid-tile-form-field"
                    [label]="'Fecha del recorrido'"
                    [placeHolder]="'Fecha'"
                    [(ngModel)]="filtros.fecRecorrido"
                    (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                    [limpiar]="false"
                    [max]="hoy"
                />
               
            </li>
            <li style="margin-bottom: 15px;">
                <mat-slide-toggle [disabled]="!filtrado" class="kobra-slide-toggle" [(ngModel)]="filtros.mostrarNombre" (ngModelChange)="recargarPuntos(false, 'recorrido')">Mostrar núm. ubicación</mat-slide-toggle>
            </li>
            <li>
                <mat-slide-toggle [disabled]="!filtrado" class="kobra-slide-toggle" [(ngModel)]="filtros.mostrarLinea" (ngModelChange)="recargarPuntos(false, 'recorridoLineal')">Mostrar lineas</mat-slide-toggle>
            </li>
        </div>
    </ol>
    
    <ol class="lista-filtros-buttons">
      <button
        class="boton-accion-front"
        style="width: 100%;"
        mat-stroked-button
        
        (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos; buscarRecorridoAgente()">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
      </button>
    </ol>
</mat-sidenav>