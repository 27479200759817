import { City } from "../../city/city.model";
import { Neighborhood } from "../../neighborhood/neighborhood.model";
import { State } from "../../state/state.model";

export class AgentAddress {
  id: number;
  idAgente: number;
  street: string;
  outdoorNumber: string;
  indoorNumber: string;
  neighborhoodID: number;
  neighborhood: Neighborhood;
  postalCode: number;
  cityID: number;
  city: City;
  stateID: number;
  state: State;
  location: string;
  idCobrador: number;
  calle: string;
  numeroExt: number;
  numeroInt: string;
  idColonia: number;
  idMunicipio: number;
  idEstado: number;
  estado: string;
  municipio: string;
  colonia: string;
  codigoPostal: number;
  idCodigoPostal: number;
  constructor() {
    this.city = new City();
    this.state = new State();
    this.neighborhood = new Neighborhood();
  }
}
