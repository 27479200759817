<div class="app content kobra">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Condiciones</mat-card-title>
    </mat-card-header>
    <mat-card-content class="card-container">
      <app-loader *ngIf="isLoadPregunta" [message]="'Cargando preguntas...'"></app-loader>
      <div *ngIf="!isLoadPregunta">
        <div *ngFor="let card of cards; let i = index; trackBy: trackByFn">
          <mat-card>
            <mat-card-header>
              <div class="card-header-flex">
                <mat-card-title>Condición {{ i + 1 }}</mat-card-title>
                <div class="action-button" *ngIf="cards.length > 1">
                  <button mat-icon-button value="Menu" matTooltip="Acciones" mat-tooltip-panel-above [matMenuTriggerFor]="menuAccionesEx">
                    <mat-icon class="kobra-icon">more_vert</mat-icon>
                  </button>
                </div>
              </div>
            </mat-card-header>
            <mat-menu #menuAccionesEx="matMenu">
              <button mat-menu-item matTooltip="Eliminar condición" mat-tooltip-panel-above (click)="eliminarCard(card)">
                <mat-icon color="warn">delete_forever</mat-icon> Eliminar
              </button>
            </mat-menu>
            <mat-card-content>
              <mat-select-search
                class="id-card-field"
                [label]="'Pregunta*'"
                [placeHolder]="'Selecciona una pregunta'"
                [value]="'option'"
                [labelField]="'nombrePregunta'"
                [icon]="'question_mark'"
                [options]="preguntasDisponibles"
                [formControl]="getPreguntaControl(card)"
                (ngModelChange)="procesarPregunta($event, card);"
              ></mat-select-search>
              <mat-error *ngIf="validarControl(getPreguntaControl(card))" style="margin-top: -20px; font-size: 12px; margin-left: 15px;">
                La pregunta es <strong>requerida</strong>
              </mat-error>
              <form [formGroup]="card.formGroup">
                <mat-form-field class="ancho-field" *ngIf="card.idTipoValor == 2">
                  <mat-label>Operador</mat-label>
                  <mat-select formControlName="operador" (ngModelChange)="actualizarOperador(card, i );">
                    <mat-option *ngFor="let operador of card.operadores" [value]="operador.value">{{ operador.display }}</mat-option>
                  </mat-select>
                  <mat-icon matSuffix 
                    *ngIf="card.formGroup.get('operador')?.value" 
                    (click)="limpiarTipoRespuesta(card.formGroup.get('operador'))">
                    clear
                  </mat-icon>
                  <mat-error *ngIf="validarControl(card.formGroup.get('operador'))">Este campo es <strong>requerido</strong></mat-error>
                </mat-form-field>
                <ng-container #dynamicContainer></ng-container>
              </form>
            </mat-card-content>
          </mat-card>

          <div *ngIf="i < cards.length - 1" class="condicional-connector">
            <div class="line"></div>
            <div class="condicional-text">{{ cards[i + 1]?.condicionalSeleccionada?.nombre || 'Seleccione una condicional' }}</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-flat-button class="boton-cancelar" (click)="cancel()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
      <button
        mat-stroked-button
        class="boton-accion-front"
        (click)="agregarTipoCondicion()"
      >
        <mat-icon>add</mat-icon> Agregar condición
      </button>
      <button
        mat-stroked-button
        [ngClass]="{ 'boton-accion-guardar': guardarHabilitado }"
        [disabled]="!guardarHabilitado"
        (click)="accionGuardar()"
      >
        <mat-icon>save</mat-icon> Guardar
      </button>
    </mat-card-actions>
  </mat-card>

  <!-- Condición completa -->
  <div style="margin-top: 20px;" *ngIf="condicionTextoCambiado">
    <mat-card class="info">
      <mat-card-content class="contendor-clara-mat">
        <ng-container>
          <span style="font-weight: bold; font-size: 18px;">Clave</span>
          <div style="display: flex; align-items: center;">
            <button
              style="margin-left: -10px;"
              mat-icon-button
              matTooltip="Copiar variable"
              mat-tooltip-panel-above
              ngxClipboard
              [cbContent]="copiarClave"
              aria-label="Button that displays a tooltip when focused or hovered over"
            >
              <mat-icon class="kobra-icon">file_copy</mat-icon>
            </button>
            <span style="margin-left: 8px;">{{ claveVariable }}</span>
          </div>
        </ng-container>
        <ng-container>
          <span style="font-weight: bold; font-size: 18px;">Descripción</span>
          <div style="margin-top: 10px;">
            <p [innerHTML]="condicionTexto"></p>
          </div>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>
