
import { ApplicationService } from './../../../application/shared/application.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";

import { AgentAddress } from "../../modelos/agent-address.model";
import { AgentAddressService } from "../../modelos/agent-address.service";
import { FormValidation } from "../../../shared/form-validation/form-validation.model";
import { LoaderService } from "../../../loader/loader.service";
import { MessageService } from "../../../shared/message/message.service";
import { ServicesGestoresService } from './../../../core/http/services-gestores/services-gestores.service';
import { Observable } from 'rxjs';
import { environmentSelector } from '../../../../environments/environment.selector';
import { VisitService } from 'src/app/visit/visit.service';

@Component({
  selector: 'agent-address-form',
  templateUrl: './agent-address-form.component.html',
  styleUrls: ['./agent-address-form.component.css'],
  providers: [
    AgentAddressService,
    VisitService,
  ]
})
export class AgentAddressFormComponent implements OnChanges, OnInit {
  private environment = environmentSelector();
  public loader: boolean;
  public loaderEstados: boolean;
  public loaderMunicipios: boolean;
  public loaderColonias: boolean;
  public loaderCodigoPostales: boolean;
  public estados: any;
  public municipios: any;
  public colonias: any;
  public codigosPostales: any;
  public selectedIdEstado: number;
  public selectedIdMunicipio: number;
  public selectedIdColonia: number;
  public selectedEstado: string;
  public selectedMunicipio: string;
  public selectedColonia: string;
  public labelEstado: string;
  public labelEligeEstado: string;
  public labelEstadoPlural: string;
  public labelMunicipio: string;
  public labelCodigoPostal: string;
  public labelEligeMunicipio: string;
  public labelMunicipioPlural: string;
  public labelColonia: string;
  public labelEligeColonia: string;
  public labelColoniaPlural: string;
  public mostrarColonia: boolean;
  public nuevaColonia: string = null;
  selectedIdCodigoPostal: number;


  public form: FormGroup;
  public calleValidation: FormValidation;
  public numeroExtValidation: FormValidation;
  public numeroIntValidation: FormValidation;
  //public coloniaValidation: FormValidation;
  //public codigoPostalValidation: FormValidation;
  public municipioValidation: FormValidation;
  public estadoValidation: FormValidation;
  public guardando: boolean;

  @Input() agentAddress: AgentAddress;
  @Input() agentID: number;

  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<AgentAddress> = new EventEmitter();

  constructor(
    private agentAddressService: AgentAddressService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private app: ApplicationService,
    private serviciosGestores: ServicesGestoresService,
    private visitService: VisitService,

  ) {
    this.labelEstado = this.environment.labelEstado;
    this.labelEstadoPlural = this.environment.labelEstadoPlural;
    this.labelEligeEstado = this.environment.labelEligeEstado;
    this.labelMunicipio = this.environment.labelMunicipio;
    this.labelCodigoPostal = this.environment.labelCodigoPostal;
    this.labelMunicipioPlural = this.environment.labelMunicipioPlural;
    this.labelEligeMunicipio = this.environment.labelEligeMunicipio;
    this.labelColonia = this.environment.labelColonia;
    this.labelColoniaPlural = this.environment.labelColoniaPlural;
    this.labelEligeColonia = this.environment.labelEligeColonia;
  }

  ngOnChanges() {
    this.resetForm();
  }

  ngOnInit() {
    this.initForm();
    this.initValidation();
    this.loadEstados();
    if (this.agentAddress) {

      if (this.agentAddress.colonia && this.agentAddress.idColonia == null){
       // console.log(this.agentAddress)
        this.hideMatSelect();
        this.toggleMostrarColonia();
      }
      this.nuevaColonia = this.agentAddress.colonia;

      this.resetForm();
    }

  }

  public cancel(): void {
    this.onCancel.emit();
    this.resetForm();
  }

  public toggleMostrarColonia(): void {
    this.mostrarColonia = !this.mostrarColonia;
  }

  public hideMatSelect(): void {
    this.mostrarColonia = false;
    this.limpiarControles('idColonia')
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      calle: ['', [
        Validators.required
      ]],
      numeroExt: ['', [
        Validators.required
      ]],
      numeroInt: ['', [
      ]],
      idEstado: [null, [
        Validators.required
      ]],
      idMunicipio: ['', [
        Validators.required
      ]],
      codigoPostal: ['', [
      ]],
      idColonia: ['', [
      ]],
      colonia: ['', [
      ]],
    });
  }

  private initValidation(): void {
    this.calleValidation = new FormValidation();
    this.calleValidation.formControl = this.form.controls['calle'];
    this.calleValidation.message = {
      required: 'Información requerida.'
    };
    this.calleValidation.observeFromControl();

    this.numeroExtValidation = new FormValidation();
    this.numeroExtValidation.formControl = this.form.controls['numeroExt'];
    this.numeroExtValidation.message = {
      required: 'Información requerida.'
    };
    this.numeroExtValidation.observeFromControl();

    this.estadoValidation = new FormValidation();
    this.estadoValidation.formControl = this.form.controls['idEstado'];
    this.estadoValidation.message = {
      required: 'Información requerida.'
    };
    this.estadoValidation.observeFromControl();

    this.municipioValidation = new FormValidation();
    this.municipioValidation.formControl = this.form.controls['idMunicipio'];
    this.municipioValidation.message = {
      required: 'Información requerida.'
    };
    this.municipioValidation.observeFromControl();

    // this.codigoPostalValidation = new FormValidation();
    // this.codigoPostalValidation.formControl = this.form.controls['codigoPostal'];
    // this.codigoPostalValidation.message = {
    //   required: 'Información requerida.'
    // };
    // this.codigoPostalValidation.observeFromControl();

    // this.coloniaValidation = new FormValidation();
    // this.coloniaValidation.formControl = this.form.controls['idColonia'];
    // this.coloniaValidation.message = {
    //   required: 'Información requerida.'
    // };
    // this.coloniaValidation.observeFromControl();

    this.form.get('idEstado')?.valueChanges.subscribe((value) => {
      if (value === null) {
        this.limpiarControles('idColonia', 'codigoPostal', 'idMunicipio', 'colonia');
        this.colonias = null
        this.codigosPostales = null
        this.selectedIdCodigoPostal = null;
        this.municipios = null
        this.selectedColonia = null
      } else {
        this.changeEstado();
      }
    });

    this.form.get('idMunicipio')?.valueChanges.subscribe((value) => {
      if (value === null) {
        this.limpiarControles( 'codigoPostal', 'idColonia', 'colonia');
        this.colonias = null
        this.codigosPostales = null
        this.selectedIdCodigoPostal = null;
        this.selectedColonia = null
      } else {
        this.changeMunicipio();
      }
    });

    this.form.get('codigoPostal')?.valueChanges.subscribe((value) => {
      if (value === null) {
        this.limpiarControles( 'idColonia', 'colonia');
        this.toggleMostrarColonia();
        this.colonias = null
        this.selectedColonia = null
      } else {
        this.changeCodigoPostal();
      }
    });

    this.form.get('idColonia')?.valueChanges.subscribe((value) => {
      this.changeColonias();
    });
  }

  public loadMunicipios(): void {
    if (this.form.value.idEstado) {
      this.loaderMunicipios = true;
      this.municipios = [];
      this.visitService.obtenerMunicipios(this.form.value.idEstado)
        .subscribe(
          municipios => {
            this.municipios = municipios.data;
            this.loaderMunicipios = false;
          },
          error => {
            this.loaderMunicipios = false;
            this.messageService.httpError(error);
          }
        );
    } else {
      this.municipios = [];
    }
  }

  public loadCodigoPostal(): void {
    if (this.form.value.idEstado && this.form.value.idMunicipio) {
      this.loaderCodigoPostales = true;
      this.codigosPostales = [];
      this.visitService.obtenerCodigosPostales(this.form.value.idMunicipio)
      this.visitService.obtenerCodigosPostales(this.form.value.idMunicipio)
        .subscribe(
          codigoPostales => {
            this.codigosPostales = codigoPostales.data;
            this.loaderCodigoPostales = false;
          },
          error => {
            this.messageService.httpError(error);
            this.loaderCodigoPostales = false;
          }

        );
    } else {
      this.codigosPostales = [];
    }
  }

  public loadColonias(): void {
    if (this.form.value.idMunicipio) {
      this.loaderColonias = true;
      this.colonias = [];
      this.visitService.obtenerColonias(this.form.value.idMunicipio, this.form.value.codigoPostal)
        .subscribe(
          colonias => {
            this.colonias = colonias.data;
            this.loaderColonias = false;
          },
          error => {
            this.messageService.httpError(error);
            this.loaderColonias = false;
          }

        );
    } else {
      this.colonias = [];
    }
  }

  private loadEstados(): void {
    this.loaderEstados = true;
    this.visitService.obtenerEstados()
      .subscribe(
        estados => {
          this.estados = estados.data;
          this.loaderEstados = false;
        },
        error => {
          this.messageService.httpError(error);
          this.loaderEstados = false;
        }
      );
  }

  private prepareFormData(): AgentAddress {
    let agentAddress: AgentAddress = new AgentAddress();
    agentAddress.calle = this.form.value.calle;
    agentAddress.numeroExt = this.form.value.numeroExt;
    agentAddress.numeroInt = this.form.value.numeroInt;
    agentAddress.codigoPostal = this.form.value.codigoPostal||null;
    agentAddress.idCodigoPostal = this.selectedIdCodigoPostal;
    agentAddress.idEstado = this.form.value.idEstado;
    agentAddress.idMunicipio = this.form.value.idMunicipio;
    agentAddress.idColonia = this.form.value.idColonia||null;
    agentAddress.colonia = this.form.value.colonia||null;
    return agentAddress;
  }

  private resetForm(): void {
    if (this.form) {
      this.form.reset({
        calle: this.agentAddress.calle,
        numeroExt: this.agentAddress.numeroExt,
        numeroInt: this.agentAddress.numeroInt,
        idColonia: this.agentAddress.idColonia,
        colonia: this.agentAddress.colonia,
        codigoPostal: this.agentAddress.codigoPostal,
        idEstado: this.agentAddress.idEstado,
        idMunicipio: this.agentAddress.idMunicipio,
      });
      this.selectedIdCodigoPostal = this.agentAddress.idCodigoPostal;
      this.selectedEstado = this.agentAddress.estado;
      this.selectedMunicipio = this.agentAddress.municipio;
      this.selectedColonia = this.agentAddress.colonia;
      this.nuevaColonia = this.agentAddress.colonia;
      this.loadMunicipios();
      this.loadColonias();
      this.loadCodigoPostal();
    }
  }

  public save(): void {

    if(!this.form.valid){
      this.app.showSnackbar('¡Aviso!', `Hay campos pendientes de capturar`, 3000, 'warning');
      this.app
      return;
    }
    let agentAddress: AgentAddress = this.prepareFormData();

    if (this.nuevaColonia) {
      this.limpiarControles('idColonia')
      agentAddress.idColonia = null;
      this.selectedColonia = this.nuevaColonia;
     }else{
      this.selectedColonia = null;
     }

    this.loader = true;
    let loading: number = this.app.showLoading('Actualizando domicilio...');
    let response: Observable<AgentAddress>;
    if (this.agentAddress.idAgente) {
        let domicilio = <AgentAddress>{
          idAgente: this.agentAddress.idAgente,
          calle: agentAddress.calle,
          numeroExt: agentAddress.numeroExt,
          numeroInt: agentAddress.numeroInt,
          idColonia: agentAddress.idColonia,
          idMunicipio: agentAddress.idMunicipio,
          idEstado: agentAddress.idEstado,
          codigoPostal: agentAddress.codigoPostal,
          estado: this.selectedEstado,
          municipio: this.selectedMunicipio,
          colonia: agentAddress.colonia ? this.selectedColonia : null,
        };
        this.visitService.saveDomicilioAgente(this.agentAddress.idAgente, domicilio)
        .subscribe(
          response => {
            this.loader = false;
            this.app.hideLoading(loading);
            this.onSave.emit(domicilio);
          }, (error: any) => {
            this.app.showError(error);
            this.app.hideLoading(loading);
          });
    }else{
      this.app.hideLoading(loading);
      this.app.showSnackbar('¡Aviso!', `Ocurrió un problema`, 3000, 'warning');
    }
  }

  public changeEstado() : void {
    setTimeout(()=>{
      if(this.form.get('idEstado').value && this.estados && this.estados.length>0){
        let index : number  = this.estados.findIndex((x:any) => x.idEstado == this.form.get('idEstado').value);
        if(this.selectedEstado != this.estados[index].nombre){
          this.limpiarControles('idColonia', 'codigoPostal', 'idMunicipio');
          this.selectedMunicipio = '';
        }
        this.selectedEstado = this.estados[index].nombre;
        this.loadMunicipios();
      }
    });
  }

  public changeMunicipio() : void {
    setTimeout(()=>{
      if(this.form.get('idMunicipio').value && this.municipios && this.municipios.length>0){
        let index : number  = this.municipios.findIndex((x:any) => x.idMunicipio == this.form.get('idMunicipio').value);
        if(this.selectedMunicipio != this.municipios[index].nombre){
          this.limpiarControles('idColonia', 'codigoPostal');
          this.selectedColonia = '';
        }
        this.selectedMunicipio = this.municipios[index]?.nombre;
        this.loadCodigoPostal();
      }
    });
  }

  public changeCodigoPostal() : void {
    setTimeout(()=>{
      if(this.form.get('codigoPostal').value && this.codigosPostales && this.codigosPostales.length>0){
        let index : number  = this.codigosPostales.findIndex((x:any) => x.codigoPostal == this.form.get('codigoPostal').value);
        if(this.selectedIdCodigoPostal != this.codigosPostales[index].idCodigoPostal){
          this.limpiarControles('idColonia');
          this.selectedColonia = '';
        }
        this.selectedIdCodigoPostal = this.codigosPostales[index].idCodigoPostal;
        this.loadColonias();
      }
    });
  }

  public changeColonias() : void {
    this.nuevaColonia = null;
      if(this.form.get('idColonia').value && this.colonias && this.colonias.length>0){
        let index : number  = this.colonias.findIndex((x:any) => x.idColonia == this.form.get('idColonia').value);
        this.selectedColonia = this.colonias[index].nombre;
      }
  }

  private limpiarControles(...propiedades: string[]){
    setTimeout(()=>{
      for (let index = 0; index < propiedades.length; index++) {
        const propiedad = propiedades[index];
        this.form.controls[propiedad].patchValue('', {emitEvent: false});
      }
    }, 100);

  }

}
