import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { WorkReference } from "./work-reference.model";
import { map } from 'rxjs/operators';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';

@Injectable()
export class WorkReferenceService {

  constructor(
    private visitasServ: ServicesVisitasService,
  ) { }

  public add( reference: WorkReference ): Observable<number> {
    return this.visitasServ.post(`/agentes/${reference.agentID}/referencia-laboral`, reference).pipe(
      map(res => res.data)
    );
  }

  public all(agentID: number): Observable<WorkReference[]> {
    return this.visitasServ.get(`/agentes/${agentID}/referencia-laboral?activo=true`).pipe(
      map((res) => {
        // return res.json() as WorkReference[];
          let referencesData = res.data;
          let references: WorkReference[] = [];
          for (let i = 0; i < referencesData.length; i++) {
            let reference: WorkReference = new WorkReference();
            reference.id = referencesData[i].idReferenciaLaboral;
            reference.name = referencesData[i].nombre;
            reference.surname = referencesData[i].apellido;
            reference.fullName = referencesData[i].nombre + ' ' + referencesData[i].apellido;
            reference.company = referencesData[i].empresa;
            reference.phoneNumber = referencesData[i].telefono;
            reference.agentID = referencesData[i].idAgente;
            references.push(reference);
          }
          return references;
        })
    );
  }

  public delete( id: number, reference: WorkReference ): Observable<any> {
    reference.activo = false;

    return this.visitasServ.patch(`/agentes/referencia-laboral/${id}`, reference).pipe(
      map(res => res.data)
    );
  }

  public update( reference: WorkReference ): Observable<number> {
    reference.activo = true;

    return this.visitasServ.patch(`/agentes/referencia-laboral/${reference.id}`, reference).pipe(
      map(res => res.data)
    );
  }
}