
<app-loader *ngIf="cargandoExperienciaLaboral" [message]="'Cargando experiencias laborales'">
</app-loader>

<mat-card *ngIf="!cargandoExperienciaLaboral">
  <!-- <mat-card-actions  align="end" *ngIf="!deleted && sessionData.isSuperAdmin">
    <button mat-icon-button
      value="Menu"
      matTooltip="Acciones"
      mat-tooltip-panel-above
      [matMenuTriggerFor]="menu"
    >
      <mat-icon class="kobra-icon">more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button 
        mat-menu-item 
        matTooltip="Agregar experiencia laboral"
        mat-tooltip-panel-above
        (click)="showForm()" >
        <mat-icon class="kobra-icon">add</mat-icon>Nuevo
      </button>
    </mat-menu>
  </mat-card-actions> -->
  <mat-card-content style="max-height: 500px; overflow: auto;">
    <div *ngFor="let experience of workExperiences; index as e">
      <!-- Verifica el índice para alternar entre izquierda y derecha -->
      <mat-card class="card-labaral" [ngClass]="{'card-left': e % 2 === 0, 'card-right': e % 2 !== 0}">
        <mat-card-actions align="end" *ngIf="activo && sessionData.isSuperAdmin">
          <button mat-icon-button
            value="subMenu"
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menuEditar"
          >
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </button>
          <mat-menu #menuEditar="matMenu">
            <button 
              mat-menu-item 
              matTooltip="Editar"
              mat-tooltip-panel-above
              (click)="showForm(experience)" >
              <mat-icon class="kobra-icon">edit</mat-icon>Editar
            </button>
              <button 
              mat-menu-item 
              matTooltip="Eliminar"
              mat-tooltip-panel-above
              (click)="eliminar(experience)" >
              <mat-icon color="warn">delete_forever</mat-icon>Eliminar
            </button>
          </mat-menu>
            
          
          
        </mat-card-actions>
        <mat-card-content>
          
          <div>
            <p class="etiqueta-p"><strong>Empresa:</strong> {{ experience.company }}</p>
            <p class="etiqueta-p"><strong>Puesto:</strong> {{ experience.title }}</p>
            <p class="etiqueta-p"><strong>Jefe Inmediato:</strong> {{ experience.boss }}</p>
            <p class="etiqueta-p"><strong>Periodo Laboral:</strong> {{ experience.startDate | date:'dd/MM/yyyy' }} - {{ experience.endDate | date:'dd/MM/yyyy' }} </p>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="workExperiences.length == 0 " style="text-align: center;">
      <i>Sin información para mostrar</i>
    </div>
  </mat-card-content>
</mat-card>

