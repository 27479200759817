<!--app-bar title="Nuevo cliente"></app-bar-->

<div class="app content kobra">
  <form [formGroup]="formGroup" class="form">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Empresa
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="wizard-container">
          <div class="kobra two columns">
            <div class="kobra column">
              <div>
                <mat-card class="card-sin-borde">
                  <mat-card-header>
                    <mat-card-title>
                      Datos generales
                    </mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="kobra two columns">
                      <div class="kobra column">
                        <!-- <app-field formControlName="nombre" placeholder="Nombre" [required]="true"></app-field> -->
                        <mat-form-field >
                          <mat-label>Nombre</mat-label>
                          <input matInput textSanitizer  [type]="'text'"  formControlName="nombre" maxlength="100">
                          <mat-icon matSuffix>person</mat-icon>
                        </mat-form-field>
                      </div>
                      <div class="kobra column">
                        <!-- <app-field formControlName="razonSocial" placeholder="Razón social" [required]="true"></app-field> -->
                        <mat-form-field >
                          <mat-label>Razón Social</mat-label>
                          <input matInput textSanitizer  [type]="'text'"  formControlName="razonSocial" maxlength="100">
                          <mat-icon matSuffix>group</mat-icon>
                        </mat-form-field>
                      </div>
    
                      <div class="kobra column">
                        <!-- <app-field formControlName="telefono" placeholder="Teléfono" [required]="true"></app-field> -->
                        <mat-form-field >
                          <mat-label>Teléfono</mat-label>
                          <input matInput textSanitizer  [type]="'text'"  formControlName="telefono" maxlength="100">
                          <mat-icon matSuffix>call</mat-icon>
                        </mat-form-field>
                      </div>
                      <div class="kobra column">
                        <!-- <app-field formControlName="sitioWeb" placeholder="Sitio web"></app-field> -->
                        <mat-form-field >
                          <mat-label>Sitio Web</mat-label>
                          <input matInput textSanitizer  [type]="'text'" formControlName="sitioWeb" maxlength="100">
                          <mat-icon matSuffix>language</mat-icon>
                        </mat-form-field>
                      </div>
    
                      <div class="kobra column">
                        <app-field
                        type="upload"
                        [fileAccept]="['image/*']"
                        [maxWidth]="7680"
                        [maxHeight]="4320"
                        formControlName="logo"
                        placeholder="Logo"
                      ></app-field>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
                <br>
                <mat-card class="card-sin-borde dos">
                  <mat-card-header>
                    <mat-card-title>
                      Datos del administrador
                    </mat-card-title>
                  </mat-card-header>
    
                  <mat-card-content>
                    <div class="kobra two columns">
                      <div class="kobra column">
                        <!-- <app-field formControlName="nombreEncargado" placeholder="Nombre" [required]="true"></app-field> -->
                        <mat-form-field >
                          <mat-label>Nombre</mat-label>
                          <input matInput textSanitizer  [type]="'text'"  formControlName="nombreEncargado" maxlength="100">
                          <mat-icon matSuffix>person</mat-icon>
                        </mat-form-field>
                      </div>
                      <div class="kobra column">
                        <!-- <app-field formControlName="email" placeholder="Correo electrónico" [required]="true"></app-field> -->
                        <mat-form-field >
                          <mat-label>Correo electrónico</mat-label>
                          <input matInput textSanitizer  [type]="'text'"  formControlName="email" maxlength="100">
                          <mat-icon matSuffix>mail</mat-icon>
                        </mat-form-field>                        
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
    
            <div class="kobra column">
              <mat-card style="margin-bottom: 20px;">
                <mat-card-header>
                  <mat-card-title>
                    Datos de contacto
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="button-container">
                    <div class="button-toggle-group-container right">
                      <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
                        <mat-button-toggle value="agregar" 
                          matTooltip="Agregar contacto"
                          mat-tooltip-panel-above
                          (click)="addContact()">
                          <mat-icon class="kobra-icon">add</mat-icon>
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                  </div>
    
                <mat-card *ngIf="showNewContact">
                    <mat-card-content class="card-with-arrow">
                        <form [formGroup]="formGroupContacto">
                          <div class="kobra two columns">
                            <div class="kobra column">
                              <mat-form-field >
                                <mat-label>Nombre</mat-label>
                                <input matInput textSanitizer  [type]="'text'" placeholder="Ingrese el nombre del contacto" formControlName="nombre" maxlength="100">
                                <mat-icon matSuffix>person</mat-icon>
                              </mat-form-field>
                            </div>
                            <div class="kobra column">
                              <mat-form-field >
                                <mat-label>Teléfono</mat-label>
                                <input matInput textSanitizer  [type]="'text'" placeholder="Ingrese el número de contacto" formControlName="numeroTelefono" maxlength="100">
                                <mat-icon matSuffix>call</mat-icon>
                              </mat-form-field>
                            </div>
                          </div>
                            
                        </form>
                    </mat-card-content>
                    <mat-card-actions class="botones-card-derecha">
                      <button 
                            class="boton-cancelar"
                            mat-flat-button
                            matTooltip="Cancelar"
                            mat-tooltip-panel-above
                            (click)="cancelarContacto()"
                        >
                            <mat-icon>cancel</mat-icon> Cancelar
                      </button> 
                      <button
                            mat-flat-button
                            matTooltip="Guardar contacto"
                            mat-tooltip-panel-above
                            color="primary"
                            (click)="saveContacto()"
                            [disabled]="formGroupContacto.invalid"
                        >
                          <mat-icon>save</mat-icon> Guardar
                        </button> 
                    </mat-card-actions>
                </mat-card>
                  <!--button mat-icon-button f-actions (click)="addContact()">
                    <mat-icon aria-label="Agregar contacto">add</mat-icon>
                  </button-->
      
                  <app-empty *ngIf="contacts.length === 0 && !showNewContact"></app-empty>
      
                  <mat-list *ngFor="let contact of contacts; index as i">
                    <mat-list-item>
                      <div style="display: flex;">
                        <mat-icon mat-list-icon style="margin-top: 15px;">contact_phone</mat-icon>
                        <h4 style="margin-top: 15px; margin-left: 5px;" mat-line>{{contact.nombre}}:</h4>
                        <p mat-line style="margin-top: 15px; margin-left: 15px">{{contact.numeroTelefono}}</p>
                        <button mat-icon-button style="margin-left: auto;" (click)="contacts.splice(i, 1)" matTooltip="Eliminar contacto" mat-tooltip-panel-above >
                          <mat-icon color="warn" aria-label="Eliminar contacto">delete_forever</mat-icon>
                        </button>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <button class="boton-cancelar" type="button" mat-flat-button routerLink="/clientes"  (click)="cancelar()">
          <mat-icon class="kobra-icon">close</mat-icon>Cancelar
        </button>          
        <button
          mat-stroked-button
          [ngClass]="{'boton-guardar': !formGroup.invalid}" 
          [disabled]="formGroup.invalid"
          (click)="register()"
        ><mat-icon>save</mat-icon>Registrar</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
