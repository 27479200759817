<form [formGroup]="form" (submit)="save()">
  <span class="titulo">Editar</span>
  <mat-grid-list-responsive style="margin-top: 20px;" [colsLa]="3" [colsMd]="2" [colsSm]="1" rowHeight="80px">
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Nombre(s)</mat-label>
        <input matInput textSanitizer  class="upper"
          formControlName="name"
          placeholder="Ingrese el nombre del agente">
        <mat-icon matSuffix>person</mat-icon>
        <mat-error *ngIf="nameValidation.hasError()">{{nameValidation.error}}</mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Apellido Paterno</mat-label>
        <input matInput textSanitizer  class="upper"
          formControlName="firstSurname"
          placeholder="Ingrese el apellido paterno del agente">
        <mat-icon matSuffix>person</mat-icon>
        <mat-error *ngIf="firstSurnameValidation.hasError()">{{firstSurnameValidation.error}}</mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field>
        <mat-label>Apellido Materno</mat-label>
        <input matInput textSanitizer  class="upper"
          formControlName="secondSurname"
          placeholder="Ingrese el apellido paterno del agente">
        <mat-icon matSuffix>person</mat-icon>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1" *ngIf="pais=='MX' && !redInterna">
      <mat-form-field [ngClass]="{error: pais=='MX' ? nssValidation.hasError() : false}">
        <mat-label>NSS</mat-label>
        <input matInput textSanitizer 
          formControlName="nss"
          placeholder="Ingrese el número de seguridad social">
        <mat-icon matSuffix>verified_user</mat-icon>
        <mat-error *ngIf="nssValidation.hasError()">{{nssValidation.error}}</mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1" *ngIf="!redInterna">
      <mat-form-field [ngClass]="{error: pais=='MX' ? curpValidation.hasError() : false}">
        <mat-label>{{labels.labelCURP}}</mat-label>
        <input matInput textSanitizer 
          *ngIf="pais=='MX'" formControlName="curp" pattern="[A-Z]{4}\d{6}[HM][A-Z]{2}[B-DF-HJ-NP-TV-Z]{3}[A-Z0-9][0-9]"
          >
        <input matInput textSanitizer 
          *ngIf="pais!='MX'" formControlName="curp"
          placeholder="Ingrese el {{labels.labelCURP}} del agente">
        <mat-icon matSuffix>badge</mat-icon>
        <mat-error *ngIf="pais=='MX' ? curpValidation.hasError() : false">{{curpValidation.error}}</mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1" *ngIf="!redInterna">
      <mat-form-field [ngClass]="{error: pais=='MX' ? rfcValidation.hasError() : false}">
        <mat-label>{{labels.labelRFC}}</mat-label>
        <input matInput textSanitizer 
          *ngIf="pais=='MX'" formControlName="rfc" pattern="^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$"
          placeholder="Ingrese el número de seguridad social">
        <input matInput textSanitizer 
          *ngIf="pais!='MX'" formControlName="rfc"
          placeholder="Ingrese el {{labels.labelRFC}} del agente">
        <mat-icon matSuffix>badge</mat-icon>
        <mat-error *ngIf="pais=='MX' ? rfcValidation.hasError() : false">{{rfcValidation.error}}</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1" *ngIf="!redInterna">
      <mat-date-picker-formater
      
        [ngClass]="{error: birthdateValidation.hasError()}"
        [label]="'Fecha de Nacimiento'"
        [placeHolder]="'Capture la fecha de nacimiento del agente'"
        [min]="minDate" [max]="maxDate"
        formControlName="birthdate"
        style="width: 76% !important;"
        [limpiar]="false"
      />
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field [ngClass]="{error: genderValidation.hasError()}">
        <mat-label>Sexo*</mat-label>
        <mat-select
          formControlName="gender"
        >
          <mat-option [value]="'M'">
            Masculino
          </mat-option>
          <mat-option [value]="'F'">
            Femenino
          </mat-option>
        </mat-select>
        <mat-icon matSuffix>wc</mat-icon>
        <mat-error *ngIf="genderValidation.hasError()">{{genderValidation.error}}</mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field *ngIf="!redInterna" [ngClass]="{error: cellphoneValidation.hasError()}">
        <mat-label>Teléfono Celular</mat-label>
        <input matInput textSanitizer 
          formControlName="cellphone"
          placeholder="Ingrese el celular del agente">
        <mat-icon matSuffix>smartphone</mat-icon>
        <mat-error *ngIf="cellphoneValidation.hasError()">{{cellphoneValidation.error}}</mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="redInterna">
        <mat-label>Teléfono Celular</mat-label>
        <input matInput textSanitizer 
          formControlName="cellphone"
          placeholder="Ingrese el celular del agente">
        <mat-icon matSuffix>smartphone</mat-icon>
      </mat-form-field>
    </mat-grid-tile>

  </mat-grid-list-responsive>

  <mat-grid-list-responsive [colsLa]="3" [colsMd]="2" [colsSm]="1" [rowHeight]="pixelesContrasena">
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field [ngClass]="{error: emailValidation.hasError()}" [style.margin-top.px]="margenContrasena">
        <mat-label>Email*</mat-label>
        <input matInput textSanitizer 
          class="readonly-input"
          readonly    
          formControlName="email"
          placeholder="Ingrese el correo del agente"
          (input)="convertirAMinusculas($event)">
        <mat-icon matSuffix>mail</mat-icon>
        <mat-error *ngIf="emailValidation.hasError()">{{emailValidation.error}}</mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-form-field [ngClass]="{error: contrasenaValidation.hasError()}" [style.margin-top.px]="margenContrasena">
        <mat-label>Contraseña</mat-label>
        <input matInput textSanitizer 
          formControlName="contrasena"
          placeholder="Ingrese la nueva contraseña del agente">
        <mat-icon matSuffix (click)="hidePwd = !hidePwd">{{ hidePwd ? 'visibility_off' : 'visibility' }}</mat-icon>
        <mat-error *ngIf="contrasenaValidation.hasError()">{{contrasenaValidation.error}}</mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list-responsive>
  <div class="kobra three columns slide">
    <div class="kobra column">
      <mat-slide-toggle class="kobra-slide-toggle" formControlName="buroLaboral">Buró laboral</mat-slide-toggle>
    </div>
    <div class="kobra column">
      <mat-slide-toggle class="kobra-slide-toggle" formControlName="disponible">Activo</mat-slide-toggle>
    </div>
    <div class="kobra column">
      <mat-slide-toggle class="kobra-slide-toggle" formControlName="primerContacto">Primer contacto</mat-slide-toggle>
    </div>
  </div>
  
  <!-- <mat-grid-list-responsive [colsLa]="3" [colsMd]="2" [colsSm]="1" rowHeight="60px">
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-slide-toggle class="kobra-slide-toggle" formControlName="buroLaboral">Buró laboral</mat-slide-toggle>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-slide-toggle class="kobra-slide-toggle" formControlName="disponible">Activo</mat-slide-toggle>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <mat-slide-toggle class="kobra-slide-toggle" formControlName="primerContacto">Primer contacto</mat-slide-toggle>
    </mat-grid-tile>

  </mat-grid-list-responsive> -->

  <div class="field kobra-forms-buttons" align="right">
    <button class="boton-cancelar" mat-flat-button type="submit" style="margin-right: 10px;" (click)="cancel()">
      <mat-icon class="kobra-icon">
        close
      </mat-icon> Cancelar</button>
    <button mat-stroked-button class="kobra-button" type="submit" [disabled]="form.invalid">
      <mat-icon>
        save
      </mat-icon> {{guardando ? 'Guardando...' : 'Guardar'}}</button>
  </div>
</form>
