import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { Subscription, Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { Lender } from '@lender/lender.model';
import { LenderService } from '@lender/lender.service';
import { ClientesEtiquetasVisitasService } from '../clientes-etiquetas-visitas.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

@Component({
  selector: 'app-clientes-etiquetas-visitas-form',
  templateUrl: './clientes-etiquetas-visitas-form.component.html',
  styleUrls: ['./clientes-etiquetas-visitas-form.component.css'],
  providers: [
    LenderService
  ]
})
export class ClientesEtiquetasVisitasFormComponent implements OnInit {
  private querySub: Subscription;
  sessionData: SessionData;
  saveButton = false;
  etiquetaForm: FormGroup;
  etiquetas$: Observable<any>;
  loaderText: string = '';
  lenders: Lender[];
  etiquetaClienteVisita: any = {
    idCliente: null,
    idProducto: null,
    idEtiquetaVisita: null
  };
  products: Array<any>;
  etiquetas: Array<any>;
  loadingProducts: boolean = false;
  loadings: any = {
    financieras: false,
    productos: false,
    etiquetas: false
  }
  etiquetasSeleccionadas: Array<any> = []; // Array para almacenar las etiquetas seleccionadas

  //public selectedIdCliente: number = null;
  //public selectedIdProducto: number = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private app: ApplicationService,
    private localStorageService: LocalStorageService,
    public clientesEtiquetasVisitasService: ClientesEtiquetasVisitasService,
    private fb: FormBuilder,
    private lenderService: LenderService,
  ) {
    this.sessionData = this.localStorageService.getSessionData();
    this.etiquetaForm = this.fb.group(
      {
        idCliente: ['', Validators.required],
        idProducto: ['', Validators.required],
        idEtiquetaVisita: [!this.sessionData.isSuperAdmin ? this.sessionData.idCliente + '' : ''],
      }
    );
    this.etiquetaClienteVisita.idCliente = !this.sessionData.isSuperAdmin ? this.sessionData.idCliente : null;
  }

  ngOnInit() {
    this.loadLenders();
    this.loadClientesEtiquetasVisita();
  }

  checkControl(control: string) {
    return this.etiquetaForm.controls[control].invalid && this.etiquetaForm.controls[control].touched;
  }

  getErrorMessage(control: string) {
    return this.etiquetaForm.controls[control].hasError('required') ? 'Este campo es requerido.' : '';
  }

  private compare(a, b) {
    const nombreA = a.nombre.toUpperCase();
    const nombreB = b.nombre.toUpperCase();

    let comparison = 0;
    if (nombreA > nombreB) {
      comparison = 1;
    } else if (nombreA < nombreB) {
      comparison = -1;
    }
    return comparison;
  }

  loadLenders(): void {
    this.loadings.financieras = true;
    this.lenderService.getAll().subscribe(
      (res: any) => {
        res.data.sort(this.compare);
        this.lenders = res.data;
        this.loadings.financieras = false;
      },
      (err: any) => {
        this.loadings.financieras = false;
        this.app.showError(err);
      }
    );
  }

  loadProducts() {
    this.products = [];
    if (this.etiquetaClienteVisita.idCliente !== null) {
      this.loadings.productos = true;
      this.lenderService.obtenerProductosCliente(this.etiquetaClienteVisita.idCliente)
        .pipe(
          take(1),
          map((res: any) => {
            return res;
          })
        ).subscribe((res: any) => {
          this.loadings.productos = false;
          this.products = res.data;
        }, (err: any) => {
          this.loadings.productos = false;
          this.app.showError(err);
        });
    }
  }

  loadClientesEtiquetasVisita() {
    this.etiquetas = [];
    this.loadings.etiquetas = true;
    this.clientesEtiquetasVisitasService.obtenerGlobalEtiquetas()
      .pipe(
        take(1),
        map((res: any) => {
          return res;
        })
      ).subscribe((res: any) => {
        this.etiquetas = res.data;
        this.loadings.etiquetas = false;
      }, (err: any) => {
        this.loadings.etiquetas = false;
        this.app.showError(err);
      });
  }

  addEtiqueta() {
    const selectedEtiquetaId = this.etiquetaForm.get('idEtiquetaVisita')?.value;
    // Verificar si la etiqueta ya está seleccionada
    const etiquetaYaAgregada = this.etiquetasSeleccionadas.find(et => et.idEtiquetaVisita === selectedEtiquetaId);
    if (etiquetaYaAgregada) {
      this.app.showSnackbar(
        "Aviso",
        "La etiqueta ya se agrego.",
        3000,
        "warning"
      );
    } else {
      const selectedEtiqueta = this.etiquetas.find(et => et.idEtiquetaVisita === selectedEtiquetaId);

      if (selectedEtiqueta) {
        // Agregar la etiqueta al inicio del array
        this.etiquetasSeleccionadas.unshift({
          idEtiquetaVisita: selectedEtiqueta.idEtiquetaVisita,
          etiquetaVisita: selectedEtiqueta.etiquetaVisita
        });
        this.etiquetaForm.get('idEtiquetaVisita')?.reset(); // Resetea el campo de etiqueta en el formulario
      }
    }
  }

  removeEtiqueta(idEtiquetaVisita: number) {
    this.etiquetasSeleccionadas = this.etiquetasSeleccionadas.filter(et => et.idEtiquetaVisita !== idEtiquetaVisita);
  }

  onSubmit() {
    this.saveButton = true;
    if (this.etiquetaForm.valid) {
      this.app.showLoading("Asignando etiqueta...");
  
      // Sobrescribe el valor de idEtiquetaVisita con el array de IDs de las etiquetas seleccionadas
      let idEtiquetaVisita = this.etiquetasSeleccionadas.map(et => et.idEtiquetaVisita);
  
      // Asegurarse de que siempre sea un array, incluso si tiene un solo elemento
      if (!Array.isArray(idEtiquetaVisita)) {
        idEtiquetaVisita = [idEtiquetaVisita];
      }

      
      this.etiquetaForm.patchValue({
        idEtiquetaVisita: idEtiquetaVisita.length > 1 ? idEtiquetaVisita : [idEtiquetaVisita[0]]
      });
      
      const payload = {
        idCliente: this.etiquetaForm.get('idCliente').value,
        idProducto: this.etiquetaForm.get('idProducto').value,
        idEtiquetaVisita: idEtiquetaVisita,
      };

      // Envía el formulario con el array de IDs en idEtiquetaVisita
      this.clientesEtiquetasVisitasService.nuevaEtiqueta(payload)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.saveButton = false;
        this.app.hideLoading();
    
        // Verifica la longitud de etiquetasSeleccionadas para determinar el texto
        const etiquetasCount = this.etiquetasSeleccionadas.length;
        const mensaje = etiquetasCount > 1
          ? `Se asignaron correctamente las ${etiquetasCount} etiquetas`
          : `Se asignó correctamente la etiqueta`;
    
        this.app.showSnackbar('¡Aviso!', mensaje, 3000, 'success');
    
        this.etiquetaForm.reset(); // Resetea todo el formulario
        this.etiquetasSeleccionadas = []; // Limpia las etiquetas seleccionadas después de guardar
      }, (error: any) => {
        this.saveButton = false;
        this.app.hideLoading();
        this.app.showError(error);
      });
    
    } else {
      this.saveButton = false;
      (<any>Object).values(this.etiquetaForm.controls).forEach((control: FormControl) => {
        control.markAsTouched();
      });
    }
  }
  
  
  


}