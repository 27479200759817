
<app-loader *ngIf="showSegmentLoader" [message]="'Cargando referencias personales'">
</app-loader>

 <mat-card >
  <!-- <mat-card-actions  align="end" *ngIf="!deleted && sessionData.isSuperAdmin">
    <button mat-icon-button
      value="Menu"
      matTooltip="Acciones"
      mat-tooltip-panel-above
      [matMenuTriggerFor]="menu"
    >
      <mat-icon class="kobra-icon">more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button  mat-menu-item 
        matTooltip="Agregar referencia"
        mat-tooltip-panel-above
        (click)="showForm()">
        <mat-icon class="kobra-icon">add</mat-icon>
        Nuevo
      </button>
    </mat-menu>
  </mat-card-actions> -->
  <mat-card-content style="max-height: 352px; overflow: auto;">
    <div *ngIf="isRegistros">
      <div *ngFor="let reference of references; index as e" >
        <mat-card-actions  align="end" *ngIf="activo && sessionData.isSuperAdmin">
          <button mat-icon-button
            value="Menu"
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menuEditar"
          >
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </button>
          <mat-menu #menuEditar="matMenu">
            <button mat-menu-item 
              matTooltip="Editar"
              mat-tooltip-panel-above
              (click)="showForm(reference)">
              <mat-icon class="kobra-icon">edit</mat-icon>
              Editar
            </button>
            <button mat-menu-item 
              matTooltip="Eliminar"
              mat-tooltip-panel-above
              (click)="eliminar(reference)">
              <mat-icon color="warn">delete_forever</mat-icon>
              Eliminar
            </button>
          </mat-menu>
        </mat-card-actions>
        <div style="text-align: center; margin-bottom: 10px;">
          <div style="margin-bottom: 20px;">
            <span class="titulo-mayuscula tutilo-referencias">{{reference.fullName}}</span>
          </div>
          <div style="margin-bottom: 10px;">
            <mat-icon class="kobra-icon icon-ref">phone</mat-icon>
            <span>{{reference.phoneNumber}}</span>
          </div>
          <div style="margin-bottom: 10px;">
            <mat-icon class="kobra-icon icon-ref">person</mat-icon>
            <span>{{reference.relationship.name}}</span>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
    <div style="text-align: center;" *ngIf="!isRegistros">
      <i >Sin información para mostrar.</i> 
     </div>
  </mat-card-content>
</mat-card>
