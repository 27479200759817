<div class="app content kobra">
  <form [formGroup]="conceptoForm" (ngSubmit)="onSubmit()">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{concepto ? 'Edición' : 'Nuevo'}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <div class="row">
            <div class="column">
              <mat-form-field style="width: 100%;">
                <mat-label>Tipo concepto</mat-label>
                <mat-select
                  formControlName="idTipoConcepto"
                  placeholder="Seleccione..."
                >
                  <ng-container *ngFor="let tipoConcepto of tiposConceptos">
                    <mat-option [value]="tipoConcepto.idTipoConcepto">{{tipoConcepto.nombre}}</mat-option>
                  </ng-container>
                </mat-select>
                <mat-icon matSuffix>tag</mat-icon>
              </mat-form-field>
                <mat-error *ngIf="checkControl('idTipoConcepto')">
                  {{getErrorMessage('idTipoConcepto')}} <strong>requerido</strong>
                </mat-error>
            </div>
            <div class="column">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Nombre Concepto</mat-label>
                <input matInput textSanitizer  placeholder="Escribir nombre del concepto" formControlName="nombre">
                <mat-icon matSuffix>donut_large</mat-icon>
                <mat-error *ngIf="checkControl('nombre')">
                  {{getErrorMessage('nombre')}} <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div> 
            <mat-checkbox class="kobra-checkbox" formControlName="cargo">Cargo</mat-checkbox>
            <mat-checkbox class="kobra-checkbox" *ngIf="concepto" formControlName="activo">Activo</mat-checkbox>
          </div> 
      </mat-card-content>
      <mat-card-actions align="end">
        <button class="boton-cancelar" mat-flat-button routerLink="../" queryParamsHandling="preserve"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
        <button mat-stroked-button class="boton-guardar" [disabled]="saveButton"><mat-icon style="color:white !important">save</mat-icon>Guardar</button>
      </mat-card-actions>
    </mat-card>
  </form>
  
</div>
