<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; hijoDesactivado();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container-wrapper button-container"> 
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
          <mat-button-toggle value="filtros" 
            matTooltip="Filtrar"
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>

          </mat-button-toggle>
          <mat-button-toggle value="descargas"
            (click)="descargarListado()" 
            matTooltip="Descargar listado"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">cloud_download</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
          
            (click)="loadAgents(filtros.page)"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  
    <mat-menu #menu="matMenu">
      <!-- <button mat-menu-item 
        (click)="openDialogEstatus()"
        [disabled]="itemsSelected.length == 0"
      >
        <mat-icon class="kobra-icon">swap_vert</mat-icon>
        <span>Estatus</span>
      </button> -->
      <button mat-menu-item 
        [disabled]="itemsSelected.length == 0"
        (click)="openDialogVinculacion()"
        >
        <mat-icon class="kobra-icon">link</mat-icon>
        <span>Vincular productos</span>
      </button>
      <button mat-menu-item
        routerLink="/agentes/nuevo"
        *ngIf="validarMenu('/agentes/nuevo')"
      >
        <mat-icon class="kobra-icon">add</mat-icon>
        <span>Nuevo Agente Interno</span>
      </button>
      <button mat-menu-item 
        *ngIf="sessionData.isSuperAdmin"
        [disabled]="itemsSelected.length == 0"
        (click)="openDialogDesvinculacion()"
        >
        <mat-icon class="kobra-icon">link_off</mat-icon>
        <span>Desvincular productos </span>
      </button>
    </mat-menu> 
    
    <app-grid 
      [loading]="isLoadAgentes"
      [columns]="tablaAgentes.columns"
      [items]="agents"
      [headers]="tablaAgentes.headers"
      [multiSelect]="tablaAgentes.multiSelect"
      (onSelectedRows)="onSelected($event)"
      (sortChange)="setSortField($event)"
      (onLinkRow)="onClick($event)">

      <div t-header>
             
      <mat-card *ngIf="ayuda" class="cardAyuda">
        <mat-card-content>
          <p><mat-icon color="primary">help</mat-icon>
            El módulo "Agentes" es una sección integral del sistema, diseñada para la gestión completa de todos los agentes registrados en la red.
            En este módulo, los usuarios tienen la capacidad de visualizar a todos los agentes, ofreciendo una perspectiva clara y completa de la fuerza de trabajo disponible.
            Proporciona acceso a detalles exhaustivos de cada agente, incluyendo sus referencias, historial y otra información relevante.
            Aquí, no solo se puede observar quiénes son los agentes activos, sino que también se facilita la actualización de su estatus.
            Además, la vista permite a los usuarios editar información personal de los agentes, dar de baja a los agentes, etc.
          </p>
          Acciones:
          <p>
            <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda y el acceso a las agentes ya existentes.
          </p>
          <p>
            <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
          </p>
          <p>
            <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar los datos de los agentes para su análisis o uso fuera de línea.
          </p>
          <p>
            <mat-icon class="kobra-icon">swap_vert</mat-icon><b> Estatus: </b> Puedes cambiar el estatus de un agentes o varios.
          </p>
          <p>
            <mat-icon class="kobra-icon">link</mat-icon><b> Vincular productos: </b> Te permite vincular a los agentes a los diferentes productos de nuestros clientes.
          </p>
          <p>
            <mat-icon class="kobra-icon">link_off</mat-icon><b> Desvincular productos: </b> Podrás desvincular de manera masiva a los agentes de los productos del cliente que selecciones.
          </p>
           <p  *ngIf="validarMenu('/agentes/nuevo')">
            <mat-icon class="kobra-icon">add</mat-icon><b> Nuevo Agente Interno: </b> Te permite dar de alta un nuevo agente para la Red Interna.
          </p>
        </mat-card-content>
      </mat-card>
      </div> 
  
      <ng-container t-paginator>
        <mat-paginator
          showFirstLastButtons
          [length]="paginator.pagination?.totalCount"
          [pageIndex]="paginator.pagination?.currentPage-1"
          [pageSize]="paginator.pagination.perPage"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-grid>

    <mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
      <mat-toolbar>
        <button class="filter-button-side" mat-flat-button
          matTooltip="Ocultar filtros"
          mat-tooltip-panel-above
          (click)="filtrosSideNav.toggle()"
        >
          <mat-icon>cancel</mat-icon>
        </button> 
        Filtros
      </mat-toolbar>
      <div class="accordion-filtros">
        <mat-accordion [multi]="false">
          <mat-expansion-panel *ngIf="sessionData.isSuperAdmin" [expanded]="true">
            <mat-expansion-panel-header>
              Súper Administrador
              <span *ngIf="contabilizadorDeFiltros('superAdmin') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('superAdmin') }}</span>
            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Cliente'"
                  [placeHolder]="'Selecciona un cliente'"
                  [value]="'idCliente'"
                  [labelField]="'nombre'"
                  [icon]="'account_balance'"
                  [options]="lenders"
                  [(ngModel)]="filtros.idCliente"
                  (ngModelChange)="loadProducts(); this.contabilizadorDeFiltros('superAdmin');"
                >
                </mat-select-search>
              </li>
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="!sessionData.isSuperAdmin">
            <mat-expansion-panel-header>
              Generales
              <span *ngIf="contabilizadorDeFiltros('generales') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('generales') }}</span>
            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Visibilidad</mat-label>
                  <mat-select
                    [(ngModel)]="filtros.visibilidad"
                    (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                    placeholder="Seleccione..."
                  >
                    <mat-option [value]="0">Red Kobra</mat-option>
                    <mat-option [value]="1">Red Interna</mat-option>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.visibilidad != null" 
                  (click)="filtros.visibilidad = null;$event.stopPropagation();">clear</mat-icon>
                  <mat-icon matSuffix>visibility</mat-icon>
                </mat-form-field>
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Buro laboral</mat-label>
                  <mat-select [(ngModel)]="filtros.buroLaboral" (selectionChange)="this.contabilizadorDeFiltros('generales');"
                  placeholder="Seleccione...">
                    <mat-option [value]="true">Sí</mat-option>
                    <mat-option [value]="false">No</mat-option>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar-solo"
                  *ngIf="filtros.buroLaboral != null" 
                  (click)="filtros.buroLaboral = null;$event.stopPropagation();">clear</mat-icon>

                </mat-form-field>
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Primer contacto</mat-label>
                  <mat-select [(ngModel)]="filtros.primerContacto" (selectionChange)="this.contabilizadorDeFiltros('generales');"
                  placeholder="Seleccione...">
                    <mat-option [value]="true">Sí</mat-option>
                    <mat-option [value]="false">No</mat-option>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar-solo"
                  *ngIf="filtros.primerContacto != null" 
                  (click)="filtros.primerContacto = null;$event.stopPropagation();">clear</mat-icon>
                </mat-form-field>
              </li>
              <li class="filtro-side">
                <mat-form-field style="width: 100%;">
                  <mat-label>Activo</mat-label>
                  <mat-select [(ngModel)]="filtros.disponible" (selectionChange)="this.contabilizadorDeFiltros('generales');"
                  placeholder="Seleccione...">
                    <mat-option [value]="true">Sí</mat-option>
                    <mat-option [value]="false">No</mat-option>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar-solo"
                  *ngIf="filtros.disponible != null" 
                  (click)="filtros.disponible = null;$event.stopPropagation();">clear</mat-icon>
                </mat-form-field>
              </li>
              <li class="filtro-side" *ngIf="!sessionData.isSuperAdmin">
                <mat-form-field style="width: 100%;">
                  <mat-label>Sólo Agentes Vinculados</mat-label>
                  <mat-select [(ngModel)]="filtros.vinculados" (selectionChange)="this.contabilizadorDeFiltros('generalesAdmin');"
                  placeholder="Seleccione...">
                    <mat-option [value]="true">Sí</mat-option>
                    <mat-option [value]="false">No</mat-option>
                  </mat-select>
                  <mat-icon matSuffix class="icono-limpiar-solo"
                  *ngIf="filtros.vinculados != null" 
                  (click)="filtros.vinculados = null;$event.stopPropagation();">clear</mat-icon>
                </mat-form-field>
              </li>
              
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Ubicación
              <span *ngIf="this.contabilizadorDeFiltros('ubicacion') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('ubicacion') }}</span>
            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="labelEstado"
                  [placeHolder]="'Seleccione...'"
                  [value]="'idEstado'"
                  [labelField]="'nombre'"
                  [icon]="'location_city'"
                  [options]="estados"
                  [(ngModel)]="filtros.idEstado"
                  (ngModelChange)="loadMunicipios();"
                >
                </mat-select-search>
                
              </li>
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="this.loadings.municipios ? 'Cargando municipios...' : labelMunicipio"
                  [placeHolder]="'Seleccione...'"
                  [value]="'idMunicipio'"
                  [labelField]="'nombre'"
                  [icon]="'location_city'"
                  [options]="municipios"
                  [(ngModel)]="filtros.idMunicipio"
                  (ngModelChange)="this.contabilizadorDeFiltros('ubicacion');"
                >
                </mat-select-search>
                
              </li>
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Fechas
              <span *ngIf="this.contabilizadorDeFiltros('fechas') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechas') }}</span>
            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-accordion [multi]="false">
                  <mat-expansion-panel  [expanded]="true">
                    <mat-expansion-panel-header>
                      Fechas registro
                      <span *ngIf="this.contabilizadorDeFiltros('fechas') >= 1"class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechas') }}</span>
                    </mat-expansion-panel-header>
                    <ol class="lista-filtros-side">
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha inicio'"
                          [placeHolder]="'Capture la fecha inicio'"
                          [max]="filtros.fecFin ? filtros.fecFin : today"
                          [(ngModel)]="filtros.fecInicio"
                          (ngModelChange)="cambioInicio($event);"
                        />
                       
                      </li>
                      <li class="filtro-side">
                        <mat-date-picker-formater
                          [label]="'Fecha fin'"
                          [placeHolder]="'Capture la fecha fin'"
                          [min]="filtros.fecInicio"
                          [max]="today"
                          [(ngModel)]="filtros.fecFin"
                          (ngModelChange)="cambioFin($event);"
                        />
                       
                      </li>
                    </ol>
                  </mat-expansion-panel>
                </mat-accordion>
              </li>
            </ol>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              Agente
              <span *ngIf="this.contabilizadorDeFiltros('agente') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('agente') }}</span>
            </mat-expansion-panel-header>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Agente'"
                  [placeHolder]="'Busca por id o nombre'"
                  [value]="'option'"
                  [labelField]="'nombre'"
                  [icon]="'account_circle'"
                  [realizarBusqueda]="agenteFilterSearch"
                  [(ngModel)]="filtros.idAgentePorNombre"
                  (ngModelChange)="onCobradorSelectionChange($event)"
                  [minBusqueda]="1"
                >
                </mat-select-search>
               
              </li>
              
            </ol>
            <ol class="lista-filtros-side">
              <li class="filtro-side">
                <mat-select-search
                  class="id-card-field"
                  [label]="'Correo'"
                  [placeHolder]="'Busca por correo'"
                  [value]="'option'"
                  [labelField]="'correo'"
                  [icon]="'account_circle'"
                  [realizarBusqueda]="buscarPorCorreoAgente"
                  [(ngModel)]="filtros.idAgentePorCorreo"
                  (ngModelChange)="onCobradorCorreoSelectionChange($event)"
                  [minBusqueda]="3"
                >
                </mat-select-search>
               
              </li>
            </ol>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <ol class="lista-filtros-buttons">
        <button
        class="boton-accion-front"
          style="width: 100%; "
          mat-stroked-button
          (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; setFilter(); ">
            <mat-icon>filter_alt</mat-icon>
            Filtrar
        </button>
        <button
          style="width: 100%; margin-top: 10px;"
          mat-stroked-button
          (click)="clearFilter()">
            <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
            Limpiar Filtros
        </button>
      </ol>
    </mat-sidenav>
</div>
  