<div class="app content">
    <mat-card>
        <mat-card-content class="kobra-card-content">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Criterios de Visita</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="kobra three columns">
                        <div class="kobra column">
                            <mat-select-search
                                [label]="'Cliente'"
                                [placeHolder]="'Selecciona un cliente'"
                                [value]="'idCliente'"
                                [labelField]="'nombreCliente'"
                                [icon]="'account_balance'"
                                [options]="clientes"
                                [disabled]="loadings.clientes || editarPresente"
                                [(ngModel)]="idCliente"
                                (ngModelChange)="onChangeLender($event);"
                                [loader]="loadings.clientes"
                            >
                            </mat-select-search>
                        </div>
                        <div class="kobra column">
                            <mat-select-search
                            [label]="'Producto'"
                            [placeHolder]="'Selecciona un producto'"
                            [value]="'idProducto'"
                            [labelField]="'nombreProducto'"
                            [options]="productos"
                            [disabled]="loadings.productos || editarPresente"
                            [(ngModel)]="idProducto"
                            (ngModelChange)="onChangeProducto($event)"
                            [icon]="'conveyor_belt'"
                            [loader]="loadings.productos"
                          >
                          </mat-select-search>
                                                 
                        </div>
                    </div>
                    <div class="kobra three columns">
                        <div class="kobra column">
                            <mat-form-field class="criterio-field">
                                <mat-label>Criterio de la visita</mat-label>
                                <input
                                    textSanitizer
                                    matInput
                                    [(ngModel)]="nuevoCriterio.nombre"
                                    maxlength="100"
                                    placeholder="Capture el criterio de la visita"
                                />
                                <mat-icon matSuffix  class="icono-limpiar"
                                *ngIf="nuevoCriterio.nombre" 
                                (click)="nuevoCriterio.nombre = null;">clear</mat-icon>
                                <mat-icon matSuffix>assignment</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="kobra column">
                            <mat-form-field class="criterio-field">
                                <mat-label>Ponderación</mat-label>
                                <input
                                    textSanitizer
                                    matInput
                                    [(ngModel)]="nuevoCriterio.ponderacion"
                                    decimalValidator
                                    [maxValue]="100"
                                    placeholder="Capture la ponderación"
                                />
                                <mat-icon matSuffix  class="icono-limpiar"
                                *ngIf="nuevoCriterio.ponderacion" 
                                (click)="nuevoCriterio.ponderacion = null;">clear</mat-icon>
                                <mat-icon matSuffix>percent</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="kobra column">
                            <div class="action-buttons" align="left">
                                <button
                                    mat-stroked-button
                                    class="boton-accion-front"
                                    (click)="agregarCriterio()"
                                    
                                >
                                    <mat-icon>add</mat-icon>
                                    Agregar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4>Porcentaje total: {{ totalPonderaciones }}%</h4>
                        <mat-divider></mat-divider>
                        <div style="margin: 10px 0">
                            <!-- [loading]="loadings.criterios" -->
                            <app-table
                                
                                [columns]="tablaCriterios.columnas"
                                [items]="criterios"
                                [headers]="tablaCriterios.headers"
                                (onLinkRow)="eliminarCriterio($event)"
                                [tableHeaders]="tablaCriterios.tableHeaders"
                                [ignoreSortChange]="true"
                                [multiSelect]="tablaCriterios.multiSelect"
                            ></app-table>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <div class="button-center">
                <button
                    class="boton-cancelar"
                    mat-flat-button
                    type="submit"
                    style="margin-right: 10px;"
                    (click)="cancelar()"
                >
                    <mat-icon class="kobra-icon">close</mat-icon> Cancelar
                </button>
                <button
                    mat-stroked-button
                    class="kobra-button"
                    type="submit"
                    (click)="guardar()"
                >
                    <mat-icon>save</mat-icon> Guardar
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
