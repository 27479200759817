import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { environmentSelector } from '../../../../environments/environment.selector';
import { Subscription, Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { ClientesEtiquetasVisitasService } from '../clientes-etiquetas-visitas.service';
import { Lender } from '@lender/lender.model';
import { LenderService } from '@lender/lender.service';
import { FormControl } from '@angular/forms';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';
import { saveAs } from 'file-saver';
import { LoadingService } from '../../../shared/components/loading-bar/loading-bar.service';
import moment from 'moment';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ModalEstatusComponent } from '../../etiquetas-visitas/modales/modal-estatus/modal-estatus.component';
import { ItemSeleccionado } from '../interfaces/item-seleccionado';


@Component({
  selector: 'app-clientes-etiquetas-visitas',
  templateUrl: './clientes-etiquetas-visitas.component.html',
  styleUrls: ['./clientes-etiquetas-visitas.component.css'],
  providers: [
    LenderService
  ]
})
export class ClientesEtiquetasVisitasComponent implements OnInit, OnDestroy {
  private environment = environmentSelector();

  private querySub: Subscription;
  sessionData: SessionData;
  hijosActivos: boolean = false;
  filtrosAbiertos: boolean = false;
  ayuda: boolean = false;
  etiquetas$: Observable<any>;
  loaderText: string = '';
  idLender: number;
  lenders: Lender[];
  products: Array<any>;
  etiquetas: Array<any>;
  loadingProducts: boolean = false;
  queryParamsGlobal: string;
  idCliente:number;
  etiquetaVisita:string = '';
  idProducto:number;
  estatus:any;
  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;
  itemsSelected: Array<ItemSeleccionado> = [];

  filter = false;

  filters = {
    idCliente: null,
    idProducto: null,
    etiquetaVisita: null,
    estatus: null,
    page: 1,
    sortField: 'activo desc, etiquetaVisita',
    sortAsc: ''
  }

  tabla: any = {
    headers: false,
    multiSelect: true,
    columns: [
        {
          name: 'Cliente',
          key: 'cliente',
          hide: false
        },
        {
            name: 'Producto',
            key: 'producto',
            hide: false
        },
        {
          name: 'Etiqueta Visita',
          key: 'etiquetaVisita',
          hide: false
        },
        {
          name: 'Estatus',
          type: 'estatus',
          key: 'activo',
          hide: false
        }
    ]
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private app: ApplicationService,
    private localStorageService: LocalStorageService,
    public clientesEtiquetasVisitasService: ClientesEtiquetasVisitasService,
    private lenderService: LenderService,
    private loadingService: LoadingService,
    private http: HttpClient,
    private dialog: MatDialog

  ) { 
    this.sessionData = this.localStorageService.getSessionData();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();
  }

  ngOnInit() {
    this.loadLenders();
    this.clientesEtiquetasVisitasService.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
  }

  ngOnDestroy() {
  }


  private agregarContabilizadoresDeFiltros(){
   
    this.contabilizadorFiltros.add('Generales', 'generales', [
        'idCliente',
        'idProducto',
        'etiquetaVisita',
        'estatus',
       
    ]);

}

public contabilizadorDeFiltros(filtroName: string){
  return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filters);
}


public contabilizadorDeFiltrosTotales():void{

    let totalFiltrosArray: number[] = [];
   
    totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );


    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}


  resultadoFiltros(filtrosNuevos: any) {
    if(filtrosNuevos){
      this.filter = true;
      this.filters = filtrosNuevos;
      if(this.filters.idCliente){
        this.loadProducts();
      }

      if(this.filters.idCliente){
        this.idCliente = this.filters.idCliente;
      }
  
      if(this.filters.idProducto){
        this.idProducto = this.filters.idProducto;
      }
  
      if(this.filters.etiquetaVisita){
        this.etiquetaVisita = this.filters.etiquetaVisita;
      }
  
      if(this.filters.estatus){
        this.estatus = this.filters.estatus;
      }

    }
    this.clientesEtiquetasVisitasService.obtenerEtiquetas(this.filters.page || 1,
      this.filters);
  }

  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !== this.clientesEtiquetasVisitasService.pagination.page || newPagina.pageSize !== this.clientesEtiquetasVisitasService.pagination.pageSize){
      this.clientesEtiquetasVisitasService.pagination.pageSize = newPagina.pageSize;
      this.clientesEtiquetasVisitasService.obtenerEtiquetas(newPagina.pageIndex+1,
        this.filters);
    }
  }

  onAdd() {
    this.router.navigate([`catalogos/clientes-etiquetas-visitas/form`], {
      queryParamsHandling: 'preserve'
    });
  }

  public onSelected(rows) {
    this.itemsSelected = rows.slice();
  }

  setSortField(sort: any){
    this.filters.sortAsc = sort.direction;
    if (sort.active != this.filters.sortField) {
      this.filters.sortField = sort.active;
    }
    this.clientesEtiquetasVisitasService.obtenerEtiquetas(1, this.filters);
  }

  private compare(a, b) {
    const nombreA = a.nombre.toUpperCase();
    const nombreB = b.nombre.toUpperCase();

    let comparison = 0;
    if (nombreA > nombreB) {
      comparison = 1;
    } else if (nombreA < nombreB) {
      comparison = -1;
    }
    return comparison;
  }

  loadLenders(): void {
    this.lenderService.getAll().subscribe(
      (data: any) => {
        data.sort(this.compare);
        this.lenders = data;
      },
      (err: any) => {
        this.app.showError(err);
      }
    );
  }

  loadClientesEtiquetasVisita() {
    this.etiquetas = [];
    this.clientesEtiquetasVisitasService.obtenerGlobalEtiquetas()
      .pipe(
        take(1),
        map((res: any) => {
          return res;
        })
      ).subscribe((res: any) => {
        this.etiquetas = res.data;
      }, (err: any) => {
        this.app.showError(err);
      });
  }

  loadProducts() {
    this.products = [];
    this.loadingProducts = true;

    if (this.filters.idCliente !== null) {
      this.lenderService.obtenerProductosCliente(this.filters.idCliente)
        .pipe(
          take(1),
          map((res: any) => {
            return res;
          })
        ).subscribe((res: any) => {
          this.loadingProducts = false;
          this.products = res.data;
        }, (err: any) => {
          this.loadingProducts = false;
          this.app.showError(err);
        });
    }
  }

  public descargarListado(): void {
    this.loadingService.setLoading(true);  // Asegúrate de que loadingService esté inyectado en tu componente

    let queryParams = `?token=${localStorage.getItem('access-token')}`;

    if (this.filters.idCliente  != null) {
      queryParams += `&idCliente=${encodeURIComponent(this.filters.idCliente)}`;
    }

    if (this.filters.idProducto  != null) {
      queryParams += `&idProducto=${encodeURIComponent(this.filters.idProducto)}`;
    }

    if (this.filters.estatus != null) {
      queryParams += `&activo=${encodeURIComponent(this.filters.estatus)}`;
    }

    this.lenderService.descargarEtiquetasVisitas(queryParams).subscribe(event => {
        if (event.type === HttpEventType.Response) {
            this.loadingService.setLoading(false);
            const blob = new Blob([event.body], { type: event.body.type });
            const fechaActual = moment().format('YYYYMMDD'); // Formato de fecha, ajustable según necesidad
            saveAs(blob, `Clientes-etiquetas-visitas-${fechaActual}`);
        }
    }, error => {
        this.loadingService.setLoading(false);
        this.app.showSnackbar(
            "Aviso",
            "Ocurrió un problema al intentar descargar el archivo.",
            3000,
            "error"
        );
        console.log(error);
    });
}

  limpiarFiltros(){
    this.filter = false;
    const excludeKeys = ['page', 'sortField', 'sortAsc'];

    Object.keys(this.filters).forEach(key => {
      if (!excludeKeys.includes(key)) {
        this.filters[key] = null;
      }
    });
    
    this.filters.page = 1;
    this.clientesEtiquetasVisitasService.filtrosService.removerFiltros();
    //this.clientesEtiquetasVisitasService.obtenerEtiquetas(1, this.filters);
  }

  filtrar(){
    Object.keys(this.filters).forEach(key => {
      if(key != 'page' && this.filters[key]){
        this.filter = true;
      }
    });

    if (this.filter) {
      
      if(this.filters.idCliente !== null){
        this.idCliente = this.filters.idCliente;
      }
  
      if(this.filters.idProducto !== null){
        this.idProducto = this.filters.idProducto;
      }
  
      if(this.filters.estatus !== null){
        this.estatus = this.filters.estatus;
      }
      
      this.clientesEtiquetasVisitasService.filtrosService.guardarFiltros(this.filters);
      this.filters.page = 1
      this.clientesEtiquetasVisitasService.obtenerEtiquetas(1, this.filters);
    }
    this.contabilizadorDeFiltrosTotales();

  }

  inactivarHijo(){
    this.clientesEtiquetasVisitasService.filtrosService.asignarOpcion();
    if(this.filter){
      this.clientesEtiquetasVisitasService.filtrosService.guardarFiltros(this.filters, true);
    }
    this.clientesEtiquetasVisitasService.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
  }
  
  public openModalEstatus(accion: boolean) {

    const dialogRef = this.dialog.open(ModalEstatusComponent, {
      width: '800px',
      data: { 
        itemsSelected: this.itemsSelected,
        accion : accion,
        opcion: 2
       }
    });
    dialogRef.afterClosed().subscribe(result => {
       if(result){
        this.ngOnInit();
       }
    });
  }

  public validarSeleccion(activar: boolean): boolean {
    if (this.itemsSelected.length === 0) {
      return true; // Deshabilitar si no hay elementos seleccionados
    }
  
    // Verificar si hay al menos un elemento que cumple la condición de ser activo o inactivo
    const hayActivo = this.itemsSelected.some(item => item.activo === true);
    const hayInactivo = this.itemsSelected.some(item => item.activo === false);
  
    // Para activar botón "Activar": si hay inactivos, habilitar
    if (activar && hayInactivo) {
      return false;
    }
  
    // Para activar botón "Inactivar": si hay activos, habilitar
    if (!activar && hayActivo) {
      return false;
    }
  
    // Si no cumple ninguna condición, deshabilitar el botón
    return true;
  }

  
}
