import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { slideInAnimation } from './shared/components/transacciones/router-animations';

/***COMPONENTES ***/
import { AuthComponent } from './authentication/auth.component';
import { AuthService } from './authentication/auth.service';
import { ValidacionTokenComponent } from './validacion-token/validacion-token.component';
import { LayoutComponent } from "./layout/layout.component";
import { LenderListComponent } from './lender/list/lender-list.component';
import { ProductosNvoListComponent } from './catalogos/productos/lista/productos-nvo-list.component';
import { ProductosNvoEditarComponent } from './catalogos/productos/editar/productos-editar.component';
import { ConceptosProductosComponent } from './catalogos/conceptos-productos/lista/conceptos-productos-list.component';
import { ConceptosProductosNvoComponent } from './catalogos/conceptos-productos/nuevo/conceptos-productos.component';
import { SucursalesComponent } from './catalogos/sucursales/list/sucursales-list.component';
import { SucursalesModificarComponent } from './catalogos/sucursales/modificar/sucursales-modificar.component';
import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import { ChecklistsComponent } from './catalogos/checklists/lista/checklists.component';
import { ChecklistsEditarComponent } from './catalogos/checklists/edicion/checklists-editar.component';
import { ConfigCuestionarioComponent } from './catalogos/checklists/modales/configuracion/config-cuestionario.component';
import { ChecklistsFormatoComponent } from './catalogos/checklists/formato/checklists-formato.component';
import { FormatoCuentaComponent } from './catalogos/checklists/formato-cuenta/formato-cuenta.component';
import { UsuariosComponent } from './catalogos/usuarios/usuarios.component';
import { UsuariosFormComponent } from './catalogos/usuarios/usuarios-form/usuarios-form.component';
import { AdmistradorSucursalesModificarComponent } from './catalogos/usuarios/sucursales/sucursales-modificar.component';
import { AdmistradorProductosModificarComponent } from './catalogos/usuarios/productos/productos-modificar.component';
import { CatalogoEtiquetasVisitasComponent } from './catalogos/etiquetas-visitas/lista/etiquetas-visitas.component';
import { EtiquetasVisitasFormComponent } from './catalogos/etiquetas-visitas/etiquetas-visitas-form/etiquetas-visitas-form.component';
import { CalificadasVisitasComponent } from './calificadas-visitas/calificadas-visitas.component';
import { HomeComponent as ImportHomeComponent } from './import/home/home.component';
import { ProductComponent as ImportProductComponent } from './import/product/product.component';
import { WizardComponent as ImportWizardComponent } from './import/wizard/wizard.component';
import { FinishComponent as ImportFinishComponent } from './import/finish/finish.component';
import { AccountListComponent } from './account/account-list.component';
import { AccountDetailComponent } from './account/account-detail.component';
import { ClientesMovimientosComponent } from './saldos/clientes-movimientos/clientes-movimientos.component';
import { ClientesMovimientosFormComponent } from './saldos/clientes-movimientos/clientes-movimientos-form/clientes-movimientos-form.component';
import { CobradoresMovimientosComponent } from './saldos/cobradores-movimientos/cobradores-movimientos.component';
import { CobradoresMovimientosFormComponent } from './saldos/cobradores-movimientos/cobradores-movimientos-form/cobradores-movimientos-form.component';
import { ProductosComponent } from './catalogos/permisos-producto/lista/productos-list.component';
import { ProductosModificarComponent } from './catalogos/permisos-producto/editar/productos-modificar.component';
import { ListaTarifaComponent } from './catalogos/tarifas-agentes/lista-tarifa/lista-tarifa.component';
import { TarifaProductosComponent } from './catalogos/tarifas-agentes/editar-tarifa/editar-tarifa.component';
import { PaquetesSaldosComponent } from './catalogos/paquetes-saldos/paquetes-saldos.component';
import { PaquetesSaldosConceptosComponent } from './catalogos/paquetes-saldos-conceptos/paquetes-saldos-conceptos.component';
import { ConceptosSaldosComponent } from './saldos/conceptos-saldos/conceptos-saldos.component';
import { ConceptosSaldosFormComponent } from './saldos/conceptos-saldos/conceptos-saldos-form/conceptos-saldos-form.component';
import { CobradoresFacturacionesPeriodosComponent } from './saldos/cobradores-facturaciones-periodos/cobradores-facturaciones-periodos.component';
import { CobradoresFacturacionesPeriodosFormComponent } from './saldos/cobradores-facturaciones-periodos/cobradores-facturaciones-periodos-form/cobradores-facturaciones-periodos-form.component';
import { PaquetesFinancieraComponent } from './saldos/paquetes-financiera/paquetes-financiera.component';
import { LenderDetailComponent } from './lender/detail/detail.component';
import { PaquetesFinancieraDetalleComponent } from './saldos/paquetes-financiera/paquetes-financiera-detalle/paquetes-financiera-detalle.component';
import { VisitListComponent } from "./visit/visit-list.component";
import { VisitDetailComponent } from "./visit/visit-detail.component";
import { ClientesEtiquetasVisitasComponent } from "./catalogos/clientes-etiquetas-visitas/lista/clientes-etiquetas-visitas.component";
import { ClientesEtiquetasVisitasFormComponent } from "./catalogos/clientes-etiquetas-visitas/form/clientes-etiquetas-visitas-form.component";
import { DescargasComponent } from './descargas/descargas.component';
import { AgentListComponent } from "./agent/agent-list/agent-list.component";
import { AgentDetailComponent } from './agent/agent-detail/agent-detail.component';
import { IndicatorsComponent } from './indicators/indicators.component';
import { GeoCercaCrearComponent } from './geo-cercas/geo-cerca-create/geo-cerca-create.component';
import { LenderNewComponent } from 'src/app/lender/new/new.component';
import { CalificarObligatoriosComponent } from './calificar-obligatorios/calificar-obligatorios.component';
import { AgenteNuevoComponent } from '@agent/agente-nuevo/agente-nuevo.component';
import { VisitCreateComponent } from './visit-create/visit-create.component';
import { AgenteRecorridoComponent } from './agent/agente-recorridos/agente-recorrido.component';
import { PromesasPagoComponent } from './promesas-pago/promesas-pago.component';
import { InformacionRecopiladaComponent } from "./visit/informacion-recopilada/informacion-recopilada.component";
import { RevisitListComponent } from "./visit/revisita/revisita-lista.component";
import { RequestListComponent } from './credit-request/request-list.component';
import { UserEditComponent } from './user/edit/user-edit.component';
import { EncargadosFinancieraComponent } from './lender/encargados-financiera/encargados-financiera.component';
import { ListaVisibilidadComponent } from './agent/visibilidad-cuentas/lista/lista-visibilidad.component';
import { ConceptosEtiquetasVisitasComponent } from './saldos/conceptos-etiquetas-visitas/conceptos-etiquetas-visitas.component';
import { ConceptosEtiquetasVisitasFormComponent } from './saldos/conceptos-etiquetas-visitas/conceptos-etiquetas-visitas-form/conceptos-etiquetas-visitas-form.component';
import { CreditRequestComponent } from './credit-request/credit-request.component';
import { CobradoresFacturacionesComponent } from './saldos/cobradores-facturaciones/cobradores-facturaciones.component';
import { ConfigurationComponent } from '@lender/configuration/configuration.component';
import { PaquetesClientesConfigurationComponent } from './saldos/paquetes-financiera/configuration/configuration.component';
import { CuentasAgrupadasListComponent } from './cuentas/cuentas-agrupadas/cuentas-agrupadas-list.component';
import { LayoutFieldsComponent } from 'src/app/lender/layout-fields/layout-fields.component';
import { LayoutFieldNuevo } from '@lender/layout-fields/nuevo/layout-field-nuevo.component';
import { ListadoBloquesComponent } from './catalogos/checklists/formato/bloques/lista/listado-bloques.component';
import { BloqueNuevoComponent } from './catalogos/checklists/formato/bloques/nuevo/bloque-nuevo.component';
import { CriteriosCalidadComponent } from './visitas-criterios/criterios-calidad/criterios-calidad.component';
import { CriteriosCalidadNuevoComponent } from './visitas-criterios/criterios-calidad-nuevo/criterios-calidad-nuevo.component';


const routes: Routes = [
  { path: 'auth', component: AuthComponent },
  { path: 'login', component: ValidacionTokenComponent },
  {
    path: '', component: LayoutComponent, canActivate: [AuthService],
    children: [
      {
        path: '', component: DashboardMainComponent, children: [
        ]
      },
      {
        path: 'geocercas', component: GeoCercaCrearComponent, data: { breadcrumb: 'Geocercas' }, children: [
          /*{ path: 'nuevo', component: ProductosNvoEditarComponent, data:{breadcrumb: 'Nuevo'} },
          { path: 'editar', component: ProductosNvoEditarComponent, data:{breadcrumb: 'Editar'} }*/
        ]
      },
      {
        path: 'clientes', component: LenderListComponent, data: { breadcrumb: 'Clientes' }, children: [
          { path: 'nuevo', component: LenderNewComponent, data: { breadcrumb: 'Nuevo cliente' } },
          { path: 'configuracion', component: ConfigurationComponent, data:{breadcrumb: 'Configuraciones'} },
          { path: 'encargados', component: EncargadosFinancieraComponent, data:{breadcrumb: 'Encargados'} },
        ]
      },
      {
        path: 'catalogos/productos', component: ProductosNvoListComponent, data: { breadcrumb: 'Productos' }, children: [
          { path: 'nuevo', component: ProductosNvoEditarComponent, data: { breadcrumb: 'Nuevo' } },
          { path: 'editar', component: ProductosNvoEditarComponent, data: { breadcrumb: 'Editar: :nombreCliente/:nombreProducto' } }
        ]
      },
      {
        path: 'catalogos/conceptos-productos', component: ConceptosProductosComponent, data: { breadcrumb: 'Conceptos de productos' }, children: [
          { path: 'nuevo', component: ConceptosProductosNvoComponent, data: { breadcrumb: 'Nuevo' } }
        ]
      },
      {
         path: 'catalogos/conceptos-etiquetas-visitas', component: ConceptosEtiquetasVisitasComponent, data: { breadcrumb: 'Conceptos de etiquetas visitas' }, children: [
          { path: 'nuevo', component: ConceptosEtiquetasVisitasFormComponent, data: { breadcrumb: 'Nuevo' } }
        ]
      },
      {
        path: 'catalogos/sucursales', component: SucursalesComponent, data: { breadcrumb: 'Sucursales' }, children: [
          { path: 'nuevo', component: SucursalesModificarComponent, data: { breadcrumb: 'Nuevo' } },
          { path: 'editar', component: SucursalesModificarComponent, data: { breadcrumb: 'Editar: :nombreCliente/:nombreSucursal' } }
        ]
      },
      {
        path: 'catalogos/criterios-calidad', component: CriteriosCalidadComponent, data: { breadcrumb: 'Criterios de Calidad' }, children: [
          { path: 'nuevo', component: CriteriosCalidadNuevoComponent, data: { breadcrumb: 'Nuevo' } },
          { path: 'editar', component: CriteriosCalidadNuevoComponent, data: { breadcrumb: 'Editar: :nombreCliente/:nombreProducto' } }
        ]
      },
      {
        path: 'catalogos/tarifas-agentes/lista-tarifa', component: ListaTarifaComponent, data: { breadcrumb: 'Tarifas Agentes' }, children: [
           { path: 'editar', component: TarifaProductosComponent, data: { breadcrumb: 'Editar' } }
        ]
      },
      {
        path: 'catalogos/checklists', 
        component: ChecklistsComponent, 
        data: { breadcrumb: 'Cuestionarios' }, 
        children: [
          { 
            path: 'editar', 
            component: ChecklistsEditarComponent, 
            data: { breadcrumb: 'Editar: :nombreCliente/:nombreCheckList' } 
          },
          { 
            path: 'configuracion', 
            component: ConfigCuestionarioComponent, 
            data: { breadcrumb: 'Configuración: :nombreCliente/:nombreCheckList' } 
          },
          { 
            path: 'formato-visita', 
            component: ChecklistsFormatoComponent, 
            data: { breadcrumb: 'Plantillas visitas: :nombreCliente/:nombreCheckList' }, 
            children: [
              {
                path: 'bloques', 
                component: ListadoBloquesComponent, 
                data: { breadcrumb: 'Bloques' },
                children: [
                  {
                    path: 'nuevo', 
                    component: BloqueNuevoComponent, 
                    data: { breadcrumb: 'Nuevo' } 
                  },{
                    path: 'editar', 
                    component: BloqueNuevoComponent, 
                    data: { breadcrumb: 'Editar' } 
                  }
                ]
              }
            ]
          },
          { 
            path: 'formato-cuenta', 
            component: FormatoCuentaComponent, 
            data: { breadcrumb: 'Plantillas cuentas: :nombreCliente/:nombreCheckList' } 
          },
          { 
            path: 'layout', 
            component: LayoutFieldsComponent, 
            data: { breadcrumb: 'Layout: :nombreCliente/:nombreCheckList' },
            children: [
              {
                path: 'nuevo', 
                component: LayoutFieldNuevo, 
                data: { breadcrumb: 'Nuevo' } 
              }
            ]
          }
        ]
      }, {
        path: 'importacion', component: ImportHomeComponent, data: { breadcrumb: 'Importaciones' }, children: [
          { path: 'asistente/:idCliente/:idFinanciera', component: ImportWizardComponent, data: { breadcrumb: 'Proceso' } },
          { path: ':idImportacion', component: ImportFinishComponent, data: { breadcrumb: 'importación' } }
        ]
      },
      {
        path: 'catalogos/administradores', component: UsuariosComponent, data: { breadcrumb: 'Administradores' }, children: [
          { path: 'nuevo', component: UsuariosFormComponent, data: { breadcrumb: 'Nuevo' } },
          { path: 'editar', component: UsuariosFormComponent, data: { breadcrumb: 'Edición' } },
          { path: 'editar-sucursal', component: AdmistradorSucursalesModificarComponent, data: { breadcrumb: 'Edición: :nombreAdmin' } },
          { path: 'editar-producto', component: AdmistradorProductosModificarComponent, data: { breadcrumb: 'Edición: :nombreAdmin' } }
        ]
      },
      {
        path: 'catalogos/etiquetas-visitas', component: CatalogoEtiquetasVisitasComponent, data: { breadcrumb: 'Etiquetas de visitas' }, children: [
          { path: 'form', component: EtiquetasVisitasFormComponent, data: { breadcrumb: 'Nuevo' } }
        ]
      },
      {
        path: 'catalogos/clientes-etiquetas-visitas', component: ClientesEtiquetasVisitasComponent, data: { breadcrumb: 'Etiquetas de visitas clientes' }, children: [
          { path: 'form', component: ClientesEtiquetasVisitasFormComponent, data: { breadcrumb: 'Asignar' } }
        ]
      },
      /*{
        path: 'accounts', component: AccountListComponent, data: { breadcrumb: 'Cuentas' }, children: [
          //{ path: 'account/:id', component: AccountDetailComponent, data:{breadcrumb: 'Detalle'} },
          { path: 'account', component: AccountDetailComponent, data: { breadcrumb: 'Detalle', animation: 'routeAnimations' } }
        ]
      },*/
      {
        path: 'cuentas/domicilios', component: AccountListComponent, data: { breadcrumb: 'Cuentas - Domicilios' }, children: [
          { path: 'account', component: AccountDetailComponent, data: { breadcrumb: 'Detalle', animation: 'routeAnimations' } }
        ]
      },
      {
        path: 'cuentas/principales', component: CuentasAgrupadasListComponent, data: { breadcrumb: 'Cuentas - Principales' }, children: [
          { path: 'account', component: AccountDetailComponent, data: { breadcrumb: 'Detalle', animation: 'routeAnimations' } }
        ]
      },
      {
        path: 'clientes-movimientos', component: ClientesMovimientosComponent, data: { breadcrumb: 'Movimientos clientes' }, children: [
          {
            path: 'form', component: ClientesMovimientosFormComponent, data: { breadcrumb: 'Nuevo' }, children: [
              //que no se nos olvide descomentar esto por que a un no esta la opcion  agregada
              { path: 'conceptos', component: ConceptosSaldosFormComponent, data: { breadcrumb: 'Concepto' } }
            ]
          }
        ]
      },
      {
        path: 'gestores-movimientos', component: CobradoresMovimientosComponent, data: { breadcrumb: 'Movimientos Agentes' }, children: [
          {
            path: 'form', component: CobradoresMovimientosFormComponent, data: { breadcrumb: 'Nuevo' },
            children: [
              { path: 'periodos', component: CobradoresFacturacionesPeriodosFormComponent, data: { breadcrumb: 'Periodo' } },
              { path: 'conceptos', component: ConceptosSaldosFormComponent, data: { breadcrumb: 'Concepto' } }
            ]
          }
        ]
      },
      {
        path: 'catalogos/permisos-producto', component: ProductosComponent, data: { breadcrumb: 'Permisos por producto' }, children: [
          {
            path: 'editar', component: ProductosModificarComponent, data: { breadcrumb: 'Editar: :nombreCliente/:nombreProducto' }
          }
        ]
      },
      {
        path: 'catalogos/paquetes', component: PaquetesSaldosComponent, data: { breadcrumb: 'Paquetes' }, children: [
          { path: 'nuevo', component: PaquetesSaldosConceptosComponent, data: { breadcrumb: 'Nuevo' } },
          { path: 'editar', component: PaquetesSaldosConceptosComponent, data: { breadcrumb: 'editar' } }
        ]
      },
      {
        path: 'conceptos', component: ConceptosSaldosComponent, data: { breadcrumb: 'Conceptos' }, children: [
          { path: 'nuevo', component: ConceptosSaldosFormComponent, data: { breadcrumb: 'Nuevo' } },
          { path: 'editar', component: ConceptosSaldosFormComponent, data: { breadcrumb: 'Editar' } },
        ]
      },
      {
        path: 'cobradores-facturaciones-periodos', component: CobradoresFacturacionesPeriodosComponent, data: { breadcrumb: 'Periodos de facturación' }, children: [
          { path: 'nuevo', component: CobradoresFacturacionesPeriodosFormComponent, data: { breadcrumb: 'Nuevo' } },
          { path: 'editar', component: CobradoresFacturacionesPeriodosFormComponent, data: { breadcrumb: 'Editar' } }
        ]
      },
      {
        path: 'paquetes-cliente',
        component: PaquetesFinancieraComponent,
        data: { breadcrumb: 'Paquetes cliente' },
        children: [
          { 
            path: 'configuracion',  // Ruta estática primero
            component: PaquetesClientesConfigurationComponent, 
            data: { breadcrumb: 'Configuraciones' } 
          },
          {
            path: ':idFinanciera',
            component: LenderDetailComponent,
            data: { breadcrumb: 'Editar' },
            children: [
              {
                path: 'paquetes/:idFinancieraPaquete',
                component: PaquetesFinancieraDetalleComponent,
                data: { breadcrumb: 'Conceptos' }
              }
            ]
          }
        ]
      }, {
        path: 'visits',
        component: VisitListComponent,
        data: { breadcrumb: 'Visitas' },
        children: [
          {
            path: 'visit/:folio',
            component: VisitDetailComponent,
            data: { breadcrumb: ':folio' },
          }, {
            path: 'create',
            component: VisitCreateComponent,
            data: { breadcrumb: 'Generar visita' },
          }
        ]
      }, {
        path: 'agentes', component: AgentListComponent, data: { breadcrumb: 'Agentes' }, children: [
          { path: 'nuevo', component: AgenteNuevoComponent, data: { breadcrumb: 'Nuevo' } },
          {
            path: ':id', component: AgentDetailComponent, data: { breadcrumb: 'Agente: :id' }, children: [
              { path: 'recorrido-hoy', component: AgenteRecorridoComponent, data: { breadcrumb: 'Recorrido de hoy' } }
            ]
          }
        ]
      },
      { path: 'agentes-visibilidad', component: ListaVisibilidadComponent, data: { breadcrumb: 'Visibilidad de cuentas' }, children: [
        { path: 'cuenta', component: AccountDetailComponent, data: { breadcrumb: 'Detalle', animation: 'routeAnimations' } }
      ]},
      { path: 'agentes-recorrido', component: AgenteRecorridoComponent, data: { breadcrumb: 'Recorrido por agente' } },
      {
        path: 'deallocation-percentage',
        component: IndicatorsComponent,
        data: { breadcrumb: 'Porcentaje de desasignación' }
      }, {
        path: 'calificar-obligatorios', component: CalificarObligatoriosComponent, data: { breadcrumb: 'Calificar Obligatorias (Red Kobra)' }, children: [
          {
            path: 'visit/:id', component: VisitDetailComponent, data: { breadcrumb: 'Visita: :id', animation: 'routeAnimations' }
          },
          {
            path: 'cuenta', component: AccountDetailComponent, data: { breadcrumb: 'Detalle', animation: 'routeAnimations' } 
          }
        ]
      },
      {
        path: 'promesas-pago', component: PromesasPagoComponent, data: { breadcrumb: 'Promesas de pago' }, children: [
          {
            path: 'visit/:id', component: VisitDetailComponent, data: { breadcrumb: 'Visita: :id', animation: 'routeAnimations' }
          },
          {
            path: 'cuenta', component: AccountDetailComponent, data: { breadcrumb: 'Detalle', animation: 'routeAnimations' } 
          },
          {
            path: 'agentes/:id', component: AgentDetailComponent, data: { breadcrumb: 'Agente: :id', animation: 'routeAnimations' }
          }

        ]
      }, {
        path: 'informacion-recopilada',
        component: InformacionRecopiladaComponent,
        data: { breadcrumb: 'Información recopilada' }, children: [
          {
            path: 'cuenta', component: AccountDetailComponent, data: { breadcrumb: 'Detalle', animation: 'routeAnimations' } 
          },
          {
            path: 'agentes/:id', component: AgentDetailComponent, data: { breadcrumb: 'Agente: :id',animation: 'routeAnimations' }, children: [
              { path: 'recorrido-hoy', component: AgenteRecorridoComponent, data: { breadcrumb: 'Recorrido de hoy',animation: 'routeAnimations' } }
            ]
          }
        ]
      }, {
        path: 'revisit-request',
        component: RevisitListComponent,
        data: { breadcrumb: 'Solicitudes de revisita' }, children: [
          { 
            path: 'visit/:folio', component: VisitDetailComponent, data: { breadcrumb: 'Visita: :folio', animation: 'routeAnimations'  } 
          }, 
          {
            path: 'cuenta', component: AccountDetailComponent, data: { breadcrumb: 'Detalle', animation: 'routeAnimations' } 
          },
          {
            path: 'agentes/:id', component: AgentDetailComponent, data: { breadcrumb: 'Agente: :id', animation: 'routeAnimations' }
          }
        ]
      }, {
        path: 'requests',
        component: RequestListComponent,
        data: { breadcrumb: 'Solicitudes' },
        children: [{
          path: ':id/cliente/:idFinanciera', 
          component: CreditRequestComponent,
          data:{breadcrumb: 'Detalle de solicitud: :id'}},
        ]
      }, {
        path: 'gestores-facturaciones',
        component: CobradoresFacturacionesComponent,
        data: { breadcrumb: 'Facturaciones Agentes' }, children: [
          {
            path: 'agentes/:id', component: AgentDetailComponent, data: { breadcrumb: 'Agente: :id', animation: 'routeAnimations' }
          }
        ]
      }, {
        path: 'visitas-calificadas', component: CalificadasVisitasComponent, data: { breadcrumb: 'Visitas Calificadas' }, children: [
          {
            path: 'visit/:folio', component: VisitDetailComponent, data: { breadcrumb: 'Visita: :folio' }, children: [
            ]
          },{
            path: 'agentes/:id', component: AgentDetailComponent, data: { breadcrumb: 'Agente: :id', animation: 'routeAnimations' }
          }
        ]
      },
      {
        path: 'descargas',
        component: DescargasComponent,
        data: { breadcrumb: 'Descargas' }
    }, 
    { 
        path: 'user', 
        component: UserEditComponent ,
        data: { breadcrumb: 'Editar Perfil' }
      },
        
    ]
  },
  { path: '**', redirectTo: '/' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    BrowserAnimationsModule,
  RouterModule.forRoot(
    routes,
    { enableTracing: false } // <-- For debugging purposes only
  )
  ],
  exports: [RouterModule],
  providers: [
    AuthService
  ]
})
export class AppRoutingModule { }
