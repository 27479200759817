import { PhoneType } from "../../../phone-type/phone-type.model";
import { Relationship } from "../../../relationship/relationship.model";

export class PersonalReference {
  id: number
  agentID: number;
  fullName: string;
  name: string;
  phoneNumber: string;
  relationship: Relationship;
  relationshipID: number;
  surname: string;
  activo?: boolean;
  idCobrador?: number;
  totalReferenciasPersonales?: number;
  idReferenciaPersonal?: number;

  constructor() {
    this.relationship = new Relationship();
  }
}