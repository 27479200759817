<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false;"></router-outlet>
<div class="app content kobra" *ngIf="!hijosActivos">
  <mat-card>
    <!-- <mat-card-header>
      <mat-card-title>{{nombreFinanciera}} - {{nombreChecklist}}</mat-card-title>
    </mat-card-header> -->
    <mat-card-content class="container">

      <!-- Primera Columna - General -->
      <mat-card class="tile">
        <mat-card-header>
          <mat-card-title>
            General
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="formGroupGeneral">
            <div *ngFor="let field of dynamicFields" class="field-container">
              <mat-checkbox class="kobra-checkbox responsive" [formControl]="getFormControl('field_' + field.name)"
                [checked]="getFormControl('field_' + field.name)?.value ?? false">
                {{ field.nombre }}
              </mat-checkbox>
            </div>
          </div>
        </mat-card-content>



        <mat-card-footer>
          <div mat-card-actions align="right" style="margin: 10px;">
            <button *ngIf="formGroupGeneral" mat-flat-button class="boton-cancelar"  style="margin-right: 10px;" (click)="cancelar()"
              m-actions>
              <mat-icon class="kobra-icon">
                close
              </mat-icon>Cancelar</button>

            <button *ngIf="formGroupGeneral" mat-stroked-button class="boton-guardar" (click)="saveConfiguraciones()"
              m-actions>
              <mat-icon>
                save
              </mat-icon>Guardar</button>
            
          </div>
          <mat-progress-bar *ngIf="cargandoGeneral" class="kobra-card-footer" mode="indeterminate"></mat-progress-bar>

        </mat-card-footer>
      </mat-card>



    </mat-card-content>
  </mat-card>
</div>