import { Component, ElementRef, OnChanges, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormValidation } from "../../../../shared/form-validation/form-validation.model";
import { FormValidationService } from "../../../../shared/form-validation/form-validation.service";
import { LoaderService } from "../../../../loader/loader.service";
import { MessageService } from "../../../../shared/message/message.service";
import { WorkExperience } from "../work-experience.model";
import { WorkExperienceService } from "../work-experience.service";
import { RelationshipService } from "../../../../relationship/relationship.service";
//import { UICalendar } from "../../../../shared/ui-calendar/ui-calendar.model";
import { Observable } from 'rxjs';
import { ApplicationService } from 'src/app/application/shared/application.service';
import moment from 'moment';

declare let $: any;

@Component({
  selector: 'work-experience-modal-form',
  templateUrl: './work-experience-modal-form.component.html',
  styleUrls: ['./work-experience-modal-form.component.css'], 
  providers: [
    FormValidationService,
    WorkExperienceService,
    RelationshipService,
    LoaderService
  ]
})
export class WorkExperienceModalFormComponent implements OnInit, OnChanges {
  private $modal: any;

  public form: FormGroup;
  public companyValidation: FormValidation;
  public titleValidation: FormValidation;
  public bossValidation: FormValidation;
  public startDateValidation: FormValidation;
  public endDateValidation: FormValidation;
  public commentValidation: FormValidation;
  maxDate: Date;
  minDate: Date;

  workExperience: WorkExperience;
  agentID: number;

  afterSave: Function;
  afterDelete: Function;
  totalExperienciaLaboral: number;

  constructor(
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private workExperienceService: WorkExperienceService,
    private rooElement: ElementRef,
    private app: ApplicationService,
    public dialogRef: MatDialogRef<WorkExperienceModalFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    this.workExperience = data.workExperience;
    this.agentID = data.agentID;
    this.afterSave = data.afterSave;
    this.afterDelete = data.afterDelete;
    this.totalExperienciaLaboral = data.totalExperienciaLaboral;
  }

  ngOnInit() {
    this.initForm();
    this.initValidation();
    this.createCalendars();
  }

  ngOnChanges() {
    if (this.form) {
      this.resetForm();
    }
  }

  private createCalendars(): void {
    this.maxDate = new Date();
    /*this.endDateCalendar = new UICalendar();
    this.endDateCalendar.$element = $(this.rooElement.nativeElement).find('.ui.calendar#endDate');
    this.endDateCalendar.maxDate = new Date();
    this.endDateCalendar.type = 'date';
    this.endDateCalendar.format = 'es-MX';
    this.endDateCalendar.month = 'long';
    this.endDateCalendar.formControl = this.form.controls.endDate;
    

    this.startDateCalendar = new UICalendar();
    this.startDateCalendar.$element = $(this.rooElement.nativeElement).find('.ui.calendar#startDate');
    this.startDateCalendar.maxDate = new Date();
    this.startDateCalendar.type = 'date';
    this.startDateCalendar.format = 'es-MX';
    this.startDateCalendar.month = 'long';
    this.startDateCalendar.formControl = this.form.controls.startDate;
    this.startDateCalendar.onChange = (date) => {
      this.endDateCalendar.minDate = date;
      this.endDateCalendar.create();

      this.form.get('endDate').reset();
    };*/
  }

  onClose() {
    this.dialogRef.close(false);
  }

  public delete(): void {
    const loader = this.app.showLoading('Eliminando experiencia laboral...');
    const workExperience: WorkExperience = this.prepareSaveData();
    workExperience.totalExperienciaLaboral = this.totalExperienciaLaboral - 1;
    workExperience.agentID = this.agentID;
    this.workExperienceService.delete(this.workExperience.id, workExperience).subscribe(
      () => {
        this.app.showSnackbar('¡Aviso!', 'Experiencia laboral eliminada correctamente.', 3000, 'success');
        this.app.hideLoading(loader);
        this.afterDelete(this.workExperience);
      }, error => {
        this.app.hideLoading(loader);
        this.app.showError(error);
      }
    );
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { 'required': true };
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      company: ['', [
        Validators.required,
        this.noWhitespaceValidator
      ]],
      title: ['', [
        Validators.required,
        this.noWhitespaceValidator
      ]],
      boss: ['', [
        Validators.required,
        this.noWhitespaceValidator
      ]],
      startDate: ['', [
        Validators.required
      ]],
      endDate: ['', [
        Validators.required
      ]],
      comment: ['', [
      ]],
    });
    if(this.workExperience){
      this.resetForm();
    }
    
  }

  private initValidation(): void {
    this.companyValidation = new FormValidation();
    this.companyValidation.formControl = this.form.controls['company'];
    this.companyValidation.message = {
      required: 'Información requerida.'
    };
    this.companyValidation.observeFromControl();

    this.titleValidation = new FormValidation();
    this.titleValidation.formControl = this.form.controls['title'];
    this.titleValidation.message = {
      required: 'Información requerida.'
    };
    this.titleValidation.observeFromControl()

    this.bossValidation = new FormValidation();
    this.bossValidation.formControl = this.form.controls['boss'];
    this.bossValidation.message = {
      required: 'Información requerida.'
    };
    this.bossValidation.observeFromControl();

    this.startDateValidation = new FormValidation();
    this.startDateValidation.formControl = this.form.controls['startDate'];
    this.startDateValidation.message = {
      required: 'Información requerida.'
    };
    this.startDateValidation.observeFromControl();

    this.endDateValidation = new FormValidation();
    this.endDateValidation.formControl = this.form.controls['endDate'];
    this.endDateValidation.message = {
      required: 'Información requerida.'
    };
    this.endDateValidation.observeFromControl();
  }

  private prepareSaveData(): WorkExperience {
    let formModel = this.form.value;
    let workExperience: WorkExperience = new WorkExperience();
    workExperience.id = this.workExperience.id;
    workExperience.company = formModel.company.trim();
    workExperience.title = formModel.title.trim();
    workExperience.boss = formModel.boss.trim();
    workExperience.startDate = new Date(moment(formModel.startDate).format('YYYY-MM-DD'));
    workExperience.endDate = new Date(moment(formModel.endDate).format('YYYY-MM-DD'))
    workExperience.comment = formModel.comment ? formModel.comment.trim() : formModel.comment;
    return workExperience;
  }

  private resetForm(): void {
    this.form.reset({
      company: this.workExperience.company,
      title: this.workExperience.title,
      boss: this.workExperience.boss,
      comment: this.workExperience.comment,
      startDate: new Date(this.workExperience.startDate),
      endDate: new Date(this.workExperience.endDate)
    });

    if (!this.workExperience.id) {
      this.form.reset();
    }
  }

  public save(): void {
  
    if (this.form.valid) {
      let workExperience: WorkExperience = this.prepareSaveData();
      let response: Observable<WorkExperience>
      
      workExperience.agentID = this.agentID;
      if (workExperience.id) {
        workExperience.id = this.workExperience.id;
        response = this.workExperienceService.update(workExperience.id, workExperience);
      } else {
        workExperience.totalExperienciaLaboral = this.totalExperienciaLaboral + 1
        response = this.workExperienceService.add(workExperience);
      }

      const loader = this.app.showLoading('Guardando experiencia laboral...');
      response.subscribe(response => {
        this.app.hideLoading(loader);
        this.app.showSnackbar('¡Aviso!', 'Experiencia laboral guardada correctamente.', 3000, 'success');
        workExperience.id = workExperience.id || +response.idExperienciaLaboral;
        this.dialogRef.close(false);
        this.afterSave(workExperience);
      },error => {
        this.app.hideLoading(loader);
        this.app.showError(error);
      });
    }
  }

  public setStartDate( date: Date ): void {
    //this.form.controls.startDate.setValue(date);
  }

  public show(): void {
    this.$modal.modal('show');
    //this.startDateCalendar.create();
    //this.endDateCalendar.create();
  }
}
