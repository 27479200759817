import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from "../../../../loader/loader.service";
import { MessageService } from "../../../../shared/message/message.service";
import { OnboardingDistribuidorService } from "../onboarding-distribuidor.service";
import { onboardingDistribuidor } from "../onboarding-distribuidor.model";
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
@Component({
  selector: 'app-onboarding-distribuidor-editable',
  templateUrl: './onboarding-distribuidor-editable.component.html',
  styleUrls: ['./onboarding-distribuidor-editable.component.css'],
  providers: [
    OnboardingDistribuidorService
  ]
})
export class OnboardingDistribuidorEditableComponent implements OnInit {
  public showSegmentLoader: boolean = false;
  public onboarding: onboardingDistribuidor;

  public OnboardingDistribuidorCompleto: any;
  public sessionData: SessionData;

  @Input() public loader: boolean;
  @Input() agentID: number;
  @Input() activo: boolean;


  constructor(
    private loaderService: LoaderService,
    private messageService: MessageService,
    private OnboardingDistribuidorService: OnboardingDistribuidorService,
    private localStorageService: LocalStorageService
  ) {
    this.sessionData = this.localStorageService.getSessionData();
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.loadOnboarding(this.agentID);
  }

  private loadOnboarding(agentID: number){
    this.showLoader();
    this.OnboardingDistribuidorService.all(agentID).subscribe(
      (onboarding) => {
        this.hideLoader();

       this.onboarding = onboarding[0];
  
        if(this.onboarding){
          if (
            this.onboarding.direccion == 1 && this.onboarding.facturacion == 1 && this.onboarding.informacionPersonal == 1 &&
            this.onboarding.personalReferences == 1 && this.onboarding.profilePicture == 1 && this.onboarding.workExperience == 1 &&
            this.onboarding.workReferences == 1
          ) this.onboarding.perfil = 1;
  
          if(!this.onboarding.perfil) this.onboarding.perfil = 0;
          if(!this.onboarding.certificacionDistribuidor) this.onboarding.certificacionDistribuidor = 0;
  
          if(!this.onboarding.primerLogin) this.onboarding.primerLogin = 0;
          if(!this.onboarding.solicitudDistribuidor) this.onboarding.solicitudDistribuidor = 0;
          if(!this.onboarding.solicitudRealDistribuidor) this.onboarding.solicitudRealDistribuidor = 0;
          if(!this.onboarding.solicitudVinculacionDistribuidor) this.onboarding.solicitudVinculacionDistribuidor = 0;
        }
        

      },
      (error) => {
        this.hideLoader();
        this.messageService.httpError(error);
      }
    );
  }

  private hideLoader(): void {
    if (this.loader) {
      this.loaderService.hideHttpLoader();
    } else {
      this.showSegmentLoader = false;
    }
  }

  private showLoader(): void {
    if (this.loader) {
      this.loaderService.showHttpLoader();
    } else {
      this.showSegmentLoader = true;
    }
  }

  public onChange(estatus, paso){
    this.OnboardingDistribuidorService.update(this.agentID,estatus,paso).subscribe(
      (res) => {

        this.loadOnboarding(this.agentID);

      },
      (error) => {
        this.messageService.httpError(error);
      }
    );
  }

}
