import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ServicesVisitasService } from '../core/http/services-visitas/services-visitas.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VerificarCodigo2FAData, VerificarCodigo2FADataResponse } from './auth.interface';
import { IServicesTypeKobraResponse } from '../core/http/servicio-general/servicio-general-response';

@Injectable()
export class AuthService implements CanActivate {

  constructor(
    private router: Router,
    private visitasService: ServicesVisitasService
  ) { }

  canActivate(): boolean {
    var loggedIn: boolean = false;
    if (localStorage.getItem('access-token')) {
      loggedIn = true;
    } else {
      this.router.navigate(['/auth']);
    }
    return loggedIn;
  }

  reloadBaseUrl(archivo:string = ""){
    this.visitasService.reloadBaseUrl(archivo);
  }

  logIn(email, contrasena): Observable<any>{
    let data = {
      email: email,
      contrasena: contrasena
    };
    
    let response = this.visitasService.post('/login', data, true)
      .pipe(
        map((res) => {
          return res;
        })
      );

    return response;
  }


  verificarCodigo2FA(pSessionId: string, pVerificationCode: string, pEmail: string): Observable<IServicesTypeKobraResponse<VerificarCodigo2FADataResponse>> {
    let data: VerificarCodigo2FAData = {
      sessionId: pSessionId,
      verificationCode: pVerificationCode,
      email: pEmail
    };

    return this.visitasService.post<VerificarCodigo2FADataResponse>('/2fa', data, true).pipe(
      map((res) => res)
    );
  }
  

  loginUpdate(): Observable<any>{
    let data = {
      idAplicacion: 2
    };

    let response = this.visitasService.post('/login/update', data, true)
      .pipe(
        map((res) => {
          return res;
        })
      );

    return response;
  }
}