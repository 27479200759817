import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from "../../../../loader/loader.service";
import { WorkExperience } from "../work-experience.model";
import { WorkExperienceService } from "../work-experience.service";
import { WorkExperienceModalFormComponent } from "../work-experience-modal-form/work-experience-modal-form.component";
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationService } from 'src/app/application/shared/application.service';

@Component({
  selector: 'work-experience-editable-list',
  templateUrl: './work-experience-editable-list.component.html',
  styleUrls: ['./work-experience-editable-list.component.css'],
  providers: [
    WorkExperienceService
  ]
})
export class WorkExperienceEditableListComponent implements OnChanges, OnInit {
  isRegistros = 0;
  public workExperiences: WorkExperience[];
  public showSegmentLoader: boolean = false;
  public selectedWorkExperience: WorkExperience;
  public sessionData: SessionData;
  cargandoExperienciaLaboral: boolean = true;
  showNewWorkReference = false;
  showEditWorkReference = false;

  @Input() public loader: boolean;
  @Input() agentID: number;
  @Input() activo: boolean;

  @ViewChild(WorkExperienceModalFormComponent) private workExperienceModalFormComponent: WorkExperienceModalFormComponent;

  constructor(
    private loaderService: LoaderService,
    private workExperienceService: WorkExperienceService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private app: ApplicationService
  ) {
    this.sessionData = this.localStorageService.getSessionData();
  }

  ngOnInit() {
    this.selectedWorkExperience = new WorkExperience();
  }

  ngOnChanges() {
    this.loadExperiences(this.agentID);
  }

  public deleteExperience( workExperience: WorkExperience ): void {
    let index: number = this.getArrayIndex(this.workExperiences, workExperience);
    if (index != -1) {
      this.workExperiences.splice(index, 1);
    }
  }

  private hideLoader(): void {
    if (this.loader) {
      this.loaderService.hideHttpLoader();
    } else {
      this.showSegmentLoader = false;
    }
  }

  private loadExperiences( agentID?: number ): void {
    this.cargandoExperienciaLaboral = true;
    this.workExperienceService.all(agentID).subscribe(
      (experiences) => {
        this.isRegistros = experiences.length;
        this.workExperiences = experiences;
        this.cargandoExperienciaLaboral = false;
      }
    );
  }

  public saveExperience( workExperience: WorkExperience ): void {
    let index: number = this.getArrayIndex(this.workExperiences, workExperience);
    if (index != -1) {
      this.workExperiences.splice(index, 1, workExperience);
    } else {
      this.workExperiences.push(workExperience);
    }
  }

  private getArrayIndex( array: WorkExperience[], toFind: WorkExperience ): number {
    let index: number = -1;
    for (var i = 0; i < array.length; i++) {
      if (array[i].id == toFind.id) {
        index = i;
        break;
      }
    }
    return index;
  }

  public showForm( workExperience?: WorkExperience ): void {
    this.selectedWorkExperience = workExperience || new WorkExperience();
    this.showNewWorkReference = workExperience ? false : true;
    this.showEditWorkReference = workExperience ? true : false;
    const dialogRef = this.dialog.open(WorkExperienceModalFormComponent, {
      disableClose: false,
      width: '50%',
      data: {
        workExperience: this.selectedWorkExperience,
        agentID: this.agentID,
        totalExperienciaLaboral: this.workExperiences?.length,
        afterSave: this.saveExperience.bind(this),
        afterDelete: this.deleteExperience.bind(this)
      }
      
    });

    dialogRef.afterClosed().subscribe(data => {});
  }

  private showLoader(): void {
    if (this.loader) {
      this.loaderService.showHttpLoader();
    } else {
      this.showSegmentLoader = true;
    }
  }

  eliminar(workExperience: WorkExperience){
    const loader = this.app.showLoading('Eliminando experiencia laboral...')
    workExperience.totalExperienciaLaboral = this.workExperiences?.length - 1;
    this.workExperienceService.delete(workExperience.id, workExperience).subscribe({
        next: () => {
          this.app.showSnackbar('¡Aviso!', 'Experiencia laboral eliminada correctamente.', 3000, 'success');
          this.app.hideLoading(loader);
          this.deleteExperience(workExperience);
        },
        error: (error) => {
            this.app.hideLoading(loader);
            this.app.showError(error);
        }
    });
  }

}
