import { SessionData } from './../../../shared/interfaces/session-data';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationService } from '../../../application/shared/application.service';
import { Observable } from 'rxjs';
import { AgentService } from '../../agent.service';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { LenderService } from '@lender/lender.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ProductosService } from 'src/app/catalogos/permisos-producto/productos.service';
import { IProductosAdministrador} from 'src/app/catalogos/usuarios/productos/productos.interface';

@Component({
  selector: 'vinculacion-productos.component',
  templateUrl: 'vinculacion-productos.component.html',
  styleUrls: ['vinculacion-productos.component.css'],
  providers: [AgentService, LenderService],
})
export class ModalVinculacionProductosComponent {
  public selectedProducts: any[];
  public selectedCredits: any[];
  public cargando = false;
  public idCliente: number;
  public idLender: number;
  public credits: any[];
  sessionData: SessionData;
  lenders: any;
  productos: IProductosAdministrador[] = [];
  cargandoProductos: boolean = false;
  financiera: any[] = []; 
  desactivarProducto:boolean = true;

  constructor(
    private localStorageService: LocalStorageService,
    private agentService: AgentService,
    public dialogRef: MatDialogRef<ModalVinculacionProductosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private app: ApplicationService,
    private lenderService: LenderService,
    private productosServ: ProductosService
  ) {
    
    this.sessionData = this.localStorageService.getSessionData();
    if(!this.sessionData.isSuperAdmin){
      this.idCliente = this.sessionData.idCliente;
    }
    
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
   if(this.idCliente){
    this.loadCredits();
   }

  }

  changeLender(cliente: any) {
    this.selectedCredits = null;
    if (cliente) {
      this.idCliente =  cliente
      this.loadProductos();
      this.loadCredits();
    }else {
      this.idCliente =  null;
      this.desactivarProducto = true;
      this.selectedProducts = null;
    }
  }

  loadProductos(): void {
    this.cargandoProductos = true;
    this.desactivarProducto = true;
    this.productosServ.obtenerProductosPorCliente(this.idCliente).pipe(
      take(1)
    ).subscribe({
      next: (res: IProductosAdministrador[]) => {
        this.productos = res;
      },
      error: (error: any) => {
        this.app.showError(error);
        this.cargandoProductos = false;
        this.desactivarProducto = false;
      },
      complete: () => {
        this.cargandoProductos = false;
        this.desactivarProducto = false;
      }
    });
  }

  private loadCredits() {
    if (this.idCliente) {
      this.lenderService.obtenerTiposCreditoCliente(this.idCliente).pipe(
        take(1)
      ).subscribe(
        (res: any) => {
          this.credits = res.data;
        },
        (err: any) => {
          this.app.showError(err);
        }
      );
    }
  }

  validarVinculacion() {
    let bRespuesta = true;

    if (!this.idCliente) {
      this.app.showSnackbar('Aviso', 'Favor de seleccionar un cliente.', 3000, 'warning');
      bRespuesta = false;
    }else if (!this.selectedProducts || this.selectedProducts.length === 0) {
      this.app.showSnackbar('Aviso', 'Favor de seleccionar al menos un producto.', 3000, 'warning');
      bRespuesta = false;
    }else {
      this.selectedProducts.forEach(element => {
        if (element.idProducto === 15) {
            
          if (!this.selectedCredits || this.selectedCredits.length === 0) {
            this.app.showSnackbar('Aviso', 'Favor de seleccionar al menos un crédito.', 3000, 'warning');
            bRespuesta = false;
          }
        }
      });
  
    }

    
    return bRespuesta;
  }

  /*vincularProductos() {
    
    if (this.validarVinculacion()) {

      this.cargando = true;
      let loading: number = this.app.showLoading(`Vinculando producto...`);
      const observables: Observable<any>[] = this.data.seleccionAgentes.map((agente: any) => {
        return this.vincularProducto(agente);
      });

      forkJoin(observables).subscribe(
        (results: any[]) => {
          const contador = results.filter((result) => result === true).length;
          this.cargando = false;
          this.selectedProducts = [];
          this.selectedCredits = [];
          this.app.showSnackbar('¡Aviso!', `${contador} agentes fueron vinculados correctamente.`, 3000, 'success');
          this.app.hideLoading(loading);
          this.dialogRef.close(true);
        },
        (error) => {
          this.app.hideLoading(loading);
          this.cargando = false;
          this.app.showSnackbar('Error', 'Hubo un problema al vincular los agentes.', 3000, 'error');
        }
      );
    }
  }*/
/*
  private vincularProducto(agente: any): Observable<boolean> {
    let creditosSeleccionados:any = [];

    const productosSeleccionados = {
      idsProductos: this.selectedProducts.map((product) => product.idProducto),
    };

    if (this.selectedCredits) {
      creditosSeleccionados = {
        idsTipoCredito: this.selectedCredits.map((credito) => credito.idTipoCredito),
      };
    }

    return new Observable<boolean>((observer) => {
      this.agentService.vincularProducto(agente.id, this.idCliente, productosSeleccionados).pipe(
        take(1)
      ).subscribe(
        (respuesta: any) => {
          if (!respuesta.success && respuesta.error.message !== '') {
            this.app.showSnackbar('¡Aviso!', respuesta.error.message, 3000, 'warning');
          }

          if (respuesta.success &&  this.selectedCredits) {
            this.vincularTiposCredito(agente.id, creditosSeleccionados);
          }

          observer.next(true);
          observer.complete();
        },
        (error) => {
          this.dialogRef.close(false);
          this.app.showError(error);
          observer.next(false);
          observer.complete();
        }
      );
    });
  }*/

    vincularProductos() {
      this.cargando = true;
      let loading: number = this.app.showLoading(`Vinculando producto...`);
      // Arreglo para almacenar los créditos seleccionados
      let creditosSeleccionados: any = [];
    
      // Crear objetos con los IDs de productos y agentes seleccionados
      const productosSeleccionados = {
        idsProductos: this.selectedProducts.map((product) => product.idProducto),
      };
    
      const agentesSeleccionados = {
        idsAgentes: this.data.seleccionAgentes.map((agente) => agente.idAgente),
      };
    
      // Si hay créditos seleccionados, crear el objeto correspondiente
      if (this.selectedCredits) {
        creditosSeleccionados = {
          idsTipoCredito: this.selectedCredits.map((credito) => credito.idTipoCredito),
        };
      }
    
      // Llamar al servicio para vincular los productos
      this.agentService
        .vincularProducto(agentesSeleccionados, this.idCliente, productosSeleccionados)
        .pipe(take(1))
        .subscribe({
          next: (respuesta: any) => {
            // Si la respuesta no es exitosa, mostrar un mensaje de error
            if (!respuesta.success && respuesta.error?.message) {
              this.app.hideLoading(loading);
              this.cargando = false;
              this.app.showSnackbar('¡Aviso!', respuesta.error.message, 3000, 'warning');
            }
    
            // Si la respuesta es exitosa y hay créditos seleccionados, vincular los tipos de crédito
            if (respuesta.success && this.selectedCredits) {
              this.data.seleccionAgentes.forEach((agente) => {
                this.vincularTiposCredito(agente.idAgente, creditosSeleccionados);
              });
            }
    
            // Cerrar el diálogo con éxito
            this.dialogRef.close(true);
          },
          error: (error: any) => {
            // Manejar errores del servicio
            this.app.hideLoading(loading);
            this.cargando = false;
            this.dialogRef.close(false);
            this.app.showError(error);
            this.app.showSnackbar('Error', 'Hubo un problema al vincular los agentes.', 3000, 'error');
          },
          complete: () => {
            // Acciones adicionales cuando la suscripción se completa
            this.app.showSnackbar('¡Aviso!', `${this.data.seleccionAgentes.length} agentes fueron vinculados correctamente.`, 3000, 'success');
            this.app.hideLoading(loading);
            this.cargando = false;
          },
        });
    }
    
    

    private vincularTiposCredito(idAgente: any, creditosSeleccionados: any) {
      this.agentService
        .vincularTiposCredito(idAgente, this.idCliente, creditosSeleccionados)
        .pipe(take(1))
        .subscribe({
          next: (res: any) => {
            // Aquí puedes manejar la respuesta exitosa del servicio
            //console.log('Vinculación exitosa:', res);
          },
          error: (err: any) => {
            // Manejar errores del servicio
            this.app.showError(err);
          },
          complete: () => {
            // Acciones opcionales al completar la suscripción
            //console.log('Suscripción completada para vincularTiposCredito');
          },
        });
    }
    
}
