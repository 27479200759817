import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { ICriterio, ICriteriosResponse, IPaginatorCriteriosCalidad, IProductoCriterio, IProductosCliente } from "./criterios.interface"
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { Observable } from 'rxjs';
import { IServicesTypeKobraResponse } from '../core/http/servicio-general/servicio-general-response';

@Injectable({
    providedIn: 'root'
})

export class VisitasCriteriosService {

    constructor(
        private servicesVisitasService: ServicesVisitasService
    ) { }

    public criteriosGrid(queryParams: string): Observable<IServicesTypeKobraResponse<IPaginatorCriteriosCalidad>> {
        return this.servicesVisitasService.get<IPaginatorCriteriosCalidad>(`/criterios-calidad-visitas-calif-grid${queryParams}`) .pipe(
            map((res) => {
              return res;
            })
          );
      }

      public obtenerProductosPorCliente(idCliente: number): Observable<IServicesTypeKobraResponse<{ data: IProductoCriterio[] }>> {
        return this.servicesVisitasService.get<{ data: IProductoCriterio[] }>(`/clientes/${idCliente}/productos`);
      }
      
      
      public obtenerProductosSinCriteriosDeVisita(idCliente: number): Observable<IServicesTypeKobraResponse<{ data: IProductosCliente[] }>> {
        return this.servicesVisitasService.get<{ data: IProductosCliente[] }>(`/clientes/${idCliente}/productos-sin-criterio-visita`);
      }
      
      public obtenerCriterios(idCliente: number, idProducto: number): Observable<IServicesTypeKobraResponse<{ data: ICriterio[] }>> {
        return this.servicesVisitasService.get<{ data: ICriterio[] }>(`/clientes/${idCliente}/productos/${idProducto}/criterios`);
      }
      
      
      public guardarCriterios(
        idCliente: number,
        idProducto: number,
        criterios: ICriterio[]
      ): Observable<IServicesTypeKobraResponse<void>> {
        return this.servicesVisitasService.post<void>(`/clientes/${idCliente}/productos/${idProducto}/criterios-calidad-visitas-calif`, {
          criteriosCalidadVisitasCalif: criterios,
        });
      }
      
      public actualizarCriterios(
        idCliente: number,
        idProducto: number,
        criterios: ICriterio[]
      ): Observable<IServicesTypeKobraResponse<void>> {
        return this.servicesVisitasService.patch<void>(`/clientes/${idCliente}/productos/${idProducto}/criterios-calidad-visitas-calif`, {
          criteriosCalidadVisitasCalif: criterios,
        });
      }
      
}