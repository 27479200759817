import { ApplicationService } from './../../application/shared/application.service';
import { LocalStorageService } from './../../shared/services/local-storage.service';
import { SessionData } from './../../shared/interfaces/session-data';
import { Lender } from './../../lender/lender.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AgenteNuevoModel } from '../modelos/agente-nuevo.model';
import { LenderService } from '../../lender/lender.service';
import { AgentService } from "../agent.service";
import { IAgenteCamposExtras, ICampoExtraAgente } from '@agent/interfaces/campo-extra-agentes.interface';
import { CamposExtrasAgentesFormComponent } from '@agent/forms/campos-extras-agentes/campos-extras-agentes-form.component';

@Component({
  selector: 'app-agente-nuevo',
  templateUrl: './agente-nuevo.component.html',
  styleUrls: ['./agente-nuevo.component.css'],
  providers: [LenderService, AgentService]
})
export class AgenteNuevoComponent implements OnInit {
  public sessionData: SessionData;
  public clientes: Lender[];
  public cliente: Lender;

  hidePwd: boolean = true;
  agente: AgenteNuevoModel;
  loadingRegistro: boolean = false;
  loadingLenders: boolean = false;
  loadingCamposExtras: boolean = false;
  ayudaRoles: boolean = false;
  camposExtrasAgentes: ICampoExtraAgente[] = [];
  camposExtrasAgentesLimpio: ICampoExtraAgente[] = [];
  private emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  private celPattern = /^\d{10}$/;
  private contraPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{8,}$/;
  @ViewChild(CamposExtrasAgentesFormComponent) campoExtraComponent: CamposExtrasAgentesFormComponent;

  public validados = {
    financiera: false,
    nombre: false,
    apellidoP: false,
    sexo: false,
    email: false,
    mailInvalido: false,
    cel: false,
    contrasena: false,
    contraCorrecta: false
  };

  constructor(
    private agentService: AgentService,
    private app: ApplicationService,
    private breakpointObserver: BreakpointObserver,
    private lenderService: LenderService,
    private localStorageService: LocalStorageService,
    private location: Location
  ) {
    this.sessionData = this.localStorageService.getSessionData();
  }

  ngOnInit() {
    this.agente = new AgenteNuevoModel();
    this.agente.idCliente = this.sessionData.idCliente;
    this.loadLenders();
  }

  public loadLenders(): void {
    this.loadingLenders = true;

    this.lenderService.getAll('').subscribe(
        res => {
            this.clientes = res;
            this.onChangeLender(this.agente.idCliente);
            this.loadingLenders = false;
        },
        err => {
            this.app.showError(err);
            this.loadingLenders = false;
        },
        () => this.loadingLenders = false
    );
  }

  public onChangeLender(event) {
    let consultar: boolean = false;
    if(!this.cliente || event != this.cliente.idFinanciera){
      consultar = true;
      this.limpiarCamposExtras();
    }

    if (event) {
        this.clientes.forEach(lender => {
            if (lender.idCliente == event) {
                this.cliente = lender;
                if(consultar){
                  this.obtenerCamposExtras();
                }
            }
        });
    }
    else {
        this.cliente = null;
    }
  }

  limpiarCamposExtras(){
    this.camposExtrasAgentes = [];
    this.camposExtrasAgentesLimpio = [];
  }

  obtenerCamposExtras(){
    this.loadingCamposExtras = true;
    this.lenderService.obtenerCamposExtras<ICampoExtraAgente[]>(this.cliente.idCliente).subscribe(
      res => {
          this.camposExtrasAgentes = res;
          this.camposExtrasAgentesLimpio = JSON.parse(JSON.stringify(this.camposExtrasAgentes));
          this.loadingCamposExtras = false;
      },
      err => {
          this.app.showError(err);
          this.loadingCamposExtras = false;
      },
      () => this.loadingCamposExtras = false
    );
  }

  guardarAgente(){
    var avanza: boolean = true;

    this.agente.nombre = !this.agente.nombre ? null : this.agente.nombre.toUpperCase().trim();
    this.agente.segundoNombre = !this.agente.segundoNombre ? null : this.agente.segundoNombre.toUpperCase().trim();
    this.agente.apellidoP = !this.agente.apellidoP ? null : this.agente.apellidoP.toUpperCase().trim();
    this.agente.apellidoM = !this.agente.apellidoM ? null : this.agente.apellidoM.toUpperCase().trim();
    this.agente.email = !this.agente.email ? null : this.agente.email.trim();
    this.agente.telefonoCelular = !this.agente.telefonoCelular ? null : this.agente.telefonoCelular.trim();
    this.agente.pwd = !this.agente.pwd ? null : this.agente.pwd.trim();
    this.validados.financiera = !this.agente.idCliente ? true : false;
    this.validados.nombre = !this.agente.nombre ? true : false;
    this.validados.apellidoP = !this.agente.apellidoP ? true : false;
    this.validados.sexo = !this.agente.sexo ? true : false;
    this.validados.email = !this.agente.email ? true : false;
    this.validados.mailInvalido = this.validados.email ? false : !this.emailPattern.test(this.agente.email);
    this.validados.cel = this.agente.telefonoCelular ? !this.celPattern.test(this.agente.telefonoCelular) : false;
    this.validados.contrasena = !this.agente.pwd ? true : false;
    this.validados.contraCorrecta = !this.contraPattern.test(this.agente.pwd);

    for (let index = 0;avanza && index < Object.keys(this.validados).length ; index++) {
      const key = Object.keys(this.validados)[index];
      avanza = !this.validados[key];
    }

    if(this.camposExtrasAgentes.length > 0 && !this.campoExtraComponent.validaElementos()){
      return;
    }

    if(this.camposExtrasAgentes && this.camposExtrasAgentes.length > 0){
      this.agente.camposExtras = [...this.camposExtrasAgentes];
    }

    if(!avanza){
      return;
    }
    this.app.showLoading("Guardando información...")
    this.agentService.nuevo(this.agente).subscribe(
      res => {
        this.finalizarGuardado(res.data.idCobrador);
      },
      error => {
        this.app.hideLoading();
        this.app.showError(error);
      }
    );
  }

  cancelar(){
    this.location.back();
  }

  finalizarGuardado(idCobrador: number) {
    this.loadingRegistro = false;
    this.agente.nombre = null;
    this.agente.segundoNombre = null;
    this.agente.apellidoP = null;
    this.agente.apellidoM = null;
    this.agente.sexo = null;
    this.agente.email = null;
    this.agente.telefonoCelular = null;
    this.agente.pwd = null;
    this.camposExtrasAgentes = JSON.parse(JSON.stringify(this.camposExtrasAgentesLimpio));
    this.app.hideLoading();
    this.app.showSnackbar(
      "Aviso",
      "El agente ha sido dado de alta con el id " + idCobrador + ".",
      2000,
      "success"
    );
  }

  guardarCamposExtras(idCobrador: number, idAgente: number){
    let camposExtras: IAgenteCamposExtras = {
      idAgente: idAgente,
      idCliente: this.cliente.idCliente,
      camposExtras: this.camposExtrasAgentes
    };
    this.agentService.camposExtras(camposExtras).subscribe(
      res => {
          this.finalizarGuardado(idCobrador);
        },
        error => {
          this.loadingRegistro= false;
          this.app.showError(error);
        }
      );
  }
}
