import { AgentAddress } from "../agent-address/agent-address.model";
import { AgentStatus } from "./agent-status.model";
import { AgentChecklist } from "../agent-checklist/agent-checklist.model";
import { Certificate } from "../certificate/certificate.model";
import { PersonalReference } from "../personal-reference/personal-reference.model";
import { Visit } from "../visit/visit.model";
import { WorkExperience } from "../work-experience/work-experience.model";
import { WorkReference } from "../work-reference/work-reference.model";

export class Agent {
  id: number;
  address: AgentAddress;
  avgRate: number;
  certified: boolean;
  certificate: Certificate;
  checklist: AgentChecklist;
  createdAt: Date;
  email: string;
  fullName: string;
  firstName: string;
  gender: string;
  secondName: string;
  firstSurname: string;
  secondSurname: string;
  birthdate: Date;
  personalReferences: PersonalReference[];
  profilePicture: string;
  rutaFotoPerfil: string;
  rates: number;
  relationshipStatus: string;
  selected: boolean;
  phoneNumber: string;
  cellphoneNumber: string;
  visits: Visit[];
  workExperiences: WorkExperience[];
  workReferences: WorkReference[];
  visibility: string;
  curp: string;
  rfc: string;
  idINEFront: number;
  idINEBack: number;
  idCedulaFiscal: number;
  rutaIneFrontal: string;
  rutaIneTracera: string;
  rutaCedulaFiscal: string;
  desassignedAccountCount: number;
  assignedAccountCount: number;
  validado: boolean;
  buroLaboral: boolean;
  cedulaFiscal: boolean;
  disponible: boolean;
  primerContacto: boolean;
  nss: string;
  idCobradorEstatus: number;
  cobradorEstatus: AgentStatus;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  nombreCompleto: string;
  ranking: number;
  activo: boolean;
  diasBaja: number;
  fecBaja: string;
  fecVencimientoBaja: string;
  contrasena: string;
  idCobrador: number;
  idAgente: number;
  interno:boolean;
  nombreArchivoPerfil: string;
  nombreArchivoIneFrontal: string;
  nombreArchivoIneTracera: string;
  nombreArchivoCedula: string;
  idCliente:number;

  constructor() {
    this.address = new AgentAddress();
    this.checklist = new AgentChecklist();
    this.cobradorEstatus = new AgentStatus();
    this.personalReferences = [];
    this.rates = 0;
    this.visits = [];
    this.workExperiences = [];
    this.workReferences = [];
  }

  public static map(data: any): Agent {
    let instance = new Agent();

    instance.idCobrador = data.idCobrador;
    instance.id = data.idAgente;
    instance.idAgente = data.idAgente ? data.idAgente : null;
    instance.createdAt = data.fechaRegristro ? data.fechaRegristro : null;
    instance.fullName = data.nombre + ' ' + data.apellidoPaterno + (data.apellidoMaterno ? ' ' + data.apellidoMaterno: '');
    instance.firstName = data.nombre;
    instance.firstSurname = data.apellidoPaterno;
    instance.secondSurname = data.apellidoMaterno;
    instance.email = data.email;
    instance.phoneNumber = data.telefono;
    instance.cellphoneNumber = data.telefonoCelular;
    instance.birthdate = data.fechaNacimiento;
    instance.curp = data.curp;
    instance.rfc = data.rfc;
    instance.nss = data.nss;
    instance.buroLaboral = data.buroLaboral;
    instance.idCedulaFiscal = data.idCedulaFiscal;
    instance.interno = data.redInterna;
    instance.idCliente = data.idCliente;
    instance.idCobradorEstatus = data.idCobradorEstatus;
    instance.primerContacto = data.primerContacto;
    instance.nombreCompleto = data.nombreCompleto;
    instance.disponible = data.disponible;
    instance.gender = data.sexo;
    instance.fecBaja = data.fecBaja;
    instance.fecVencimientoBaja = data.fecVencimientoBaja;
    instance.activo = data.activo;
    instance.rutaFotoPerfil = data.rutaFotoPerfil;
    instance.rutaIneFrontal = data.rutaIneFrontal;
    instance.rutaIneTracera = data.rutaIneTracera;
    instance.nombreArchivoPerfil = data.nombreArchivoPerfil;
    instance.nombreArchivoIneFrontal = data.nombreArchivoIneFrontal;
    instance.nombreArchivoIneTracera = data.nombreArchivoIneTracera;
    instance.nombreArchivoCedula = data.nombreArchivoCedula;
    instance.rutaCedulaFiscal = data.rutaCedulaFiscal;
    return instance;
  }

}
