<div class="app content kobra">
  <section class="centrado">
    <mat-card class="card-general">
      <mat-card-header>
        <mat-card-title>
          Saldos
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="formGroupSaldos" *ngIf="formGroupSaldos">
        <app-field
          *ngFor="let field of camposDinamicos"
          [type]="field.type"
          [formControlName]="field.name"
          [placeholder]="field.placeholder"
          [required]="field.required"
          [maxValue]="999999999"
          >
        </app-field>
        </form>
      </mat-card-content>
      <mat-card-actions align="end" *ngIf="formGroupSaldos">
        <button class="boton-cancelar" mat-flat-button type="submit" routerLink="../" >
          <mat-icon class="kobra-icon">
            close
          </mat-icon> Cancelar
        </button>
        <button  mat-stroked-button class="boton-guardar"
          (click)="saveConfiguracionesSaldos()" m-actions >
          <mat-icon>
            save
          </mat-icon>
          Guardar
        </button>
      </mat-card-actions>
      <mat-card-footer class="kobra-card-footer">
      <mat-progress-bar  *ngIf="cargandoSaldos" class="kobra-card-footer" mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </section>
</div>
