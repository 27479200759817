import { FiltrosService } from '../../application/shared/filtros.service';
import { AgentService } from '../agent.service';
import { ApplicationService } from '../../application/shared/application.service';
import { SessionData } from '../../shared/interfaces/session-data';
import { environmentSelector } from '../../../environments/environment.selector';
import { Lender } from '../../lender/lender.model';
import { LenderService } from '../../lender/lender.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { take} from 'rxjs/operators';
import { map } from 'rxjs/internal/operators/map';
import moment from 'moment';
import { FiltrosAgentesRecorridos } from '../modelos/filtros-agentes-recorridos.model';
import { MapboxService } from '../../mapbox/mapbox.service';
import mapboxgl from 'mapbox-gl';
import { MatSidenav } from '@angular/material/sidenav';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';
import { UtcConversor } from 'src/app/shared/components/utc-conversor';


export interface User {
    name: string;
}

@Component({
    selector: 'agente-recorrido',
    templateUrl: './agente-recorrido.component.html',
    styleUrls: ['./agente-recorrido.component.css'],
    providers: [
        LenderService,
        AgentService,
        MapboxService
    ]
})
export class AgenteRecorridoComponent implements OnInit {
    private environment = environmentSelector();
    public rutaMiga: string = "/agents";
    public textoMiga: string = "Agentes";
    public sessionData: SessionData;
    public lenders: Lender[];
    public idLender: number | null;
    public filtros: FiltrosAgentesRecorridos;
    public filtrado: boolean = false;
    public ocultarVistas: boolean = false;
    public cargandoFinancieras: boolean = true;
    filtrosAbiertos: boolean = false;
    private subscribeRouterParamsBounded: Function;
    private map: any;
    public hoy: Date = new Date();
    @ViewChild('agenteInput') private agenteInput: any;
    filtrosEntrada: FiltrosAgentesRecorridos;
    private ubicaciones: any;
    private puntos: any = [];
    private zoom: number = 5;
    private latHover: number = 0;
    private lngHover: number = 0;
    public ayuda: boolean = false;
    @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;
    public tipoMapa = 1;
    previousTipoMapa: number;
    cargando: boolean;
    public queryParamsGlobal:string;
    public contabilizadorFiltros: FiltersContabilizer;
    public totalFiltros: number = 0;



    constructor(
        private lenderService: LenderService,
        private localStorageService: LocalStorageService,
        private app: ApplicationService,
        private agenteServ: AgentService,
        private route: ActivatedRoute,
        private filtrosService: FiltrosService

    ) {
        mapboxgl.accessToken = this.environment.tokenMapbox;
        this.sessionData = this.localStorageService.getSessionData();
        this.filtros = new FiltrosAgentesRecorridos();
        const offsetInMinutes = new Date().getTimezoneOffset();
        const offsetInHours = -(offsetInMinutes / 60);

        this.route.url.subscribe(url => {
            const currentUrl = url.join('/');
            if (currentUrl === 'agentes-recorrido') {
                localStorage.removeItem('filtrosRecorrido');
                this.subscribeRouterParamsBounded = this.subscribeRouterParams.bind(this);
            } else {
                this.filtrosEntrada = JSON.parse(localStorage.getItem('filtrosRecorrido'));
                this.filtros.idAgente = this.filtrosEntrada.idAgente;
                this.filtros.fecRecorrido = this.filtrosEntrada.fecRecorrido;
                this.ocultarVistas = true;
                this.buscarRecorridoAgente();
            }
        });
        this.contabilizadorFiltros = new FiltersContabilizer();
        this.agregarContabilizadoresDeFiltros();
    }

    ngOnInit() {
        setTimeout(()=>{
            this.inicializarMapa();
        }, 300);
    }

    private agregarContabilizadoresDeFiltros(){
   
        this.contabilizadorFiltros.add('Generales', 'generales', [
            'idAgente',
            'fecRecorrido',
            'mostrarNombre',
            'mostrarLinea',
           
        ]);
    
    }
    
    public contabilizadorDeFiltros(filtroName: string){
        return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
    }
    
    public contabilizadorDeFiltrosTotales():void{
        
    
        let totalFiltrosArray: number[] = [];
       
        totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );

    
        this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }

    private subscribeRouterParams(filtrosNuevos: FiltrosAgentesRecorridos): void {
        var iniciar = false;
        if (filtrosNuevos) {
            iniciar = true;
            this.filtrado = true;
            this.filtros = filtrosNuevos;
        }
        this.loadLenders();
        if(iniciar){
            this.buscarRecorridoAgente();
        }
    }

    public loadLenders(): void {
        this.cargandoFinancieras = true;
        this.lenderService.getAll().subscribe(
            (lenders) => {
                this.lenders = lenders;
                setTimeout(() => {
                    if(this.filtros.financiera){
                        this.idLender = this.filtros.financiera.idFinanciera;
                    }
                }, 150);
                
                this.onChangeLender(this.idLender);
                this.cargandoFinancieras = false;
            }
        );
    }
    public onChangeLender(event) {
        if (event) {
            this.lenders.forEach(lender => {
                if (lender.idFinanciera == event) {
                    this.filtros.financiera = lender;
                }
            });
        }
        else {
            this.filtros.financiera = null;
        }

    }

    inicializarMapa() {

        if(this.filtros.fecRecorrido || this.filtros.financiera || this.filtros.idAgente){
            this.buscarRecorridoAgente()
        }
        let sTipoMapa = "";
        if(this.tipoMapa == 2){
            sTipoMapa = 'satellite-streets-v12'
        }else{
            sTipoMapa = 'streets-v12'
        }

        const pointOptions = {
            "width": 32,
                "height": 32,
                "x": 0,
                "y": 0,
                "pixelRatio": 1
        };

        this.map = new mapboxgl.Map({
            container: 'mapaRecorrido', // container id
            style: 'mapbox://styles/mapbox/'+sTipoMapa, //stylesheet location
            center: [this.environment.paisLng, this.environment.paisLat], // starting positiontarting zoom,
            zoom: this.zoom,
            ...pointOptions,
        });

        this.map.addControl(new mapboxgl.FullscreenControl());
        this.map.addControl(new mapboxgl.NavigationControl());

        this.map.on('load', () => {
            this.map.loadImage('./assets/img/kobra-verde.png', (error, kobra) => {
                if (error) throw error;
                this.map.addImage('kobra', kobra);
                this.map.loadImage('./assets/img/kobra-rojo.png', (error, kobraRoja) => {
                    if (error) throw error;
                    this.map.addImage('kobraRoja', kobraRoja);
                    this.map.loadImage('./assets/img/arrow-map.png', (error, arrow) => {
                        if (error) throw error;
                        this.map.addImage('arrow', arrow);

                        this.map.addSource('recorrido', {
                            'type': 'geojson',
                            'data': {
                                'type': 'Feature',
                                'properties': {
                                    'description': '',
                                    'icon': 'pitch'
                                },
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': [
                                        [
            
                                        ]
                                    ]
                                }
                            }
                        });
        
                        this.map.addSource('recorridoLineal', {
                            'type': 'geojson',
                            'data': {
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'LineString',
                                    'coordinates': [
                                        [
            
                                        ]
                                    ]
                                }
                            }
                        });
        
                        this.map.addLayer({
                            'id': 'recorridosLineales',
                            'type': 'line',
                            'source': 'recorridoLineal',
                            'layout': {
                                'line-join': 'round',
                                'line-cap': 'round'
                            },
                            'paint': {
                                'line-color': '#060D37',
                                'line-opacity': .20,
                                'line-width': 1
                            }
                        });

                        this.map.addLayer({
                            'id': 'recorridosDirectionLineales',
                            'type': 'symbol',
                            'source': 'recorridoLineal',
                            'layout': {
                                'symbol-placement': 'line',
                                'symbol-spacing': 1,
                                'icon-allow-overlap': true,
                                'icon-ignore-placement': true,
                                'icon-image': 'arrow',
                                "icon-size": 0.30,
                                'visibility': 'visible'
                            },
                            'paint': {
                                'icon-opacity': 0.20
                            }
                        });
            
                        this.map.addLayer({
                            'id': 'recorridos',
                            'type': 'symbol',
                            'source': 'recorrido',
                            'layout': {
                                'text-field': ['get', 'description'],
                                'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                                'text-radial-offset': 0.5,
                                'text-justify': 'auto',
                                'icon-image': ['get', 'icon'],
                                "icon-size": 0.05,
                                "icon-allow-overlap": true,
                                "icon-ignore-placement": true,
                                'text-allow-overlap': true
                            }
                        });
        
                        this.popupHover();
            
                        if(this.filtrosEntrada){
                            this.subscribeRouterParams(this.filtrosEntrada);
                        }
                        else{
                            this.filtrosService.obtenerFiltrosGardados<FiltrosAgentesRecorridos>(this.filtros, this.subscribeRouterParamsBounded);
                        }
                    });
                });
            });
        });
    }

    onSelectListEvent(ubicacion) {
        this.map.flyTo(this.zoom == 5 ? {
            zoom: 12,
            center: [ubicacion.lng, ubicacion.lat]
        } :
        {
            center: [ubicacion.lng, ubicacion.lat]
        });
        this.zoom = 12;
    }

    resetFinancieraFilter() {
        this.filtros.financiera = null;
        this.idLender = null;
        this.resetAsignarAgente();
    }


    public agenteSearch = function (term: string, seleccionarPrimero: boolean = false) {
        return this.agenteServ.obtenerAgentes(term)
                .pipe(
                    take(1),
                    map((res: any) => res.data.map(
                        ({ idAgente, idCobrador, nombreCompleto }) => (
                            { idAgente: idAgente, idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}` }
                        )
                    ))
                );
    }.bind(this)

    resetAsignarAgente() {
        this.filtros.idAgente = null;
        this.agenteInput._element.nativeElement.firstChild.firstChild.value = '';
        this.agenteInput.searchService.reset();
        this.filtros.fecRecorrido = null;
        this.filtrado = false;
        this.popup.remove();
        this.map.getCanvas().style.cursor = '';
        this.ubicaciones = [];
        this.zoom = 5;
        this.recargarPuntos(false);
    }

    buscarRecorridoAgente() {
        const camposDiferentesANull = Object.keys(this.filtros).filter(campo => this.filtros[campo] !== null);
       
        if (camposDiferentesANull.length < 4) {
            this.filtrosSideNav.toggle();
            this.app.showSnackbar('¡Aviso!', `Es requerido seleccionar un filtro válido.`, 3000, 'warning');
            return;
        }

        let query: string = this.obtenerQueryParams();
        this.cargando = true;
        if(!this.ocultarVistas){
            this.filtrosService.guardarFiltros(this.filtros);
        }
        this.agenteServ.getHistorial(this.filtros.idAgente, query).subscribe((res: any) => {
            this.ubicaciones = res;
            if(this.ubicaciones.length > 0){
                this.filtrado = true;
                this.recargarPuntos();
                this.contabilizadorDeFiltrosTotales();
            }
            else{
                this.app.showSnackbar(
                    "Aviso",
                    "No se encontraron ubicaciones en el día seleccionado",
                    3000,
                    "warning"
                );
                this.filtrosSideNav.toggle()
            }
            this.cargando = false;
        }, (error: any) => {
            this.app.showError(error);
            this.cargando = false;
        });
       
    }

    descargarRecorrido(){
        if (this.ubicaciones) {
            if (this.ubicaciones.length > 0) {
                this.agenteServ.descargarHistorialUbicaciones(this.filtros.idAgente, this.queryParamsGlobal)
                    .pipe(take(1))
                    .subscribe((respuesta) => {
                        this.app.showSnackbar("Aviso", "Tu archivo estará listo en unos momentos, para poder descargarlo ir a la opción de descargas.", 3000, 'success');
                    }, (error: any) => {
                        this.app.showError(error);
                    });
            } else {
                this.app.showSnackbar("Aviso", "No hay resultados para descargar", 3000, 'warning');
            }
        } else {
            this.app.showSnackbar("Aviso", "No hay resultados para descargar", 3000, 'warning');
        }
    }

    obtenerQueryParams() {
        this.queryParamsGlobal = '';
        if (this.filtros.fecRecorrido) {
            this.queryParamsGlobal += '?fecRecorrido=' + moment(this.filtros.fecRecorrido).format('YYYY-MM-DD');
        }

        return  this.queryParamsGlobal;
    }
    private popup: any;

    popupHover() {
        this.popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        //mousemove
        this.map.on('mousemove', 'recorridos', (e) => {
            this.map.getCanvas().style.cursor = 'pointer';
        });

        //click
        this.map.on('click', 'recorridos', (e) => {
            this.map.getCanvas().style.cursor = 'pointer';

            const coordinates = e.features[0].geometry.coordinates.slice();
            const tooltip = e.features[0].properties.tooltip;
            const id = e.features[0].properties.idUbicacion;

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            if (tooltip) {
                if(coordinates[0] != this.lngHover && coordinates[1] != this.latHover){
                    this.popup.remove();
                }
                this.popup.setLngLat(coordinates).setHTML(tooltip).addTo(this.map);
                document.getElementById("cerrar_" + id).addEventListener("click", (e) => {
                    this.popup.remove();
                    this.map.getCanvas().style.cursor = '';
                });
            }
        });

        //mouseleave
        this.map.on('mouseleave', 'recorridos', () => {
            this.map.getCanvas().style.cursor = '';
            //popup.remove();
        });
    }

    recargarPuntos(flyTo = true, sourceActualizar = 'Todos') {
        this.puntos = [];
        let puntosLineales = [];
        
        for (let index = 0; index < this.ubicaciones.length; index++) {
            const ubicacion = this.ubicaciones[index];
            if (sourceActualizar === 'Todos' || sourceActualizar === 'recorrido') {
                this.puntos.push({
                    'type': 'Feature',
                    'properties': {
                        'description': this.filtros.mostrarNombre ? `P${index+1}` : '',
                        'icon': ubicacion.alertaPanico ? 'kobraRoja' : 'kobra',
                        'idUbicacion': ubicacion.idCobradorHistorialUbicacion,
                        'tooltip': this.obtenerTootlip(ubicacion)
                    },
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [ubicacion.lng, ubicacion.lat]
                    }
                });
            }
            
            if (sourceActualizar === 'Todos' || sourceActualizar === 'recorridoLineal') {
                puntosLineales.push([ubicacion.lng, ubicacion.lat]);
            }
        }
        
        let source = this.map.getSource('recorrido');
        let sourceLineal = this.map.getSource('recorridoLineal');
        let places = {
            'type': 'FeatureCollection',
            'features': this.puntos
        };
        
        if (sourceLineal && (sourceActualizar === 'Todos' || sourceActualizar === 'recorridoLineal')) {
            sourceLineal.setData({
                'type': 'FeatureCollection',
                'features': [{
                    'type': 'Feature',
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': this.filtros.mostrarLinea ? puntosLineales : []
                    }
                }]
            });
        }
        
        if (source && (sourceActualizar === 'Todos' || sourceActualizar === 'recorrido')) {
            source.setData(places);
            if (flyTo) {
                this.onSelectListEvent(this.ubicaciones[this.ubicaciones.length - 1]);
            }
        }

        this.contabilizadorDeFiltrosTotales();
    }
    
    

    obtenerTootlip(ubicacion){
        
        return `
            <div style="position: relative; float: right; padding-left: 10px;">
                <span align="right" title="Cerrar" style="cursor: pointer; font-size: 16px" id="cerrar_${ubicacion.idCobradorHistorialUbicacion}">
                    <i class="material-icons">disabled_by_default</i>
                </span>
            </div>
            ${this.alertaDePanicoTitulo(ubicacion)}
            <b>Ubicación:</b> ${ubicacion.lat}, ${ubicacion.lng}
            <br>
            <b>Dispositivo:</b> ${ubicacion.dispositivo}
            <br>
            <b>Batería:</b> ${ubicacion.porcentageBateria} %
            <br>
            <b>Fecha registro:</b> ${UtcConversor.convertDateToLocalView(ubicacion.fecRegistro)}
            <br>
            ${this.fechaDispositivo(ubicacion)}
            <b>Ubicación offline:</b> ${ubicacion.offline ? 'Sí' : 'No'}
            <br>
            <b>Ubicación simulada(mock):</b> ${ubicacion.isMock ? 'Sí' : 'No'}
            <br>
            <a href="https://www.google.com/maps/search/?api=1&query=${ubicacion.lat}, ${ubicacion.lng}" target="_blank">Ver en maps</a>
        `;
    }

    alertaDePanicoTitulo(ubicacion){
        let respuesta = '';
        if(ubicacion.alertaPanico){
            respuesta = `
                <h3><b>Alerta de pánico</b></h3>
                <br>
            `;
        }

        return respuesta;
    }

    fechaDispositivo(ubicacion){
        let respuesta = '';
        if(ubicacion.fecDispositivo){
            respuesta = `
                <b>Fecha dispositivo:</b> ${moment(ubicacion.fecDispositivo).format('DD/MM/YYYY HH:mm')}<br>
            `;
        }

        return respuesta;
    }
    ayudaComentario(bool: boolean) {
        this.ayuda = bool;
    }
}

